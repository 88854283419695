/* eslint-disable */

import React from "react";
import Modal from "react-modal";

interface AppModal {
    onClick: CallableFunction;
    onClose: CallableFunction;
    isOpen: boolean;
    message?: string;
    isContinueButtonOnly?: boolean;
}

const AppModal = (props: AppModal) => {
    return (
        <Modal
            isOpen={props.isOpen}
            ariaHideApp={false}
            onRequestClose={() => {
                props.onClose();
            }}
            className="modal"
        >
            <div className="row">
                <div className="card text-center">
                    <div className="card-body align-items-center d-flex justify-content-center">
                        {props.message != undefined ? props.message : "You haven't set a photo!"}
                    </div>
                    <div className="card-footer text-muted p-4">
                        <input
                            className="btn btn-secondary btn-pcid-cancel"
                            type="button"
                            value="Continue"
                            onClick={(e) => {
                                e.preventDefault();
                                props.onClick();
                            }}
                        />
                        {props.isContinueButtonOnly == undefined ? <input
                            className="btn btn-success btn-pcid ml-3"
                            type="button"
                            value="Go Back"
                            onClick={() => {
                                props.onClose();
                            }}
                        /> : null}
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default AppModal;
