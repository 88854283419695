/* eslint-disable */

import { useParams, Link } from "react-router-dom";
import ApiService from "../../services/ApiService";
import DashboardLayout from "../../layouts/DashboardLayout";
import React, { useEffect, useState } from "react";
import TextBox from "../../components/TextBox";
import { toast } from "react-toastify";
import AppDropdown from "../../components/AppDropdown";
import Loading from "../../components/Loading";
import FileButton from "../../components/FileButton";
import { getImage } from "../../services/HelperMethod";

const AddDepartment = (props) => {
    const [name, setName] = useState("");
    const [nameError, setNameError] = useState("");

    const apiService = ApiService();

    const { departmentId } = useParams() as any;
    const [title, setTitle] = useState("Add Department");
    const [stores, setStores] = useState([] as any[]);
    const [storeId, setStoreId] = useState(0);
    const [isLoading, setisLoading] = useState(false);
    const [mainImage, setMainImage] = useState("");

    const [storeIdError, setStoreIdError] = useState("");

    useEffect(() => {
        setisLoading(true);
        apiService
            .getStores()
            .then((data: any) => {
                data = data.data;
                setStores(data);
                setisLoading(false);
            })
            .catch(() => {
                setisLoading(false);
                toast.error("Error Getting Stores, try again");
            });

        if (departmentId) {
            setTitle("Edit Department");
            getDepartment(departmentId);
        }
    }, []);

    function getDepartment(departmentId) {
        apiService.getDepartment(departmentId).then((data: any) => {
            data = data.data;
            
            setName(data.name);
            setStoreId(data.storeId);
            setMainImage(getImage(data.imageURL));
        });
    }

    function addDepartment() {
        let data = {
            id: departmentId,
            name: name,
            storeId,
            imageURL: mainImage,
        };
        setisLoading(true);
        apiService
            .addDepartment(data)
            .then((data: any) => {
                data = data.data;
                setisLoading(false);
                if (data.errorType) {
                    toast.error(data.errorExplanation);
                } else {
                    // toast("Department Added!");
                    props.history.push("/assets");
                }
            })
            .catch((error: any) => {
                setisLoading(false);
                toast.error("Please add all required fields");
            });
    }

    return (
        <DashboardLayout title={title}>
            <Loading isLoading={isLoading} />
            <div className="card">
                <div className="card-body">
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            addDepartment();
                        }}
                    >
                        <div className="row justify-content-center">
                            <div className="col-auto">
                                <FileButton
                                    image={mainImage}
                                    onChange={(e) => {
                                        setMainImage(e);
                                    }}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-12 col-md-6">
                                <div className="form-group mt-1">
                                    <label className="ml-3" htmlFor="lane-name">
                                        Department Name *
                                    </label>
                                    <TextBox
                                        autoFocus={true}
                                        type="text"
                                        className="form-control sign-in-inputs"
                                        onChange={(val) => setName(val)}
                                        value={name}
                                        error={nameError}
                                    />
                                </div>
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <div className="form-group mt-1">
                                    <label className="ml-3">Store *</label>
                                    <AppDropdown
                                        onChange={(val) => setStoreId(val)}
                                        value={storeId.toString()}
                                        error={storeIdError}
                                    >
                                        <option value="0">
                                            Select an entity..
                                        </option>
                                        {stores.map((d: any) => {
                                            return (
                                                <option key={d.id} value={d.id}>
                                                    {d.name}
                                                </option>
                                            );
                                        })}
                                    </AppDropdown>
                                </div>
                            </div>
                        </div>
                        <div className="form-group row justify-content-end">
                            <div className="col-auto">
                                <button
                                    type="button"
                                    className="btn btn-secondary btn-block btn-pcid-cancel mt-3"
                                    onClick={() => {
                                        props.history.push("/assets/");
                                    }}
                                >
                                    Cancel
                                </button>
                            </div>

                            <div className="col-auto">
                                <button
                                    type="button"
                                    className="btn btn-success btn-block btn-pcid mt-3"
                                    onClick={() => {
                                        addDepartment();
                                    }}
                                >
                                    Add
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </DashboardLayout>
    );
};

export default AddDepartment;
