import React from "react";
import { HashRouter, Route, Redirect, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import "normalize.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import "./assets/css/style.scss";
import "./assets/css/mobile.scss";

// App components
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";

import SignUp from "./pages/SignUp/SignUp";
import SetupPayments from "./pages/SignUp/SetupPayments";
import BusinessProfile from "./pages/SignUp/BusinessProfile";
import ResetPassword from "./pages/ResetPassword";

import Stores from "./pages/Stores/Stores";
import AddStore from "./pages/Stores/AddStore";

import Lanes from "./pages/Lanes/Lanes";
import AddLane from "./pages/Lanes/AddLane";

import PciEquipments from "./pages/Equipment/PCIEquipments";
import AddPCIEquipment from "./pages/Equipment/AddPCIEquipment";

import Assets from "./pages/Assets/Assets";
import AddAsset from "./pages/Assets/AddAsset";

import AddDepartment from "./pages/Department/AddDepartment";

import Users from "./pages/Users/Users";
import AddUser from "./pages/Users/AddUser";

import Verification from "./pages/Verification/Verification";
import AddVerification from "./pages/Verification/AddVerification";
import Maintenance from "./pages/Maintenance/Maintenance";
import VerificationDevices from "./pages/Verification/VerificationDevices";
import AddDeviceQuestions from "./pages/Verification/AddDeviceQuestions";
import VerificationLog from "./pages/Verification/VerificationLog";
import VerificationResult from "./pages/Verification/VerificationResult";
import AddMaintenance from "./pages/Maintenance/AddMaintenance";
import MaintenanceLog from "./pages/Maintenance/MaintenanceLog";
import MaintenanceLogDetails from "./pages/Maintenance/MaintenanceLogDetails";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import ChangeCompany from "./pages/SuperAdmin/ChangeCompany";
import ImpersonateUsers from "./pages/SuperAdmin/ImpersonateUsers";
import Company from "./pages/Company/Company";
import Store from "./store/StoreProvider";

import Reports from "./pages/Reports/Reports";
import SectionNine from "./pages/Reports/SectionNine";
import SectionNineShare from "./pages/Reports/SectionNineShare";
import VerificationLogByTerminal from "./pages/Reports/VerificationLogByTerminal";
import MaintenanceLogReport from "./pages/Reports/MaintenanceLogReport";
import TerminalReport from "./pages/Reports/TerminalReport";

// import { useClearCache } from "react-clear-cache";
import CardCompleted from "./pages/PaymentReceipts/CardCompleted";
import CardCanceled from "./pages/PaymentReceipts/CardCanceled";
import PayPalResult from "./pages/PaymentReceipts/PayPalResult";
import PayPalStoreUpdate from "./pages/PaymentReceipts/PayPalStoreUpdate";

//imports for sign up pages
import SetupStore from "./pages/SignUp/SetupStore";
import SetupLanes from "./pages/SignUp/SetupLanes";
import SetupEquipment from "./pages/SignUp/SetupEquipment";
import SetupShopEquipment from "./pages/SignUp/SetupShopEquipment";
import FinalPage from "./pages/SignUp/FinalPage";
import Payment from "./pages/SignUp/Payment";

function App(props) {
    // const { isLatestVersion, emptyCacheStorage } = useClearCache(props);

    return (
        <Store>
            <ToastContainer />
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <HashRouter>
                    <Switch>
                        <Route exact={true} path="/login" component={Login} />

                        <Route
                            exact={true}
                            path="/dashboard"
                            component={Dashboard}
                        />

                        <Route
                            exact={true}
                            path="/ComplianceReport/:reportID"
                            component={SectionNine}
                        />

                        <Route
                            exact={true}
                            path="/VerificationReport"
                            component={VerificationLogByTerminal}
                        />

                        <Route
                            exact={true}
                            path="/MaintenanceReport"
                            component={MaintenanceLogReport}
                        />

                        <Route
                            exact={true}
                            path="/TerminalsReport"
                            component={TerminalReport}
                        />

                        <Route exact={true} path="/reports" component={Reports} />

                        <Route
                            exact={true}
                            path="/Report/:guid"
                            component={SectionNineShare}
                        />

                        {/* super admin routes */}
                        <Route
                            exact={true}
                            path="/changeCompany"
                            component={ChangeCompany}
                        />
                        <Route
                            exact={true}
                            path="/impersonateUser"
                            component={ImpersonateUsers}
                        />

                        <Route exact={true} path="/signup" component={SignUp} />
                        <Route
                            exact={true}
                            path="/payment"
                            component={SetupPayments}
                        />

                        <Route
                            exact={true}
                            path="/businessprofile"
                            component={BusinessProfile}
                        />

                        <Route exact={true} path="/stores" component={Stores} />
                        <Route
                            exact={true}
                            path="/addStore"
                            component={AddStore}
                        />
                        <Route
                            exact={true}
                            path="/editStore/:storeId"
                            component={AddStore}
                        />

                        <Route exact={true} path="/lanes/" component={Lanes} />
                        <Route
                            exact={true}
                            path="/addLane/:selectedStoreId?/"
                            component={AddLane}
                        />
                        <Route
                            exact={true}
                            path="/editLane/:laneId"
                            component={AddLane}
                        />

                        <Route
                            exact={true}
                            path="/pciEquipments"
                            component={PciEquipments}
                        />
                        <Route
                            exact={true}
                            path="/pciEquipments/addEquipment/:selectedStoreId?/:selectedLaneId?"
                            component={AddPCIEquipment}
                        />

                        <Route
                            exact={true}
                            path="/editEquipment/:equipmentId"
                            component={AddPCIEquipment}
                        />

                        <Route
                            exact={true}
                            path="/addDepartment/"
                            component={AddDepartment}
                        />
                        <Route
                            exact={true}
                            path="/editDepartment/:departmentId"
                            component={AddDepartment}
                        />

                        <Route exact={true} path="/users" component={Users} />
                        <Route
                            exact={true}
                            path="/users/addUser/:securityId?/:isGoBack?"
                            component={AddUser}
                        />
                        <Route
                            exact={true}
                            path="/editUser/:userId/"
                            component={AddUser}
                        />

                        <Route exact={true} path="/assets" component={Assets} />
                        <Route
                            exact={true}
                            path="/assets/addAsset"
                            component={AddAsset}
                        />
                        <Route
                            exact={true}
                            path="/editAsset/:assetId"
                            component={AddAsset}
                        />

                        <Route
                            exact={true}
                            path="/verification"
                            component={Verification}
                        />

                        <Route
                            exact={true}
                            path="/editVerification/:laneId"
                            component={AddVerification}
                        />

                        <Route
                            exact={true}
                            path="/verification/devices/:verificationId"
                            component={VerificationDevices}
                        />

                        <Route
                            exact={true}
                            path="/verification/deviceLog/:deviceId"
                            component={VerificationLog}
                        />

                        <Route
                            exact={true}
                            path="/verification/deviceLogResult/:verificationResultId"
                            component={VerificationResult}
                        />

                        <Route
                            exact={true}
                            path="/verification/addDeviceQuestions/:deviceId"
                            component={AddDeviceQuestions}
                        />

                        <Route
                            exact={true}
                            path="/verification/editDeviceQuestions/:deviceId/:questionId"
                            component={AddDeviceQuestions}
                        />

                        <Route
                            exact={true}
                            path="/maintenance"
                            component={Maintenance}
                        />
                        <Route
                            exact={true}
                            path="/AddMaintenance/:assetId"
                            component={AddMaintenance}
                        />
                        {/* <Route
              exact={true}
              path="/maintenance/assets/:departmentId"
              component={MaintenanceDevices}
            /> */}

                        <Route
                            exact={true}
                            path="/maintenance/log/:assetId"
                            component={MaintenanceLog}
                        />

                        <Route
                            exact={true}
                            path="/maintenance/log/details/:logId"
                            component={MaintenanceLogDetails}
                        />

                        <Route
                            exact={true}
                            path="/EditMaintenance/:assetId/:maintenanceId"
                            component={AddMaintenance}
                        />

                        <Route
                            exact={true}
                            path="/ResetPassword"
                            component={ResetPassword}
                        />

                        <Route
                            exact={true}
                            path="/paymentCompleted"
                            component={CardCompleted}
                        />

                        <Route
                            exact={true}
                            path="/PaypalResult/:result"
                            component={PayPalResult}
                        />

                        <Route
                            exact={true}
                            path="/PaypalStoreUpdateResult/:result"
                            component={PayPalStoreUpdate}
                        />

                        <Route
                            exact={true}
                            path="/paymentCanceled"
                            component={CardCanceled}
                        />

                        <Route
                            exact={true}
                            path="/company"
                            component={Company}
                        />

                        {/*--Routes for signup pages--*/}
                        <Route
                            exact={true}
                            path="/setup-payments"
                            component={Payment}
                        />
                        <Route
                            exact={true}
                            path="/setup-store"
                            component={SetupStore}
                        />
                        <Route
                            exact={true}
                            path="/setup-lanes"
                            component={SetupLanes}
                        />
                        <Route
                            exact={true}
                            path="/setup-equipment"
                            component={SetupEquipment}
                        />
                        <Route
                            exact={true}
                            path="/setup-shop"
                            component={SetupShopEquipment}
                        />
                        <Route
                            exact={true}
                            path="/done"
                            component={FinalPage}
                        />
                        {/*--End for routes for signup--*/}

                        <Route
                            exact={true}
                            path="/"
                            render={() => <Redirect to="/login" />}
                        />
                    </Switch>
                </HashRouter>
            </MuiPickersUtilsProvider>
        </Store>
    );
}

export default App;
