import React, { useState, useEffect, useRef, useContext } from "react";
import AppButton from "./AppButton";
import ApiService from "../services/ApiService";

export default function Email(props: { link?: string; onClick? }) {
  const apiService = ApiService();

  const [to, setTo] = useState("");
  const [from, setFrom] = useState("");
  const [body, setBody] = useState("");

  function sendEmail() {
    var data = [];
    data[0] = to;
    data[1] = from;
    data[2] = body;
    data[3] = props.link;

    apiService.sendEmail(data);
  }

  return (
    <div id="opacityLayer">
      <form id="email">
        <div className="EmailHeader">
          Send Section 9 <br /> Report
        </div>
        <input
          placeholder="To:"
          onChange={(e) => {
            setTo(e.target.value);
          }}
        ></input>

        <input
          placeholder="From:"
          onChange={(e) => {
            setFrom(e.target.value);
          }}
        ></input>

        <textarea
          className="body"
          onChange={(e) => {
            setBody(e.target.value);
          }}
        ></textarea>

        <AppButton
          className="sendEmail"
          onClick={() => {
            sendEmail();
            props?.onClick?.();
          }}
        >
          Send
        </AppButton>
      </form>
    </div>
  );
}
