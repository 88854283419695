import React, { useState, useEffect, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import FileButton from "../../components/FileButton";
import TextBox from "../../components/TextBox";
import ApiService from "../../services/ApiService";
import AppDropdown from "../../components/AppDropdown";
import { toast } from "react-toastify";
import Loading from "../../components/Loading";
import Steps from "../../components/Steps";

function SetupStore() {
    const history = useHistory();

    const [isLoading, setIsLoading] = useState(false);

    const { storeId } = useParams() as any;

    const [storeImage, setStoreImage] = useState("");
    const [storeName, setStoreName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [address, setAddress] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [zip, setZip] = useState("");
    const [website, setWebsite] = useState("");
    const [managerId, setManagerId] = useState(0);
    const [managers, setManagers] = useState([] as any[]);

    const [storeNameError, setStoreNameError] = useState("");
    const [phoneNumberError, setPhoneNumberError] = useState("");
    const [addressError, setAddressError] = useState("");
    const [zipError, setZipError] = useState("");
    const [cityError, setCityError] = useState("");
    const [stateError, setStateError] = useState("");
    const [websiteError, setWebsiteError] = useState("");
    const [managerIdError, setManagerIdError] = useState("");

    const apiService = ApiService();

    useEffect(() => {
        apiService.getManagers().then((data: any) => {

            data = data.data;

            let managers = data.filter((d) => {
                return d.selectedRole == "Manager" || d.selectedRole == "Admin";
            });
            setManagers(managers);
        }, (data: any) => {
        });
    }, []);

    function handleFormErrors(err) {
        if (err?.Name) {
            setStoreNameError(err.Name[0]);
        }

        if (err?.Address) {
            setAddressError(err.Address[0]);
        }

        if (err?.City) {
            setCityError(err.City[0]);
        }

        if (err?.Zip) {
            setZipError(err.Zip[0]);
        }

        if (err?.State) {
            setStateError(err.State[0]);
        }

        if (err?.ManagerId) {
            setManagerIdError(err.ManagerId[0]);
        }
        if (err?.PhoneNumber) {
            setPhoneNumberError(err.PhoneNumber[0]);
        }

        if (err?.Website) {
            setWebsiteError(err.Website[0]);
        }

        toast.error("Please fill all the required fields");
    }

    function saveStore() {

        let dataObj = {
            id: storeId,
            name: storeName,
            phoneNumber: phoneNumber,
            address: address,
            city: city,
            state: state,
            zip: zip,
            website: website,
            managerId: managerId,
            storeImage64: storeImage,
            sourceURL: window.location.href
        }

        setIsLoading(true);

        apiService.registerStore(dataObj).then((data: any) => {
            data = data.data;

            if (data.errorType) {
                toast.error(data.errorExplanation);
                setIsLoading(false);
                return;
            }

            setIsLoading(false);

            history.push({ pathname: "/setup-lanes" });

        }).catch((error) => {
            setIsLoading(false);
            handleFormErrors(error?.response?.data?.errors);
        });
    }

    function redirectToDashboard() {
        history.push("/dashboard")
    }

    return (
        <React.Fragment>
            <Loading isLoading={isLoading} />

            <div className="full-layout">
                <div style={{ marginBottom: "3rem" }}>
                    <div>
                        <button className="btn-redirect" onClick={(e) => { e.preventDefault(); redirectToDashboard(); }}>Skip</button>
                    </div>
                </div>
                <div className="container">
                    <div style={{ width: "100%" }} className="white-box store-white-box">
                        <div>
                            <div style={{ marginBottom: "1.5rem" }} className="row">
                                <div className="col-sm-12 col-md-5">
                                    <h2 style={{ marginTop: "0.7rem" }} className="mb-3 topic-text ht" id={"store-profile"}>Setup a Store</h2>
                                </div>
                                <div className="col-sm-12 col-md-7">
                                    <Steps iteration={4} activeIndex={0} />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div>
                                <form>
                                    <div className="row">
                                        <div className="col-sm-12 col-md-2">
                                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                <FileButton onChange={(file) => { if (file) { setStoreImage(file); } }} image={storeImage} styles={{ width: "4.5rem", height: "4.5rem" }} />
                                                <label style={{ fontSize: "0.7rem", color: "#3c19fb" }} htmlFor="store-image">Choose Image</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12 col-md-4">
                                            <div className="form-group mt-1">
                                                <label className="ml-3" htmlFor="store-name">Store Name *</label>
                                                <TextBox type="text" placeholder={"Store Name"} onChange={(val) => { setStoreName(val); }} className="form-control sign-in-inputs" error={storeNameError} value={storeName} />
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-4">
                                            <div className="form-group mt-1">
                                                <label className="ml-3" htmlFor="store-phone">Phone *</label>
                                                <TextBox type="text" placeholder={"Phone Number"} mask="999-999-9999" onChange={(val) => { setPhoneNumber(val); }} className="form-control sign-in-inputs" error={phoneNumberError} value={phoneNumber} />
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-4">
                                            <div className="form-group mt-1">
                                                <label className="ml-3" htmlFor="store-address">Address *</label>
                                                <TextBox type="text" placeholder={"Address"} onChange={(val) => { setAddress(val); }} className="form-control sign-in-inputs" error={addressError} value={address} />
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-4">
                                            <div className="form-group mt-1">
                                                <label className="ml-3" htmlFor="store-city">City *</label>
                                                <TextBox type="text" placeholder={"City"} onChange={(val) => { setCity(val); }} className="form-control sign-in-inputs" error={cityError} value={city} />
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-4">
                                            <div className="form-group mt-1">
                                                <label className="ml-3" htmlFor="store-state">State *</label>
                                                <TextBox type="text" placeholder={"State"} onChange={(val) => { setState(val); }} className="form-control sign-in-inputs" error={stateError} value={state} />
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-4">
                                            <div className="form-group mt-1">
                                                <label className="ml-3" htmlFor="store-zip">Zip Code *</label>
                                                <TextBox type="text" placeholder={"Zip Code"} onChange={(val) => { setZip(val); }} className="form-control sign-in-inputs" error={zipError} value={zip} />
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-4">
                                            <div className="form-group mt-1">
                                                <label className="ml-3" htmlFor="store-website">Website</label>
                                                <TextBox type="text" placeholder={"Website"} onChange={(val) => { setWebsite(val); }} className="form-control sign-in-inputs" error={websiteError} value={website} />
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-4">
                                            <div className="form-group mt-1">
                                                <label className="ml-3" htmlFor="store-manager">Select a Manager *</label>
                                                <AppDropdown value={managerId.toString()} onChange={(val) => { setManagerId(val) }} error={managerIdError}>
                                                    <option value="0">Select a manager</option>
                                                    {managers.map((manager) => {
                                                        return (
                                                            <option key={manager.id} value={manager.id}>
                                                                {manager.firstName}{" "}{manager.lastName} ({manager.selectedRole})
                                                            </option>
                                                        );
                                                    })}
                                                </AppDropdown>
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                        <div style={{ width: 220, }}>
                                            <button className="btn" onClick={(e) => { e.preventDefault(); saveStore(); }}>Next</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >
    );
}

export default SetupStore;
