import React, { useState, useEffect } from "react";
import DashboardLayout from "../layouts/DashboardLayout";
import ApiService from "../services/ApiService";
import Loading from "../components/Loading";
import { Doughnut } from "react-chartjs-2";
import moment from "moment";
import { Link } from "react-router-dom";
import { checkClaim, dateDiff } from "../services/HelperMethod";
import { Fragment } from "react";
import { toast } from "react-toastify";
import DashboardTable from "../components/DashboardTable";
import { tableColumns } from "../services/HelperMethod";
import { Filter } from "@material-ui/icons";
import Verification from "./Verification/Verification";
import CardCompleted from "./PaymentReceipts/CardCompleted";
import { forEachChild } from "typescript";
import VerificationLog from "./Verification/VerificationLog";

function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return () => setValue((value) => value + 1); // update the state to force render
}

const Dashboard = (props: any) => {
  const forceUpdate = useForceUpdate();
  const apiService = ApiService();
  const [isLoading, setIsLoading] = useState(false);
  const [isVerificationsLoaded, setIsVerificationsLoaded] = useState(false);
  const [isVerificationsLoading, setIsVerificationsLoading] = useState(true);

  const [verifications, setVerifications] = useState([] as any);
  const [completedVerifications, setCompletedVerifications] = useState(
    [] as any
  );

  const [verificationDonut, setVerificationDonut] = useState({} as any);
  const [verificationInfo, setVerificationInfo] = useState({} as any);

  const [maintenance, setMaintenance] = useState([]);
  const [maintenanceInfo, setMaintenanceInfo] = useState({} as any);

  const [maintenanceDonut, setMaintenanceDonut] = useState({} as any);

  const [filters, setFilters] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0] as any);
  const [maintenanceFilters, setMaintenanceFilters] = useState([] as any);

  useEffect(() => {
    getDashboard();
  }, []);

  function getDashboard() {
    var vPromise = apiService.getDashboardVerifications();
    var cvPromise = apiService.getDashboardCompletedVerifications();

    Promise.all([vPromise, cvPromise]).then((data: any) => {
      var last14days = data[0].data.filter((item) => {
        if (moment().diff(moment(item.verifiedDate), "days") <= 14) {
          return true;
        }
      });
      setVerifications(last14days);
      var last7days = data[1].data.filter((item) => {
        if (moment().diff(moment(item.verifiedDate), "days") <= 7) {
          return true;
        }
      });
      setCompletedVerifications(last7days);
      console.log(last14days);
      updateVerificationDonut(last14days, last7days);
    });

    apiService
      .getDashboardMaintenance()
      .then((data: any) => {
        data = data.data;

        var last14days = data.filter((item) => {
          if (moment().diff(moment(item.dueDate), "days") <= 14) {
            return true;
          }
          return false;
        });

        setMaintenance(last14days);
        updateMaintenenceDonut(last14days);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }

  function updateVerificationDonut(last14days, completedVerifications) {
    if (last14days == null || completedVerifications == null) {
      return;
    }

    let info = getCountStart(last14days, completedVerifications);

    setVerificationInfo(info);

    setVerificationDonut({
      labels: ["Due today", "Due Soon", "Late", "Failed", "Done"],
      datasets: [
        {
          label: "# of Votes",
          data: [info.today, info.soon, info.late, info.failed, info.done],
          backgroundColor: [
            "#ff8931",
            "#ffb93c",
            "#ff485e",
            "#c43957",
            "#00be9c",
          ],
          borderColor: ["#ff8931", "#ffb93c", "#ff485e", "#c43957", "#00be9c"],
          borderWidth: 1,
        },
      ],
      onHover: (event, chartElement) => {
        event.target.style.cursor = chartElement[0] ? "pointer" : "default";
      },
      legend: {
        display: false,
      },
    });
  }

  function updateMaintenenceDonut(last14days) {
    if (last14days == null) {
      return;
    }
    let info = getCount(last14days);

    setMaintenanceInfo(info);
    setMaintenanceDonut({
      labels: ["Due today", "Due Soon", "Late", "Failed", "Done"],
      datasets: [
        {
          label: "# of Votes",
          data: [info.today, info.soon, info.late, info.failed, info.done],
          backgroundColor: [
            "#ff8931",
            "#ffb93c",
            "#ff485e",
            "#c43957",
            "#00be9c",
          ],
          borderColor: ["#ff8931", "#ffb93c", "#ff485e", "#c43957", "#00be9c"],
          borderWidth: 1,
        },
      ],
    });
  }

  function countUpToDate() {
    var verificationC = completedVerifications;
    if (verificationC.length != 0) {
      setIsVerificationsLoading(false);
    }
  }

  function getCountStart(items, CompletedVerifications) {
    let dueDatesInfo = {
      today: 0,
      soon: 0,
      done: 0,
      failed: 0,
      late: 0,
    };

    items.forEach((v) => {
      var dueDate = moment(v.dueDate).startOf("day");
      var today = moment().startOf("day");

      var momentDiff = moment.duration(Math.abs(today.diff(dueDate)));
      var difference = Math.floor(momentDiff.asDays());

      var completedStatus = itemCompletedStart(v, CompletedVerifications);
      if (completedStatus == "completed") {
        dueDatesInfo.done += 1;
      } else if (completedStatus == "failed") {
        dueDatesInfo.failed += 1;
      } else if (difference == 0) {
        dueDatesInfo.today += 1;
      } else if (today > dueDate) {
        dueDatesInfo.late += 1;
      } else if (difference < 30) {
        dueDatesInfo.soon += 1;
      }
    });

    return dueDatesInfo;
  }

  function getCount(items) {
    let dueDatesInfo = {
      today: 0,
      soon: 0,
      done: 0,
      failed: 0,
      late: 0,
    };

    items.forEach((v) => {
      var dueDate = moment(v.dueDate).startOf("day");
      var today = moment().startOf("day");

      var momentDiff = moment.duration(Math.abs(today.diff(dueDate)));
      var difference = Math.floor(momentDiff.asDays());

      var completedStatus = itemCompleted(v);
      if (completedStatus == "completed") {
        dueDatesInfo.done += 1;
      } else if (completedStatus == "failed") {
        dueDatesInfo.failed += 1;
      } else if (difference == 0) {
        dueDatesInfo.today += 1;
      } else if (today > dueDate) {
        dueDatesInfo.late += 1;
      } else if (difference < 30) {
        dueDatesInfo.soon += 1;
      }
    });

    return dueDatesInfo;
  }

  function calculateVerificationIssues(verificationLog) {
    let total = 0;
    if (!verificationLog.isAssetMatch) {
      total++;
    }
    if (!verificationLog.isModelMatch) {
      total++;
    }
    if (!verificationLog.isSerialMatch) {
      total++;
    }

    // verificationLog.verificationAnswers.forEach((log) => {
    //     if (log.answer == 2) {
    //         total++;
    //     }
    // });

    return total;
  }

  function filterMaintenance(item) {
    var dueDate = moment(item.dueDate).startOf("day");
    var today = moment().startOf("day");

    var momentDiff = moment.duration(Math.abs(today.diff(dueDate)));
    var difference = Math.floor(momentDiff.asDays());

    let classNames = "status";
    var text;

    if (difference == 0 && filters[5] == 1) {
      return false;
    } else if (today > dueDate && filters[8] == 1) {
      return false;
    } else if (
      difference < 15 &&
      filters[6] == 1 &&
      today < dueDate &&
      difference != 0
    ) {
      return false;
    } else if (item.isComplete && filters[7] == 1) {
      return false;
    }

    return true;
  }

  function getLog(item) {
    var completed = completedVerifications;
    completed.forEach((veri) => {
      if (veri.equipment.laneId == item.laneId) {
        return veri.id;
      }
    });
    return 0;
  }

  function itemCompleted(item) {
    var status = "notCompleted";
    var completed = completedVerifications;
    completed.forEach((veri) => {
      if (veri.equipment.laneId == item.laneId) {
        if (calculateVerificationIssues(veri)) {
          status = "failed";
        } else {
          status = "completed";
        }
      }
    });
    return status;
  }

  function itemCompletedStart(item, completed) {
    var status = "notCompleted";
    completed.forEach((veri) => {
      if (veri.equipment.laneId == item.laneId) {
        if (calculateVerificationIssues(veri)) {
          status = "failed";
        } else {
          status = "completed";
        }
      }
    });
    return status;
  }

  function clickState(int) {
    if (filters[int] == 1) {
      return "clicked";
    } else {
      return "default";
    }
  }
  function filterVerification(type) {
    var result = filters;
    if (result[type] == 0) {
      result[type] = 1;
    } else {
      result[type] = 0;
    }

    setFilters(result);

    setVerificationDonut({
      labels: ["Due today", "Due Soon", "Late", "Failed", "Done"],
      datasets: [
        {
          label: "# of Votes",
          data: [
            result[0] ? 0 : verificationInfo.today,
            result[1] ? 0 : verificationInfo.soon,
            result[3] ? 0 : verificationInfo.late,
            result[4] ? 0 : verificationInfo.failed,
            result[2] ? 0 : verificationInfo.done,
          ],
          backgroundColor: [
            "#ff8931",
            "#ffb93c",
            "#ff485e",
            "#c43957",
            "#00be9c",
          ],
          borderColor: ["#ff8931", "#ffb93c", "#ff485e", "#c43957", "#00be9c"],
          borderWidth: 1,
        },
      ],
      onHover: (event, chartElement) => {
        event.target.style.cursor = chartElement[0] ? "pointer" : "default";
      },
      legend: {
        display: false,
      },
    });

    setMaintenanceDonut({
      labels: ["Due today", "Due Soon", "Late", "Failed", "Done"],
      datasets: [
        {
          label: "# of Votes",
          data: [
            result[5] ? 0 : maintenanceInfo.today,
            result[6] ? 0 : maintenanceInfo.soon,
            result[8] ? 0 : maintenanceInfo.late,
            result[9] ? 0 : maintenanceInfo.failed,
            result[7] ? 0 : maintenanceInfo.done,
          ],
          backgroundColor: [
            "#ff8931",
            "#ffb93c",
            "#ff485e",
            "#c43957",
            "#00be9c",
          ],
          borderColor: ["#ff8931", "#ffb93c", "#ff485e", "#c43957", "#00be9c"],
          borderWidth: 1,
        },
      ],
    });

    forceUpdate();
  }

  function filterStatus(item) {
    var dueDate = moment(item.dueDate).startOf("day");
    var today = moment().startOf("day");

    var momentDiff = moment.duration(Math.abs(today.diff(dueDate)));
    var difference = Math.floor(momentDiff.asDays());
    var completionStatus = itemCompleted(item);

    let classNames = "status";
    var text;

    if (completionStatus == "completed" && filters[2] == 1) {
      return false;
    } else if (completionStatus == "failed" && filters[4] == 1) {
      return false;
    } else if (difference == 0 && filters[0] == 1) {
      return false;
    } else if (today > dueDate && filters[3] == 1) {
      return false;
    } else if (
      difference < 15 &&
      filters[1] == 1 &&
      today < dueDate &&
      difference != 0
    ) {
      return false;
    } else {
      return true;
    }
  }

  function status(date, item) {
    var dueDate = moment(item.dueDate).startOf("day");
    var today = moment().startOf("day");

    var momentDiff = moment.duration(Math.abs(today.diff(dueDate)));
    var difference = Math.floor(momentDiff.asDays());

    let classNames = "status";
    var text;
    var completionStatus = itemCompleted(item);
    if (completionStatus == "completed") {
      classNames += " done";
      text = "Done";
    } else if (completionStatus == "failed") {
      classNames += " failed";
      text = "Failed";
    } else if (difference == 0) {
      classNames += " today";
      text = "Today";
    } else if (today > dueDate) {
      classNames += " late";
      text = "Late";
    } else if (difference < 30) {
      classNames += " soon";
      text = "Soon";
    } else {
    }

    return [<span className={classNames} />, <span>{text}</span>];
  }

  return (
    <DashboardLayout title="">
      <Loading isLoading={isLoading} />
      <React.Fragment>
        <div className="dashboard">
          <div className="DashboardActions">
            <Link to={"users/addUser"} className="dashboard-icon-wrap blue">
              <img src="./assets/icons/Add-User.svg" alt="" />
              <div className="icontext">Add user</div>
            </Link>

            <Link to={"assets/addAsset"} className="dashboard-icon-wrap green">
              <img src="./assets/icons/Add-Asset.svg" alt="" />
              <div className="icontext">Add asset</div>
            </Link>

            <Link to={"addLane"} className="dashboard-icon-wrap purple">
              <img src="./assets/icons/Add-Lane.svg" alt="" />
              <div className="icontext">Add lane</div>
            </Link>

            <Link to={"users"} className="dashboard-icon-wrap orange">
              <img src="./assets/icons/View-Users.svg" alt="" />
              <div className="icontext">View Users</div>
            </Link>

            <Link to={"assets"} className="dashboard-icon-wrap red">
              <img src="./assets/icons/View-Assets.svg" alt="" />
              <div className="icontext">View Assets</div>
            </Link>
          </div>

          <div className="dashboardBlock">
            <div>
              <div className="title">Verifications</div>
              <div className="Doughnut">
                <Doughnut
                  data={verificationDonut}
                  legend={false}
                  options={{ cutoutPercentage: 80 }}
                />
              </div>
            </div>

            <div className="dashboardBlocks2">
              <div className="DashboardLegend">
                <div>
                  <span
                    className={"legend failed-" + clickState(4)}
                    onClick={() => {
                      filterVerification(4);
                    }}
                  ></span>
                  <p>{verificationInfo.failed} - Failed</p>
                </div>
                <div>
                  <span
                    className={"legend late-" + clickState(3)}
                    onClick={() => {
                      filterVerification(3);
                    }}
                  />
                  <p>{verificationInfo.late} - Late</p>
                </div>
                <div>
                  <span
                    className={"legend today-" + clickState(0)}
                    onClick={() => {
                      filterVerification(0);
                    }}
                  />
                  <p>{verificationInfo.today} - Today</p>
                </div>
                <div>
                  <span
                    className={"legend soon-" + clickState(1)}
                    onClick={() => {
                      filterVerification(1);
                    }}
                  />
                  <p>{verificationInfo.soon} - Soon</p>
                </div>
                <div>
                  <span
                    className={"legend done-" + clickState(2)}
                    onClick={() => {
                      filterVerification(2);
                    }}
                  />
                  <p>{verificationInfo.done} - Done</p>
                </div>
              </div>

              <table className="dashboardTable">
                <thead className="DashboardGridTitles">
                  <tr>
                    {tableColumns(
                      "Status",
                      "string",
                      verifications,
                      setVerifications
                    )}
                    {tableColumns(
                      "Due Date",
                      "DateTime",
                      verifications,
                      setVerifications
                    )}
                    {tableColumns(
                      "Days Left",
                      "DateTime",
                      verifications,
                      setVerifications,
                      "tableRight"
                    )}
                    {tableColumns(
                      "Assigned To",
                      "string",
                      verifications,
                      setVerifications
                    )}
                    {tableColumns(
                      "Lanes",
                      "string",
                      verifications,
                      setVerifications
                    )}
                    {tableColumns(
                      "Stores",
                      "string",
                      verifications,
                      setVerifications
                    )}
                  </tr>
                </thead>

                <tbody className="DashboardGrid">
                  {verifications
                    .slice(0)
                    .reverse()
                    .map((verification) => {
                      if (filterStatus(verification)) {
                        return [
                          <tr>
                            <th>
                              {itemCompleted(verification) != "notCompleted" ? (
                                <Link
                                  to={
                                    "/verification/deviceLogResult/" +
                                    getLog(verification)
                                  }
                                >
                                  {status(
                                    moment(verification.dueDate).local(),
                                    verification
                                  )}
                                </Link>
                              ) : (
                                status(
                                  moment(verification.dueDate).local(),
                                  verification
                                )
                              )}
                            </th>
                            <td>
                              {moment(verification.dueDate)
                                .local()
                                .format("MM/DD/YYYY")}
                            </td>
                            <td>{dateDifference(verification)}</td>
                            <td>
                              <Link
                                to={"/editUser/" + verification?.assignTo?.id}
                              >
                                {" "}
                                {verification?.assignTo?.firstName}{" "}
                                {verification?.assignTo?.lastName}{" "}
                              </Link>
                            </td>
                            <td>
                              <Link to={"/verification"}>
                                {" "}
                                {verification.lane?.name}{" "}
                              </Link>
                            </td>
                            <td>{verification.lane?.store?.name}</td>
                          </tr>,
                        ];
                      }
                    })}
                </tbody>
              </table>
            </div>
          </div>

          <div className="dashboardBlock">
            <div>
              <div className="title">Maintenances</div>
              <div className="Doughnut">
                <Doughnut
                  data={maintenanceDonut}
                  legend={false}
                  options={{ cutoutPercentage: 80 }}
                />
              </div>
            </div>

            <div className="dashboardBlocks2">
              <div className="DashboardLegend">
                <div>
                  <span
                    className={"legend failed-" + clickState(9)}
                    onClick={() => {
                      filterVerification(9);
                    }}
                  ></span>
                  <p>{maintenanceInfo.failed} - Failed</p>
                </div>
                <div>
                  <span
                    className={"legend late-" + clickState(8)}
                    onClick={() => {
                      filterVerification(8);
                    }}
                  />
                  <p>{maintenanceInfo.late} - Late</p>
                </div>
                <div>
                  <span
                    className={"legend today-" + clickState(5)}
                    onClick={() => {
                      filterVerification(5);
                    }}
                  />
                  <p>{maintenanceInfo.today} - Today</p>
                </div>
                <div>
                  <span
                    className={"legend soon-" + clickState(6)}
                    onClick={() => {
                      filterVerification(6);
                    }}
                  />
                  <p>{maintenanceInfo.soon} - Soon</p>
                </div>
                <div>
                  <span
                    className={"legend done-" + clickState(7)}
                    onClick={() => {
                      filterVerification(7);
                    }}
                  />
                  <p>{maintenanceInfo.done} - Done</p>
                </div>
              </div>

              <table className="dashboardTable">
                <thead className="DashboardGridTitles">
                  <tr>
                    {tableColumns(
                      "Status",
                      "string",
                      maintenance,
                      setMaintenance
                    )}
                    {tableColumns(
                      "Due Date",
                      "DateTime",
                      maintenance,
                      setMaintenance
                    )}
                    {tableColumns(
                      "Days Left",
                      "DateTime",
                      maintenance,
                      setMaintenance
                    )}
                    {tableColumns(
                      "Assigned To",
                      "string",
                      maintenance,
                      setMaintenance
                    )}
                    {tableColumns(
                      "Description",
                      "string",
                      maintenance,
                      setMaintenance
                    )}
                    {tableColumns(
                      "Stores",
                      "string",
                      maintenance,
                      setMaintenance
                    )}
                  </tr>
                </thead>

                <tbody className="DashboardGrid">
                  {maintenance
                    .slice(0)
                    .reverse()
                    .map((maintenance) => {
                      if (filterMaintenance(maintenance)) {
                        return [
                          <tr>
                            <th>
                              {itemCompleted(maintenance) != "notCompleted" ? (
                                <Link
                                  to={
                                    "/maintenance/log/details/" +
                                    getLog(maintenance)
                                  }
                                >
                                  {status(
                                    moment(maintenance.dueDate).local(),
                                    maintenance
                                  )}
                                </Link>
                              ) : (
                                status(
                                  moment(maintenance.dueDate).local(),
                                  maintenance
                                )
                              )}
                            </th>
                            <td>
                              {moment(maintenance.dueDate)
                                .local()
                                .format("MM/DD/YYYY")}
                            </td>
                            <td>{dateDifference(maintenance)}</td>
                            <td>
                              <Link
                                to={"/editUser/" + maintenance?.assignTo?.id}
                              >
                                {" "}
                                {maintenance?.assignTo?.firstName}{" "}
                                {maintenance?.assignTo?.lastName}{" "}
                              </Link>
                            </td>
                            <td>{maintenance?.asset.description} </td>
                            <td>
                              {maintenance?.asset?.department?.store?.name}
                            </td>
                          </tr>,
                        ];
                      }
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </React.Fragment>
    </DashboardLayout>
  );

  function dateDifference(verification) {
    var now = moment(new Date());
    var diffNumber = moment(verification.dueDate).diff(now);

    if (
      moment(moment().startOf("day")).diff(verification.dueDate, "days") == 0
    ) {
      return moment(moment().startOf("day")).diff(verification.dueDate, "days");
    } else {
      if (now > moment(verification.dueDate)) {
        return (
          "-" +
          moment(moment().startOf("day")).diff(verification.dueDate, "days")
        );
      } else {
        return Math.abs(
          moment(moment().startOf("day")).diff(verification.dueDate, "days")
        );
      }
    }
  }
};

export default Dashboard;
