/* eslint-disable */

import React, { useState, useEffect } from "react";
import DashboardLayout from "../../layouts/DashboardLayout";
import ApiService from "../../services/ApiService";
import { getImage, tableColumns, getSearch, OptionsDropDown } from "../../services/HelperMethod";
import Loading from "../../components/Loading";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";

function Lanes(props) {
    const [isLoading, setisLoading] = useState(false);

    const [lanes, setLanes] = useState([]);
    const [originalLanes, setOriginalLanes] = useState([]);

    const apiService = ApiService();

    useEffect(() => {
        getLanes();
    }, []);

    function getLanes() {
        setisLoading(true);
        apiService.getLanes().then((data: any) => {
            data = data.data;

            setLanes(data);
            setOriginalLanes(data);
            setisLoading(false);
        });
    }

    function addLaneBtn() {
        props.history.push("/addLane");
    }

    function getLocationUI(lat, long) {
        if (!lat || !long) {
            return <></>;
        }
        return (
            <div>
                <a target="_blank" href={`https://map.google.com/maps?q=${lat},${long}`}>
                    <div className="mw-50 badge"> {lat}</div>
                    <div className="mw-50 badge"> {long}</div>
                    <i className="fas fa-map-marked-alt"></i>
                </a>
            </div>
        );
    }

    return (
        <DashboardLayout title="My Lanes">
            <Loading isLoading={isLoading} />
            <React.Fragment>
                <input className="btn btn-success btn-pcid mt-3 ml-3 mb-2" type="button" value="Add Lane"
                    onClick={() => { addLaneBtn(); }} />
                <div className="table-responsive">
                    <div className="search">
                        {getSearch(lanes, setLanes, ["number", "name", "store.name", "lat", "long"],
                            originalLanes)}
                    </div>
                    <table className="table table-borderless table-v-center">
                        <thead>
                            <tr className="colored-row">
                                <th scope="col" />
                                <th scope="col" />
                                {tableColumns("Lane Number", "number", lanes, setLanes)}
                                {tableColumns("Lane Name", "name", lanes, setLanes)}
                                {tableColumns("Store", "store.name", lanes, setLanes)}
                                {tableColumns("Lat / Long", "lat", lanes, setLanes)}
                            </tr>
                        </thead>
                        <tbody>
                            {lanes.map((lane: any) => {
                                return (<React.Fragment key={lane.id}>

                                {/* Adds space between table columns */}
                                <div className="table-space"> 
                                </div>
                                
                                    <tr>
                                        <td scope="col">
                                            <OptionsDropDown>
                                                <Dropdown.Item
                                                    onClick={() => { props.history.push("/editLane/" + lane.id); }}>
                                                    Edit
                                                </Dropdown.Item>
                                            </OptionsDropDown>
                                        </td>
                                        <td scope="col">
                                            <Link to={"/editLane/" + lane.id}>
                                                <div className="profile-image"
                                                    style={{ backgroundImage: `url(${getImage(lane.imageURL)})` }} />
                                            </Link>
                                        </td>
                                        <td scope="col">{lane.number}</td>
                                        <td>{lane.name}</td>
                                        <td>{lane.store.name}</td>
                                        <td>{getLocationUI(lane.lat, lane.long)}
                                        </td>
                                    </tr>
                                    <tr className="lane-section">
                                        <td/>
                                        <td colSpan={5} className="inner-row">
                                        <div className="map-grid">
                                            <div className="map-auto"></div>
                                            <strong className="map-auto">Serial #</strong>
                                            <strong className="map-auto">Model #</strong>
                                            <strong className="map-auto">PinPad</strong>
                                        </div>

                                            {lane.pciEquipments.map((device) => {
                                                return (<React.Fragment key={device.id}>
                                                    <div className="map-grid">
                                                        <div className="map-auto">
                                                            <OptionsDropDown>
                                                                <Dropdown.Item
                                                                    onClick={() => {
                                                                        props.history.push("/editEquipment/" + device.id);
                                                                    }}>
                                                                    Edit
                                                                </Dropdown.Item>
                                                            </OptionsDropDown>
                                                        </div>
                                                        <div className="map-auto">
                                                            {device.serial}
                                                        </div>
                                                        <div className="map-auto">
                                                            {device.model}
                                                        </div>
                                                        <div className="map-auto">
                                                            {device.pinPad}
                                                        </div>
                                                    </div>
                                                </React.Fragment>);
                                            })}
                                        </td>
                                        <td />
                                    </tr>
                                    {lane.pciEquipments.length == 0
                                        ? <tr className="add-item">
                                            <th scope="row" />
                                            <td colSpan={3}>
                                                <Link className="table-add-link"
                                                    to={"/pciEquipments/addEquipment/" + lane?.store?.id + "/" + lane?.id}>
                                                    <div className="row">
                                                        <div className="col-auto">
                                                            <i className="fas fa-plus-circle mr-4 icon-green ml-3" />{" "}
                                                        </div>
                                                        <div className="col-auto">
                                                            Add New Terminals
                                                        </div>
                                                    </div>
                                                </Link>
                                            </td>
                                            <td />
                                            <td />
                                        </tr>
                                        : null
                                    }
                                </React.Fragment>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </React.Fragment>
        </DashboardLayout>
    );
}

export default Lanes;
