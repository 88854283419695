/* eslint-disable */

import React, { useState } from "react";
import Loading from "../components/Loading";
import ApiService from "../services/ApiService";
import { toast } from "react-toastify";
import { showValidationErrors } from "../services/HelperMethod";

const ResetPassword = (props: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const apiService = ApiService();

  function resetPassword() {
    setIsLoading(true);

    apiService
      .requestResetPassword(email)
      .then((data: any) => {
        data = data.data;
        setIsLoading(false);
        if (data.errorType) {
          toast.error(data.errorExplanation);
          return;
        }
        toast(`Email has sent to reset your password`);
        props.history.push("/login");
      })
      .catch((err) => {
        setIsLoading(false);
        showValidationErrors(err);
      });
  }

  return (
    <div className="container login-wrapper">
      <div className="row justify-content-center center-screen pcid-login">
        <Loading isLoading={isLoading} />
        <div className="col-md-6">
          <img
            className="pcid-logo"
            src="./assets/images/pcid_logo.png"
            alt="pcid"
            width="200"
          />
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <div className="form-group mt-5">
              <input
                autoFocus
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                className="form-control pcid-textbox"
                type="text"
                name="username"
                id="username"
                placeholder="Username or Email"
              />
            </div>
            <div className="form-group mt-5">
              <div className="row justify-content-center">
                <div className="col-sm-6">
                  <button
                    className="btn btn-success btn-block btn-pcid"
                    onClick={() => {
                      resetPassword();
                    }}
                  >
                    Reset Password
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
