/* eslint-disable */

import { useParams, Link } from "react-router-dom";
import ApiService from "../../services/ApiService";
import DashboardLayout from "../../layouts/DashboardLayout";
import React, { useEffect, useState } from "react";
import TextBox from "../../components/TextBox";
import { Fragment } from "react";

const AddDeviceQuestions = (props) => {
    const apiService = ApiService();
    const { deviceId: laneId, questionId } = useParams() as any;
    const [title, setTitle] = useState("Add Questions");

    useEffect(() => {
        if (questionId) {
            setTitle("Edit Question");
        }
    }, []);

    return (
        <DashboardLayout title={title}>
            <Fragment />
        </DashboardLayout>
    );
};

export default AddDeviceQuestions;
