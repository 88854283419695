import { CSSProperties } from "@material-ui/core/styles/withStyles";
import React, { useState } from "react";
import Cropper from "react-easy-crop";
import getCroppedImg from "../services/ImageCrop";

interface AddCropperProps {
  image: string;
  isShow: boolean;
  onComplete: CallableFunction;
  styles?: CSSProperties;
}

const AddCropper = (props: AddCropperProps) => {
  const [cropperZoom, setCropperZoom] = useState(1);
  const [cropperCrop, setCropperCrop] = useState({ x: 0, y: 0 });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null as any);

  if (!props.image || !props.isShow) {
    return <></>;
  }

  return (
    <div style={props.styles} className="cropper-wrap">
      <div className="cropper-inner">
        <div className="cropper-component">
          <Cropper image={props.image} crop={cropperCrop}
            zoom={cropperZoom}
            onCropChange={(crop) => {
              setCropperCrop(crop);
            }}
            onCropComplete={(croppedArea, croppedAreaPixels) => {
              setCroppedAreaPixels(croppedAreaPixels);
            }}
            onZoomChange={(zoom) => {
              setCropperZoom(zoom);
            }}
          />
        </div>

        <div className="row p-3">
          <div className="col-md-6">
            <button
              type="button"
              className="btn btn-danger btn-block mt-3"
              onClick={async () => {
                props.onComplete(null);
              }}
            >
              Cancel
            </button>
          </div>

          <div className="col-md-6">
            <button
              className="btn btn-success btn-block btn-pcid mt-3"
              type="button"
              onClick={async () => {
                try {
                //   let img = await getCroppedImg(props.image, croppedAreaPixels);
                  props.onComplete(props.image);
                } catch (error) { }
              }}
            >
              Set
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCropper;
