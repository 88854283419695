import React, { useState, useEffect, useRef, useContext } from "react";
import ApiService from "../../services/ApiService";

import AppButton from "../../components/AppButton";
import Email from "../../components/Email";
import parse from 'html-react-parser';
import uuid from 'react-uuid';
import { useParams } from "react-router-dom";
import Verification from "../Verification/Verification";

import DashboardLayout from "../../layouts/DashboardLayout";

function useForceUpdate(){
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update the state to force render
}

function Form() {
        const { reportID } = useParams() as any;
        const forceUpdate = useForceUpdate();

        const [editable, setEdit] = useState(Boolean);
        const [emailOpen, setEmailOpen] = useState("");

        const [checked, setChecked] = useState([]);
        const [submissionDate, setSubmissionDate] = useState(Date);

        const [companyName, setCompanyName] = useState("");
        const [stringStores, setStringStores] = useState("");
        const [stringLanes, setStringLanes] = useState("");
        const [stringVerification, setVerification] = useState("");
        const [report, setReport] = useState([]);
        const date = new Date();

        const apiService = ApiService();
        useEffect(() => {
            getInfo();
            console.log("got info");
            getReport();
            setEdit(true);
            setEmailOpen("notOpen");
        }, []);

        function getInfo() {
            apiService.getCompanyInfo().then((data: any) => {
                data = data.data;
                setCompanyName(data.name);
            });

            apiService.getStores().then((data: any) => {
                data = data.data;
                var numStores;
                
                numStores = data.length;
                setStringStores(numStores);
            });

            apiService.getLanes().then((data: any) => {
                data = data.data;
                var numLanes = data.length;
                
                setStringLanes('' + numLanes);
            });

            apiService.getDashboardCompletedVerifications().then((data: any) => {
                data = data.data;
                var frequency = data.Frequency;
                var verification = data.length;
                // for(var i = 0; i < data.length; i++){
                //     verification = verification + data[i].Frequency;
                // }
                
                setVerification('' + verification);
            });

            apiService.getComplianceReportResults(reportID).then((data: any) => {
                data = data.data;

                setSubmissionDate(data.submissionDate);
            });
           
        }

        function reportUpdate(e) {

            var hold = checked; 
            hold[e.currentTarget.name] = e.currentTarget.value; 
            setChecked(hold);  
            forceUpdate();
        }

        function getReport() {
            apiService.getComplianceQuestions().then((data: any) => {
                data = data.data;
                var report = [];

                for(var i = 0; i < data.length; i++){
                    report[i] = "<b>" + data[i].specId + "</b>";
                    report[i] = report[i] + "<div>" + data[i].question + "</div>";
                    report[i] = report[i] + "<div class='testing'>" + data[i].expectation + "</div>";
                }

                setReport(report);
            });

            apiService.getComplianceAnswers(reportID).then((data: any) => {
                data = data.data;
                var answers = [];
                for (var i = 0; i < data.length; i++){
                    answers[i] = '' + data[i].answer;
                }

                setChecked(answers);

            });
        }

        function save() {
            setEdit(true);

            var data = [];
            data[0] = reportID;
            for (var i = 0; i < checked.length; i++){
                data[i+1] = checked[i];
            }
            apiService.updateComplianceAnswers(data);
        }

        function post() {
            save();
            apiService.postComplianceAnswers(reportID);
            window.location.reload();
        }

        function edit() {
            setEdit(false);
        }

        function sendToProcessor(){
            var request = []
            var guid = uuid();
            request[0] = guid;
            request[1] = 1;
            console.log(guid);
            setEmailOpen("overlay");
            apiService.shareReport(request);
        }

        function printButton(){
            var Submission = new Date(submissionDate);
            if(Submission <= date){
                return(
                    <a href={"http://localhost:49977/web/ComplianceQuestion/GetPDF/" + reportID} className="printPDF" download> Print as PDF</a>
                )
            }
            
        }

        function formButtons(){
            var Submission = new Date(submissionDate);
            if(Submission <= date){
                return(
                    <AppButton
                    className="complianceProcess"
                    onClick={() => {
                        sendToProcessor();
                    }}
                    >
                    Send To Processor
                    </AppButton>
                )
            }
            else{
                if(editable){
                    return(
                        <AppButton
                        className="complianceSubmit"
                        onClick={() => {
                            edit();
                        }}
                        >
                        Edit
                        </AppButton>
                    )
                }
                else{
                    return(
                        <div>
                            <AppButton
                            className="compliancePost"
                            onClick={() => {
                                post();
                            }}
                            >
                            Post
                            </AppButton>
                            
                            <AppButton
                            className="complianceSubmit"
                            onClick={() => {
                                save();
                            }}
                            >
                            Save
                            </AppButton>
                        </div>
                    )
                }
            }
        }

        function renderEmail(){
            if(emailOpen == "overlay"){
                return (
                    <Email link= {"path"}
                    onClick={() => {
                        setEmailOpen("notOpen");
                    }}
                    />
                );
            }
        }

        return (
            <DashboardLayout title>
            <div className = "report" id={emailOpen}>
                <img src="./assets/images/pcid_logo.png" alt="pcid" width="200" id="logo"/>
                
                {printButton()}
                <div className = "Report-Header">
                    <h2>PCI Section 9</h2>
                </div>

                <div className = "cards">
                    <div className="infocard">
                        <img  src="./assets/images/store-icon.svg" alt="pcid"/>
                        <div className="infotext">
                            <h3>{stringStores}</h3>
                            <p>Locations</p>
                        </div>
                    </div>
                    <div className="infocard">
                        <img  src="./assets/images/Menu-Lanes-Active.png" alt="pcid"/>
                        <div className="infotext">
                            <h3>{stringLanes}</h3>
                            <p># of lanes</p>
                        </div>
                    </div>
                    <div className="infocard">
                        <img  src="./assets/images/shield-icon.svg" alt="pcid"/>
                        <div className="infotext">
                            <h3>{stringVerification} Day</h3>
                            <p>Verification Frequency</p>
                        </div>
                    </div>
                </div>

                    <form id="ComplianceCard" onChange={(e) => { 
                    }}>
                        <fieldset disabled={editable}>
                            <div id="ComplianceColumn"> 
                                <div className="gridColumn"> <h5>Section</h5>  </div>
                                <div className="gridColumn"> <h5>Question</h5> </div>
                                <div className="gridColumn"> <h5>Expected Testing</h5> </div> 
                                <div className="gridColumn"> <h5>Response</h5> </div>

                                {
                                    report.map((item) =>
                                    [
                                    parse('' + item),
                                    <div className="radio"> 
                                        <input type="radio" value="1" name={report.indexOf(item).toString()} checked={checked[report.indexOf(item)] === '1'} onChange={(e) =>{reportUpdate(e)}} /> <label>Yes</label>
                                        <input type="radio" value="2" name={report.indexOf(item).toString()} checked={checked[report.indexOf(item)] === '2'} onChange={(e) =>{reportUpdate(e)}}/> <label>Yes w/ CCW</label>
                                        <input type="radio" value="3" name={report.indexOf(item).toString()} checked={checked[report.indexOf(item)] === '3'} onChange={(e) =>{reportUpdate(e)}}/> <label>No</label>
                                        <input type="radio" value="4" name={report.indexOf(item).toString()} checked={checked[report.indexOf(item)] === '4'} onChange={(e) =>{reportUpdate(e)}}/> <label>N/A</label>
                                        <input type="radio" value="5" name={report.indexOf(item).toString()} checked={checked[report.indexOf(item)] === '5'} onChange={(e) =>{reportUpdate(e)}}/> <label>Not Tested</label> 
                                    </div>
                                    ])
                                }

                            </div>

                        </fieldset>
                            
                    </form>
                    {formButtons()}
                    {renderEmail()}
            </div>

            </DashboardLayout>
        );
}


export default Form;
