import React from "react";
import ClipLoader from "react-spinners/ClipLoader";

export default function AppButton(props: {
    className?;
    onClick?;
    isLoading?: boolean;
    disabled?: boolean;
    children;
}) {
    return (
        <button
            disabled={props.disabled || props.isLoading}
            className={props.className}
            onClick={(e) => {
                props?.onClick?.();
            }}
        >
            {props.isLoading ? (
                <span>
                    <ClipLoader color={"white"} loading={true} size={14} />
                </span>
            ) : (
                props.children
            )}
        </button>
    );
}
