import React, { CSSProperties, HTMLAttributes } from "react";
import { StyleHTMLAttributes } from "react";

interface LoadingProps {
    isLoading: boolean;
    style?: CSSProperties;
}

const Loading = (props: LoadingProps) => {
    if (!props.isLoading) {
        return <></>;
    }
    return (
        <div className="loading" style={props.style}>
            <div className="spinner-border">
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    );
};
export default Loading;
