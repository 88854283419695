/* eslint-disable */

import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { getUser, getImage, getGuidElement } from "../../services/HelperMethod";
import FileButton from "../../components/FileButton";
import AppCropper from "../../components/AddCropper";
import TextBox from "../../components/TextBox";
import ApiService from "../../services/ApiService";
import { toast } from "react-toastify";
import Loading from "../../components/Loading";
import { ShowGuidContext } from "../../store/StoreProvider";
import Steps from "../../components/Steps";
import AppButton from "../../components/AppButton";

function BusinessProfile() {
    const history = useHistory();

    const [loginUser, setLoginUser] = useState(null as any);
    const [imageURL, setImageURL] = useState(null as any);
    const [isCropperShow, setCropperShow] = useState(false);

    const [businessName, setBusinessName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [Email, setEmail] = useState("");
    const [Address, setAddress] = useState("");
    const [FirstName, setFirstName] = useState("");
    const [LastName, setLastName] = useState("");
    const [UserEmail, setUserEmail] = useState("");
    const [UserPhone, setUserPhone] = useState("");

    const [City, setCity] = useState("");
    const [State, setState] = useState("");
    const [Zip, setZip] = useState("");

    const [BusinessNameError, setBusinessNameError] = useState("");
    const [PhoneNumberError, setPhoneNumberError] = useState("");
    const [EmailError, setEmailError] = useState("");
    const [AddressError, setAddressError] = useState("");
    const [FirstNameError, setFirstNameError] = useState("");
    const [LastNameError, setLastNameError] = useState("");
    const [UserEmailError, setUserEmailError] = useState("");
    const [UserPhoneError, setUserPhoneError] = useState("");

    const [CityError, setCityError] = useState("");
    const [StateError, setStateError] = useState("");
    const [ZipError, setZipError] = useState("");

    const [isLoading, setIsLoading] = useState(false);
    const [showGuid, setShowGuid] = useContext(ShowGuidContext);

    useEffect(() => {
        setShowGuid(
            getGuidElement({
                elementId: "#business-profile",
                jsx: (
                    <div>
                        <p>
                            Welcome to PCEyeD! We're going <br />
                            to start by creating your business' profile
                        </p>
                        <div
                            className="cursor-pointer"
                            onClick={() => {
                                setShowGuid(null);
                            }}
                        >
                            Got it
                        </div>
                    </div>
                ),
                top: 30,
                left: 50,
            })
        );
        setLoginUser(getUser());
        apiService.getCompanyInfo().then((data: any) => {
            data = data.data;
            
            setBusinessName(data.name);
            setPhoneNumber(data.userPhone);
            setFirstName(data.userFirstName);
            setLastName(data.userLastName);
            setEmail(data.userEmail);
            setUserEmail(data.userEmail);
            // setUserPhone(data.userPhone);
        });
    }, []);

    const apiService = ApiService();

    function saveStore() {
        const request = {
            BusinessName: businessName,
            PhoneNumber: phoneNumber,
            Email,
            Address,
            FirstName,
            LastName,
            UserEmail,
            UserPhone,
            imageURL,
            City,
            State,
            Zip
        };

        setIsLoading(true);

        apiService
            .saveStore(request)
            .then((data: any) => {
                data = data.data;

                if (data.errorType) {
                    toast.error(data.errorExplanation);
                } else {
                    // toast("Everything setup! Please login", {
                    //     autoClose: 5000,
                    // });
                    return history.push({ pathname: "/setup-payments" });
                }
                setIsLoading(false);
            })
            .catch((err) => {
                handleFormErrors(err?.response?.data?.errors);
                setIsLoading(false);
            });
        // history.push("/stores");
    }

    function handleFormErrors(err) {
        if (err) {
            setAddressError(err.Address?.[0]);
            setBusinessNameError(err.BusinessName?.[0]);
            setEmailError(err.Email?.[0]);
            setFirstNameError(err.FirstName?.[0]);
            setLastNameError(err.LastName?.[0]);
            setUserPhone(err.UserPhone?.[0]);
            setUserEmailError(err.UserEmail?.[0]);
            setUserPhoneError(err.UserPhone?.[0]);
            setPhoneNumberError(err.PhoneNumber?.[0]);
            setCityError(err.City?.[0]);
            setStateError(err.State?.[0]);
            setZipError(err.Zip?.[0]);
        } else {
            toast.error("Error, Try again!");
        }
    }

    return (
        <React.Fragment>
            <Loading isLoading={isLoading} />

            <AppCropper
                isShow={isCropperShow}
                image={imageURL}
                onComplete={(e) => {
                    setImageURL(e);
                    setCropperShow(false);
                }}
            />
            <div className="full-layout">
                <div className="container">
                    <div style={{ width: "100%" }} className="white-box">
                        <div>
                            <div
                                style={{ marginBottom: "1.5rem" }}
                                className="row"
                            >
                                <div className="col-sm-12 col-md-5">
                                    <h2
                                        style={{ marginTop: "0.7rem" }}
                                        className="mb-3 topic-text ht"
                                        id={"business-profile"}
                                    >
                                        Business Profile
                                    </h2>
                                </div>
                                <div className="col-sm-12 col-md-7">
                                    <Steps iteration={3} activeIndex={1} />
                                </div>
                            </div>
                            <div>
                                <div>
                                    <p className="pl-3 general-info">
                                        Business Info
                                    </p>
                                    <form>
                                        <div className="row">
                                            <div className="col-sm-12 col-md-6">
                                                <div className="form-group mt-1">
                                                    <label
                                                        className="ml-3"
                                                        htmlFor="business-name"
                                                    >
                                                        Company *
                                                    </label>
                                                    <TextBox
                                                        type="text"
                                                        onChange={(val) => {
                                                            setBusinessName(
                                                                val
                                                            );
                                                        }}
                                                        className="form-control sign-in-inputs"
                                                        error={
                                                            BusinessNameError
                                                        }
                                                        value={businessName}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <div className="form-group mt-1">
                                                    <label
                                                        className="ml-3"
                                                        htmlFor="business-phone"
                                                    >
                                                        Phone *
                                                    </label>
                                                    <TextBox
                                                        type="text"
                                                        mask="999-999-9999"
                                                        onChange={(val) => {
                                                            setPhoneNumber(val);
                                                        }}
                                                        className="form-control sign-in-inputs"
                                                        error={PhoneNumberError}
                                                        value={phoneNumber}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <div className="form-group mt-1">
                                                    <label
                                                        className="ml-3"
                                                        htmlFor="business-email"
                                                    >
                                                        Email *
                                                    </label>
                                                    <TextBox
                                                        type="email"
                                                        onChange={(val) => {
                                                            setEmail(val);
                                                        }}
                                                        className="form-control sign-in-inputs"
                                                        error={EmailError}
                                                        value={Email}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <div className="form-group mt-1">
                                                    <label
                                                        className="ml-3"
                                                        htmlFor="business-address"
                                                    >
                                                        Address *
                                                    </label>
                                                    <TextBox
                                                        type="text"
                                                        onChange={(val) => {
                                                            setAddress(val);
                                                        }}
                                                        className="form-control sign-in-inputs"
                                                        error={AddressError}
                                                        value={Address}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <div className="form-group mt-1">
                                                    <label
                                                        className="ml-3"
                                                        htmlFor="business-address"
                                                    >
                                                        City *
                                                    </label>
                                                    <TextBox
                                                        type="text"
                                                        onChange={(val) => {
                                                            setCity(val);
                                                        }}
                                                        className="form-control sign-in-inputs"
                                                        error={CityError}
                                                        value={City}
                                                    />
                                                </div>
                                            </div><div className="col-sm-12 col-md-6">
                                                <div className="form-group mt-1">
                                                    <label
                                                        className="ml-3"
                                                        htmlFor="business-address"
                                                    >
                                                        State *
                                                    </label>
                                                    <TextBox
                                                        type="text"
                                                        onChange={(val) => {
                                                            setState(val);
                                                        }}
                                                        className="form-control sign-in-inputs"
                                                        error={StateError}
                                                        value={State}
                                                    />
                                                </div>
                                            </div><div className="col-sm-12 col-md-6">
                                                <div className="form-group mt-1">
                                                    <label
                                                        className="ml-3"
                                                        htmlFor="business-address"
                                                    >
                                                        Zip Code *
                                                    </label>
                                                    <TextBox
                                                        type="text"
                                                        onChange={(val) => {
                                                            setZip(val);
                                                        }}
                                                        className="form-control sign-in-inputs"
                                                        error={ZipError}
                                                        value={Zip}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-12">
                                                <p className="pl-3 mt-4 general-info">
                                                    Point of Contact Info{" "}
                                                </p>
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <div className="form-group mt-1">
                                                    <label
                                                        className="ml-3"
                                                        htmlFor="poc-fname"
                                                    >
                                                        First Name *
                                                    </label>
                                                    <TextBox
                                                        type="text"
                                                        onChange={(val) => {
                                                            setFirstName(val);
                                                        }}
                                                        className="form-control sign-in-inputs"
                                                        error={FirstNameError}
                                                        value={FirstName}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <div className="form-group mt-1">
                                                    <label
                                                        className="ml-3"
                                                        htmlFor="poc-lname"
                                                    >
                                                        Last Name *
                                                    </label>

                                                    <TextBox
                                                        type="text"
                                                        onChange={(val) => {
                                                            setLastName(val);
                                                        }}
                                                        className="form-control sign-in-inputs"
                                                        error={LastNameError}
                                                        value={LastName}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <div className="form-group mt-1">
                                                    <label
                                                        className="ml-3"
                                                        htmlFor="poc-email"
                                                    >
                                                        Email *
                                                    </label>

                                                    <TextBox
                                                        type="text"
                                                        onChange={(val) => {
                                                            setUserEmail(val);
                                                        }}
                                                        className="form-control sign-in-inputs"
                                                        error={UserEmailError}
                                                        value={UserEmail}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <div className="form-group mt-1">
                                                    <label
                                                        className="ml-3"
                                                        htmlFor="poc-phone"
                                                    >
                                                        Phone *
                                                    </label>
                                                    <TextBox
                                                        mask="999-999-9999"
                                                        type="text"
                                                        onChange={(val) => {
                                                            setUserPhone(val);
                                                        }}
                                                        className="form-control sign-in-inputs"
                                                        error={UserPhoneError}
                                                        value={UserPhone}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                            }}
                                        >
                                            {/* <div className="form-group mt-1">
                                                <input
                                                    className="btn btn-secondary btn-block btn-pcid-cancel mt-3"
                                                    type="button"
                                                    value="Cancel"
                                                    onClick={() => {
                                                        history.push("/login");
                                                    }}
                                                />
                                            </div> */}
                                            <div
                                                style={{
                                                    width: 220,
                                                }}
                                            >
                                                <AppButton
                                                    isLoading={isLoading}
                                                    disabled={
                                                        !businessName ||
                                                        !phoneNumber ||
                                                        !Email ||
                                                        !Address ||
                                                        !FirstName ||
                                                        !LastName ||
                                                        !Email ||
                                                        !UserPhone
                                                    }
                                                    className="btn"
                                                    onClick={(e) => {
                                                        e?.preventDefault();
                                                        saveStore();
                                                    }}
                                                >
                                                    Next
                                                </AppButton>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default BusinessProfile;
