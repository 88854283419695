/* eslint-disable */

import React, { useState, useEffect } from "react";
import DashboardLayout from "../../layouts/DashboardLayout";
import ApiService from "../../services/ApiService";
import { Link } from "react-router-dom";
import {
  OptionsDropDown,
  getImage,
  tableColumns,
  getSearch,
} from "../../services/HelperMethod";
import { Dropdown } from "react-bootstrap";
import Loading from "../../components/Loading";
import moment from "moment";
import CurrencyFormat from "react-currency-format";
import { toast } from "react-toastify";

const Assets = (props: any) => {
  const apiService = ApiService();

  const [departments, setDepartments] = useState([] as any[]);
  const [assets, setAssets] = useState([] as any[]);
  const [originalAssets, setOriginalAssets] = useState([] as any[]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    apiService.getAssets().then((data: any) => {
      data = data.data;

      setAssets(data);
      setOriginalAssets(data);
      setIsLoading(false);
    });

    apiService.getDepartments().then((data: any) => {
      data = data.data;
      
      setDepartments(data);
    });
  }, []);

  return (
    <DashboardLayout title="Asset Management">
      <Loading isLoading={isLoading} />
      <React.Fragment>
        <div className="row">
          <div className="col-auto">
            <input
              className="btn btn-success btn-pcid ml-3 mb-2"
              type="button"
              value="Create Asset"
              onClick={() => {
                if(departments.length <= 0){
                  toast("Please setup your departments first")
                }else{
                  props.history.push("/assets/addAsset");
                }
              }}
            />
          </div>
        </div>
        <div className="table-responsive">
          <div className="search">
            {getSearch(
              assets,
              setAssets,
              [
                "description",
                "modelNumber",
                "serialNumber",
                "department.store.name",
                "department.name",
                "replacementCost",
              ],
              originalAssets
            )}
          </div>
          <table className="table table-borderless table-v-center">
            <thead>
              <tr className="tbl-ths colored-row">
                <th scope="col"></th>
                <th scope="col"></th>
                {tableColumns("Description", "description", assets, setAssets)}
                {tableColumns("Model", "modelNumber", assets, setAssets)}
                {tableColumns("Serial", "serialNumber", assets, setAssets)}
                {tableColumns("Date Put In Service", "date", assets, setAssets)}
                {tableColumns(
                  "Store",
                  "department.store.name",
                  assets,
                  setAssets
                )}
                {tableColumns(
                  "Department",
                  "department.name",
                  assets,
                  setAssets
                )}
                {tableColumns(
                  "Replacement Cost",
                  "replacementCost",
                  assets,
                  setAssets
                )}
              </tr>
            </thead>
            <tbody>
              {assets.map((asset: any) => {
                return (
                  <tr key={asset.id}>
                    <td>
                      <OptionsDropDown>
                        <Dropdown.Item
                          onClick={() => {
                            props.history.push("/editAsset/" + asset.id);
                          }}
                        >
                          Edit Asset
                        </Dropdown.Item>
                      </OptionsDropDown>
                    </td>
                    <td>
                      <Link to={"/editAsset/" + asset.id}>
                        <div
                          className="profile-image"
                          style={{
                            backgroundImage: `url(${getImage(asset.imageURL)})`,
                          }}
                        />
                      </Link>
                    </td>
                    <td>{asset.description}</td>
                    <td>{asset.modelNumber}</td>
                    <td>{asset.serialNumber}</td>
                    <td>{moment(asset.date).local().toNow(true)}</td>
                    <td>{asset.department?.store?.name}</td>
                    <td>{asset.department?.name}</td>
                    <td>
                      <CurrencyFormat
                        value={asset.replacementCost}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <div className="card mt-4">
          <div className="card-body">
            <h2 className="title text-center mt-2 mb-3">Departments</h2>
            <div className="questions mb-2">
              <Link
                className="btn btn-pcid mt-3 ml-3 mb-2"
                to={"/addDepartment/"}
              >
                Add Department
              </Link>
            </div>
            <div>
              {departments.map((d: any) => {
                return (
                  <div key={d.id} className="question-item colored-row">
                    <Link to={`/editDepartment/${d.id}`}>
                      <div className="row align-items-center">
                        <div className="col-auto">
                          <i className="fas fa-ellipsis-h ml-3 mr-3"></i>{" "}
                        </div>
                        <div className="col-auto">
                          <div className="small">
                            <div
                              className="profile-image"
                              style={{
                                backgroundImage: `url(${getImage(d.imageURL)})`,
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-auto">{d.name}</div>
                        <div className="col-auto">
                          <div className="ml-4 badge mr-1">Store: </div>
                          <span className="badge">{d.store?.name}</span>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </React.Fragment>
    </DashboardLayout>
  );
};

export default Assets;
