/* eslint-disable */

import React, { useState, useEffect } from "react";
import DashboardLayout from "../../layouts/DashboardLayout";
import ApiService from "../../services/ApiService";
import {
    getImage,
    tableColumns,
    OptionsDropDown,
    getSearch,
} from "../../services/HelperMethod";
import { Link } from "react-router-dom";
import Loading from "../../components/Loading";
import moment from "moment";
import { Dropdown } from "react-bootstrap";

const TerminalReports = (props) => {
    const apiService = ApiService();

    const [isLoading, setIsLoading] = useState(false);
    const [devices, setDevices] = useState([] as any[]);
    const [originalDevices, setOriginalDevices] = useState([] as any[]);

    useEffect(() => {
        getDevices();
    }, []);

    function getDevices() {
        setIsLoading(true);

        apiService.getDevices().then((data: any) => {
            data = data.data;
            setDevices(data);
            setOriginalDevices(data);
            setIsLoading(false);
        });
    }

    return (
        <DashboardLayout title="Terminals">
            <Loading isLoading={isLoading} />
            {MyPciEquipments()}
        </DashboardLayout>
    );

    function additionalImage(device) {
        let htm = [] as any[];

        if (device.frontImageURL) {
            htm.push(
                <div className="pro-pic small" key="right">
                    <div className="badge badge-info">Front</div>
                    <div
                        className="mt-1 profile-image"
                        style={{
                            backgroundImage: `url(${getImage(
                                device.frontImageURL
                            )})`,
                        }}
                    ></div>
                </div>
            );
        }

        if (device.backImageURL) {
            htm.push(
                <div className="pro-pic small" key="right">
                    <div className="badge badge-info">Back</div>
                    <div
                        className="mt-1 profile-image"
                        style={{
                            backgroundImage: `url(${getImage(
                                device.backImageURL
                            )})`,
                        }}
                    ></div>
                </div>
            );
        }

        if (device.leftImageURL) {
            htm.push(
                <div className="pro-pic small" key="left">
                    <div className="badge badge-info">Left</div>
                    <div
                        className="profile-image"
                        style={{
                            backgroundImage: `url(${getImage(
                                device.leftImageURL
                            )})`,
                        }}
                    />
                </div>
            );
        }
        if (device.topImageURL) {
            htm.push(
                <div className="pro-pic small" key="top">
                    <div className="badge badge-info">Top</div>
                    <div
                        className="mt-1 profile-image"
                        style={{
                            backgroundImage: `url(${getImage(
                                device.topImageURL
                            )})`,
                        }}
                    />
                </div>
            );
        }
        if (device.bottomImageURL) {
            htm.push(
                <div className="pro-pic small" key="bottom">
                    <div className="badge badge-info">Bottom</div>
                    <div
                        className="profile-image"
                        style={{
                            backgroundImage: `url(${getImage(
                                device.bottomImageURL
                            )})`,
                        }}
                    />
                </div>
            );
        }
        if (device.rightImageURL) {
            htm.push(
                <div className="pro-pic small" key="right">
                    <div className="badge badge-info">Right</div>
                    <div
                        className="mt-1 profile-image"
                        style={{
                            backgroundImage: `url(${getImage(
                                device.rightImageURL
                            )})`,
                        }}
                    ></div>
                </div>
            );
        }

        return <div>{htm}</div>;
    }

    function MyPciEquipments() {
        return (
            <React.Fragment>
                <input
                    className="btn btn-success btn-pcid mt-3 ml-3 mb-2"
                    type="button"
                    value="Add Terminals"
                    onClick={() => {
                        props.history.push("/pciEquipments/addEquipment");
                    }}
                />
                <div className="table-responsive">
                    <div className="search">
                        {getSearch(
                            devices,
                            setDevices,
                            [
                                "pinPad",
                                "serial",
                                "assetTag",
                                "datePlaced",
                                "lane.name",
                                "lane.store.name",
                            ],
                            originalDevices
                        )}
                    </div>
                    <table className="table table-borderless table-v-center">
                        <thead>
                            <tr className="colored-row">
                                <th scope="col"></th>
                                <th scope="col"></th>
                                {tableColumns(
                                    "Serial",
                                    "serial",
                                    devices,
                                    setDevices
                                )}
                                {tableColumns(
                                    "Placed Date",
                                    "datePlaced",
                                    devices,
                                    setDevices
                                )}
                                {tableColumns(
                                    "Placed By",
                                    "placedBy.firstName",
                                    devices,
                                    setDevices
                                )}
                                {tableColumns(
                                    "Model",
                                    "model",
                                    devices,
                                    setDevices
                                )}
                                {tableColumns(
                                    "Lane",
                                    "lane.name",
                                    devices,
                                    setDevices
                                )}
                                {tableColumns(
                                    "Store",
                                    "lane.store.name",
                                    devices,
                                    setDevices
                                )}
                                {tableColumns(
                                    "PCeyeD Barcode",
                                    "barcode",
                                    devices,
                                    setDevices
                                )}
                                {tableColumns(
                                    "Assigned To",
                                    "assignedTo.firstname",
                                    devices,
                                    setDevices
                                )}
                                {tableColumns(
                                    "Frequency",
                                    "frequency",
                                    devices,
                                    setDevices
                                )}
                                {tableColumns(
                                    "Verification Date",
                                    "date",
                                    devices,
                                    setDevices
                                )}
                                {tableColumns(
                                    "Notes",
                                    "String",
                                    devices,
                                    setDevices
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {devices.map((device) => {
                                return (
                                    <tr key={device.id}>
                                        <td scope="col">
                                            <OptionsDropDown>
                                                <Dropdown.Item
                                                    onClick={() => {
                                                        props.history.push(
                                                            "editEquipment/" +
                                                                device.id
                                                        );
                                                    }}
                                                >
                                                    Edit
                                                </Dropdown.Item>
                                            </OptionsDropDown>
                                        </td>
                                        <td scope="col">
                                            <div className="row no-gutters justify-content-center align-items-center">
                                                <div className="col-auto">
                                                    <Link
                                                        to={
                                                            "editEquipment/" +
                                                            device.id
                                                        }
                                                    >
                                                        <div
                                                            className="profile-image"
                                                            style={{
                                                                backgroundImage: `url(${getImage(
                                                                    device.mainImageURL
                                                                )})`,
                                                            }}
                                                        />
                                                    </Link>
                                                </div>
                                                {/* <div className="col-6">
                                                    {additionalImage(device)}
                                                </div> */}
                                            </div>
                                        </td>
                                        <td>{device.serial}</td>
                                        <td>
                                            {moment(device.datePlaced).format(
                                                "ll"
                                            )}
                                        </td>
                                        <td>
                                            <Link to="/users">
                                                {
                                                    (device.placedBy || {})
                                                        .firstName
                                                }
                                            </Link>
                                        </td>
                                        <td>{device.model}</td>
                                        <td>{device.lane.name}</td>
                                        <td>{device.lane.store.name}</td>
                                        <td>{device.assetTag}</td>
                                        <td>{device.placedBy.firstName + " " + device.placedBy.lastName}</td>
                                        <td>{"Two Weeks"}</td>
                                        <td>{device.datePlaced}</td>
                                        <td>{<i className="fas fa-comment-dots"></i>}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </React.Fragment>
        );
    }
};

export default TerminalReports;
