/* eslint-disable */

import React, { useState, useEffect } from "react";
import DashboardLayout from "../../layouts/DashboardLayout";
import ApiService from "../../services/ApiService";
import Loading from "../../components/Loading";
import { Dropdown } from "react-bootstrap";
import {
  OptionsDropDown,
  getImage,
  tableColumns,
  getSearch,
} from "../../services/HelperMethod";
import { Link } from "react-router-dom";

const Users = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isEditUser, setIsEditUser] = useState(false);

  const [users, setUsers] = useState([]);
  const [originalUsers, setOriginalUsers] = useState([]);
  const apiService = ApiService();

  useEffect(() => {
    setIsLoading(true);
    getAllUsers();
  }, []);

  function getAllUsers() {
    apiService.getAllUsers().then((data: any) => {
      data = data.data;
      setUsers(data);
      setOriginalUsers(data);
      setIsLoading(false);
    });
  }

  return (
    <DashboardLayout title="Users">
      <Loading isLoading={isLoading} />
      {MyUsers()}
    </DashboardLayout>
  );

  function MyUsers() {
    return (
      <React.Fragment>
        <input
          className="btn btn-success btn-pcid mt-3 ml-3 mb-2"
          type="button"
          value="Add Users"
          onClick={() => {
            props.history.push("/users/addUser");
          }}
        />
        <div className="table-responsive">
          {getSearch(
            users,
            setUsers,
            [
              "username",
              "firstName",
              "lastName",
              "phone",
              "email",
              "selectedRole",
            ],
            originalUsers
          )}

          <table className="table table-borderless table-v-center">
            <thead>
              <tr className="colored-row">
                <th scope="col"></th>
                <th scope="col"></th>

                {tableColumns("Username", "username", users, setUsers)}
                {tableColumns("First Name", "firstName", users, setUsers)}
                {tableColumns("Last Name", "lastName", users, setUsers)}
                {tableColumns("Phone", "phone", users, setUsers)}
                {tableColumns("Email", "email", users, setUsers)}
                {tableColumns("Security", "selectedRole", users, setUsers)}
              </tr>
            </thead>
            <tbody>
              {users.map((u: any) => {
                return (
                  <tr key={u.id}>
                    <td>
                      <OptionsDropDown>
                        <Dropdown.Item
                          onClick={() => {
                            props.history.push("/editUser/" + u.id);
                          }}
                        >
                          Edit
                        </Dropdown.Item>
                      </OptionsDropDown>
                    </td>
                    <td>
                      <Link to={"/editUser/" + u.id}>
                        <div
                          className="profile-image"
                          style={{
                            backgroundImage: `url(${getImage(u.imageURL)})`,
                          }}
                        />
                      </Link>
                    </td>
                    <td>{u.username}</td>
                    <td>{u.firstName}</td>
                    <td>{u.lastName}</td>
                    <td className="text-nowrap">{u.phone}</td>
                    <td>{u.email}</td>
                    <td>{u.selectedRole}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </React.Fragment>
    );
  }
};

export default Users;
