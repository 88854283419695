/* eslint-disable */

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ApiService from "../../services/ApiService";
import { Link } from "react-router-dom";
import DashboardLayout from "../../layouts/DashboardLayout";
import moment from "moment";
import { Dropdown } from "react-bootstrap";
import {
    OptionsDropDown,
    frequencyText,
    getImage,
    tableColumns,
    getColumnSearch,
    dateDiff,
    getSearch,
} from "../../services/HelperMethod";
import AppButton from "../../components/AppButton";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';

function useForceUpdate(){
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update the state to force render
}

const VerificationLogByTerminal = (props) => {
    const { deviceId } = useParams();
    const apiService = ApiService();
    const [logs, setLogs] = useState([] as any[]);
    const [originalLogs, setOriginalLogs] = useState([] as any[]);
    const [noDataMsg, setNoDataMsg] = useState("");
    const [filters, setFilters] = useState([]);
    const [stores, setStores] = useState([] as any[]);
    const [users, setUsers] = useState([] as any[]);

    const forceUpdate = useForceUpdate();

    useEffect(() => {
        apiService.getAllDeviceLogs().then((data: any) => {
            data = data.data;
            
            setLogs(data);
            setOriginalLogs(data);
            if (data.length == 0) {
                setNoDataMsg("No verification data yet");
            }
        });
        
        apiService.getAllUsers().then((data: any) => {
            data = data.data;
            setUsers(data);
        });

        apiService.getStores().then((data: any) => {
            data = data.data;
            setStores(data);
        });
    }, []);

    function filter(filters){
        console.log("removeValue");
        setLogs(originalLogs);
        var filteredReport = originalLogs;
    
        for(var i = 0; i < filters.length; i++){
            if(filters[i][1] == "user"){
                filteredReport = $.grep(filteredReport, function (j, n) {
                    return filters[i][0] === j.user.firstName + " " + j.user.lastName
                });
            }
            if(filters[i][1] == "store"){
                filteredReport = $.grep(filteredReport, function (j, n) {
                    return filters[i][0] === j.verification.lane?.store?.name
                });
            }
            if(filters[i][1] == "status"){
                filteredReport = $.grep(filteredReport, function (j, n) {
                    return filters[i][0] === status(j)
                });
            }
            if(filters[i][1] == "date"){
                filteredReport = $.grep(filteredReport, function (j, n) {
                    return new Date(filters[i][0].selection.startDate) < new Date(j.verifiedDate) && new Date(j.verifiedDate) < new Date(filters[i][0].selection.endDate) 
                });
            }
        }

        setLogs(filteredReport);

        forceUpdate();
    }

    function handleSelect(ranges){
        addValue(ranges, "date")
      }

    function addValue(item, type){
        var newValues = filters;
        newValues[newValues.length] = [item, type];
        setFilters(newValues);

        filter(newValues);
    }

    function removeValue(item){
        var currentValues = filters;
        var newValues = currentValues.filter(e => e !== item);
        setFilters(newValues);

        filter(newValues);        
    }

    function status(log){
        if(log.isModelMatch & log.isSerialMatch & log.isAssetMatch){
            return "Done"
        }
        else{
            return "Failed"
        }

    }

    function getFormattedDate(date){
        var year = date.getFullYear();

        var month = (1 + date.getMonth()).toString();
        month = month.length > 1 ? month : '0' + month;
      
        var day = date.getDate().toString();
        day = day.length > 1 ? day : '0' + day;
        
        return month + '/' + day + '/' + year;
    }

    const selectionRange = {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
      }

    return (
        <DashboardLayout title="Verification Report">
                {/* <div className="table-responsive">
                    
                </div> */}

                <div className="DropdownList">
                        <Dropdown className="d-inline">
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                Store
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                            {stores.map((store) => {
                                return ([
                                    <Dropdown.Item onClick={() => { addValue(store.name, "store") }}>{store.name}</Dropdown.Item>
                                ]);
                            })}
                            </Dropdown.Menu>
                        </Dropdown>


                        <Dropdown className="d-inline">
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                Assigned To
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {users.map((user) => {
                                    return ([
                                        <Dropdown.Item onClick={() => { addValue(user.firstName + " " + user.lastName, "user") }}>{user.firstName + " " + user.lastName}</Dropdown.Item>                                    ]);
                                })}
                            </Dropdown.Menu>
                        </Dropdown>

                        <Dropdown className="d-inline">
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                Status
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => { addValue("Done", "status") }}>Done</Dropdown.Item>
                                <Dropdown.Item onClick={() => { addValue("Failed", "status") }}>Failed</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>

                        <Dropdown className="d-inline">
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                Date Range
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <DateRangePicker
                                ranges={[selectionRange]}
                                onChange={handleSelect}
                        />
                            </Dropdown.Menu>
                        </Dropdown>

                        {getSearch(logs, setLogs, ["Status", "Store", "Lane", "Completed By", "Completion Date", "Reviewed By", "Review Date"],
                            originalLogs)}
                    
                    </div>


                    <div className="bottomPadding">
                        {filters.map(item => {
                            return ([
                                <AppButton
                                className="filterButton"
                                onClick={() => {
                                    removeValue(item);
                                }}
                                >
                                {item[1] == "date" ?
                                   moment(new Date(item[0].selection.startDate)).format("MM/DD/YYYY") + "-" + moment(new Date(item[0].selection.endDate)).format("DD/MM/YYYY") + " " + "✖" : item[0] + " " + "✖"
                                }
                                </AppButton>
                            ]);
                        })}
                    </div>

            <div className="Inspection">
            <table className="InspectionTable">
            
                <thead className="InspectionGridTitles">
                    <tr>
                        {tableColumns("Status", "string", logs, setLogs)}
                        {tableColumns("Store", "string", logs, setLogs)}
                        {tableColumns("Lane", "string", logs, setLogs)}
                        {tableColumns("Model #", "string", logs, setLogs)}
                        {tableColumns("Completed By", "string", logs, setLogs)}
                        {tableColumns("Completion Date", "string", logs, setLogs)}
                        {tableColumns("Reviewed By", "string", logs, setLogs)}
                        {tableColumns("Review Date", "string", logs, setLogs)}
                        {tableColumns("Notes", "string", logs, setLogs)}
                    </tr>
                </thead>
                
                
                <tbody className="InspectionGrid">
                { 
                    logs.map((log, i) => {
                        console.log(log);
                            return ([
                                <tr>
                                    
                                    <td><Link
                                        key={i}
                                        to={"/verification/deviceLogResult/" + log.id}
                                    >
                                    {status(log) + " "}
                                    <i className="fas fa-clipboard-check mr-2"></i>
                                    </Link></td>
                                    
                                    <td>{log.verification.lane?.store?.name}</td>
                                    <td>{log.verification.lane?.name}</td>
                                    <td>{log.verification.lane?.name}</td>
                                    <td>{log.user.firstName + " " + log.user.lastName}</td>
                                    <td>{moment(log.verifiedDate).format("LL")}</td>
                                    <td>{"Not Reviewed"}</td>
                                    <td>{"Not Reviewed"}</td>
                                    <td>{<i className="fas fa-comment-dots"></i>}</td>
                                </tr>
                            ]);
                    })
                }
                </tbody>
            </table>

            <span>{noDataMsg}</span>
            </div>  
         {/* <div><a className="exportCSV"> Export to CSV</a></div> */}
        </DashboardLayout>
    );
};

export default VerificationLogByTerminal;
