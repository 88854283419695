/* eslint-disable */

import React, { useState, useEffect, Fragment } from "react";
import DashboardLayout from "../../layouts/DashboardLayout";
import { Dropdown } from "react-bootstrap";
import {
    OptionsDropDown,
    frequencyText,
    getImage,
    tableColumns,
    getColumnSearch,
    dateDiff,
} from "../../services/HelperMethod";
import ApiService from "../../services/ApiService";
import moment from "moment";
import { Link } from "react-router-dom";
import Loading from "../../components/Loading";
import { toast } from "react-toastify";

const Maintenance = (props) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [maintenances, setMaintenances] = useState([]);
    const [maintenanceDonut, setMaintenanceDonut] = useState({} as any);
    const [maintenanceInfo, setMaintenanceInfo] = useState({} as any);
    const [originalMaintenances, setOriginalMaintenances] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [filters, setFilters] = useState([] as any);

    const apiService = ApiService();
    useEffect(() => {
        getMaintenances();
    }, []);

    function getMaintenances() {
        apiService
        .getDashboardMaintenance()
        .then((data: any) => {
            data = data.data;

            let info = getCount(data);

            setMaintenances(data);
            setMaintenanceInfo(info);

            setMaintenanceDonut({
                labels: ["Due today", "Due Soon", "Late", "Failed", "Done"],
                datasets: [
                    {
                        label: "# of Votes",
                        data: [info.today, info.soon, info.late, info.failed, info.done],
                        backgroundColor: ["#ff8931", "#ffb93c", "#ff485e", "#c43957", "#00be9c"],
                        borderColor: ["#ff8931", "#ffb93c", "#ff485e", "#c43957", "#00be9c"],
                        borderWidth: 1,
                    },
                ],
                onHover: (event, chartElement) => {
                    event.target.style.cursor = chartElement[0]
                        ? "pointer"
                        : "default";
                },
                legend: {
                    display: false
                },
            });

            setFilters([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])

            setIsLoading(false);
        })
        .catch((err) => {
            setIsLoading(false);
        });
    }

    function getCount(items) {
        let dueDatesInfo = {
            today: 0,
            soon: 0,
            done: 0,
            failed: 0,
            late: 0,
        };

        items.forEach((v) => {
            console.log(v);
            var dueDate = moment(v.dueDate).startOf('day');
            var today = moment().startOf('day');

            var momentDiff = moment.duration(Math.abs(today.diff(dueDate)));
            var difference = Math.floor(momentDiff.asDays())
            console.log(difference);
            if (difference == 0) {
                dueDatesInfo.today += 1
            } else if (today > dueDate){
                dueDatesInfo.late += 1
            } else if (difference < 30) {
                dueDatesInfo.soon += 1
            }  else if (v.isComplete) {
                dueDatesInfo.done += 1;
            }
            
        });

        return dueDatesInfo;
    }

    function filterStatus(item){
        var dueDate = moment(item.dueDate).startOf('day');
        var today = moment().startOf('day');

        var momentDiff = moment.duration(Math.abs(today.diff(dueDate)));
        var difference = Math.floor(momentDiff.asDays())

        let classNames = "status";
        var text;

            if (difference == 0 && filters[0] == 1) {
                return false;
            } else if (today > dueDate && filters[3] == 1){
                return false;
            } else if (difference < 15 && filters[1] == 1 && today < dueDate && difference != 0) {
                return false;
            }  else if (item.isComplete && filters[2] == 1) {
                return false;
            } else{
                return true;
            }
    }

    function status(date, item){
        var dueDate = moment(item.dueDate).startOf('day');
        var today = moment().startOf('day');

        var momentDiff = moment.duration(Math.abs(today.diff(dueDate)));
        var difference = Math.floor(momentDiff.asDays())

        let classNames = "status";
        var text;

            if (difference == 0) {
                classNames += " today";
                text = "Today"
            } else if (today > dueDate){
                classNames += " late";
                text = "Late";
            } else if (difference < 30) {
                classNames += " soon"
                text = "Soon"
            }  else if (item.isComplete) {
                classNames += " done"
                text = "Done"
            }
    
        return ([
            <span className={classNames}/>,
            <span>
                {text}
            </span>
        ]);
    }

    return (
        <DashboardLayout title="Asset Maintenance">
            <Loading isLoading={isLoading} />
            <div className="Inspection">
                <table className="InspectionTable">
                                        <thead className="InspectionGridTitles">
                                            <tr>
                                                {tableColumns("Status", "string", maintenances, setMaintenances)}
                                                {tableColumns("Store", "string", maintenances, setMaintenances)}
                                                {tableColumns("Serial Number", "string", maintenances, setMaintenances)}
                                                {tableColumns("Description", "string", maintenances, setMaintenances)}
                                                {tableColumns("Model Number", "string", maintenances, setMaintenances)}
                                                {tableColumns("Assigned To", "string", maintenances, setMaintenances)}
                                                {tableColumns("Due Date", "DateTime", maintenances, setMaintenances)}
                                                {tableColumns("Department", "string", maintenances, setMaintenances)}  
                                            </tr>
                                    
                                        </thead>

                                        <tbody className="InspectionGrid">
                                        { 
                                        maintenances.map((maintenance) => {
                                            if(filterStatus(maintenance)){
                                                return ([
                                                    <tr>
                                                            <th className="inspectionStatus">{status(moment(maintenance.dueDate).local(), maintenance)}</th>
                                                            <td><Link to={"/editStore/" + maintenance.asset?.department?.store?.id}>{maintenance?.asset?.department?.store?.name}</Link></td>
                                                            <td>{maintenance?.asset?.serialNumber}</td>
                                                            <td><Link to={"/editAsset/" + maintenance.asset?.id}>{maintenance?.asset.description}</Link></td>
                                                            <td>{maintenance?.asset?.modelNumber}</td>
                                                            <td><Link to={"/editUser/" + maintenance?.assignTo?.id}> {maintenance?.assignTo?.firstName}{" "} {maintenance?.assignTo?.lastName} </Link></td>
                                                            <td>{moment(maintenance.dueDate).local().format("MM/DD/YYYY")}</td>
                                                            <td>{maintenance?.asset?.department?.name}</td>
                                                    </tr>
                                                ]);
                                            }
                                        })
                                        }
                                        </tbody>
                </table>
                </div>
        </DashboardLayout>
    );
};

export default Maintenance;
