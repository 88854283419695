/* eslint-disable */

import React, { useState, useEffect, Fragment } from "react";
import { checkClaim, getImage } from "../../services/HelperMethod";
import ApiService from "../../services/ApiService";
import Loading from "../../components/Loading";
import TextBox from "../../components/TextBox";
import AppDropdown from "../../components/AppDropdown";
import DashboardLayout from "../../layouts/DashboardLayout";
import { toast } from "react-toastify";
import { Link, useParams } from "react-router-dom";
import FileButton from "../../components/FileButton";
import AppModal from "../../components/AppModal";
import Modal from "../../components/Modal";
import AddUser from "../Users/AddUser";
import ModalDelete from "react-modal";

const AddStore = (props) => {
    const { storeId } = useParams();

    const apiService = ApiService();
    const [isLoading, setIsLoading] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [title, setTitle] = useState("Add Store");

    //form
    const [storeImage, setStoreImage] = useState(null as any);
    const [storeName, setStoreName] = useState("");
    const [phone, setPhone] = useState("");

    const [address, setAddress] = useState("");

    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [zip, setZip] = useState("");

    const [website, setWebsite] = useState("");
    const [managerId, setManagerId] = useState(0);
    const [managerEmail, setManagerEmail] = useState("");
    const [managers, setManagers] = useState([] as any[]);

    //form validation
    const [storeNameError, setStoreNameError] = useState("");
    const [phoneError, setPhoneError] = useState("");
    const [addressError, setAddressError] = useState("");
    const [zipError, setZipError] = useState("");
    const [cityError, setCityError] = useState("");
    const [stateError, setStateError] = useState("");
    const [managerIdError, setManagerIdError] = useState("");
    const [managerEmailError, setManagerEmailError] = useState("");
    const [websiteError, setWebsiteError] = useState("");

    const [modalDeleteIsOpen, setModalDeleteIsOpen] = useState(false);

    useEffect(() => {
        apiService.getManagers().then((data: any) => {
            data = data.data;
            let managers = data.filter((d) => {
                return d.selectedRole == "Manager" || d.selectedRole == "Admin";
            });
            setManagers(managers);
            checkStoreEdit();
        });
    }, []);

    function getManagersFromModal() {
        apiService.getManagers().then((data: any) => {
            data = data.data;
            let managers = data.filter((d) => {
                return d.selectedRole == "Manager" || d.selectedRole == "Admin";
            });
            setManagers(managers);
        })
    }

    function checkStoreEdit() {
        if (storeId) {
            setTitle("Edit Store");
            setIsLoading(true);

            apiService
                .getStoreById(storeId)
                .then((data: any) => {
                    data = data.data;
                    if (data.errorType) {
                        toast.error(data.errorExplanation);
                        return;
                    }

                    setStoreName(data.name);
                    setPhone(data.phoneNumber);
                    setAddress(data.address);

                    setCity(data.city);
                    setState(data.state);
                    setZip(data.zip);

                    setWebsite(data.website);
                    setManagerId(data.managerId);
                    setManagerEmail(data.managerEmail);
                    setStoreImage(getImage(data.imageURL));

                    setIsLoading(false);
                })
                .catch((err) => {
                    toast.error("Error fetching data, try again!");
                });
        }
    }

    function registerStore(isCheckImage = true) {
        if (isCheckImage && !storeImage) {
            setModalIsOpen(true);
            return;
        }
        
        let data = {
            id: storeId,
            name: storeName,
            phoneNumber: phone,
            address,
            city,
            zip,
            state,
            website,
            managerId: managerId,
            managerEmail,
            storeImage64: storeImage,
            sourceURL: window.location.href,
        };

        setModalIsOpen(false);
        setIsLoading(true);
        resetErrors();
        if (storeId) {
            apiService
                .updateStore(data)
                .then((data: any) => {
                    data = data.data;
                    setModalIsOpen(false);
                    props.history.push("/stores");
                })
                .catch((error) => {
                    handleFormErrors(error?.response?.data?.errors);
                });
        } else {
            apiService
                .registerStore(data)
                .then((data: any) => {
                    data = data.data;
                    if (data.payPalURL) {
                        window.open(data.payPalURL, "_self");
                        return;
                    } else {
                        setModalIsOpen(false);
                        setIsLoading(false);
                    }

                    if (data.errorType) {
                        toast.error(data.errorExplanation);
                        return;
                    }
                    props.history.push("/stores");
                })
                .catch((error) => {
                    handleFormErrors(error?.response?.data?.errors);
                });
        }
    }

    function handleFormErrors(err) {
        if (err?.Name) {
            setStoreNameError(err.Name[0]);
        }
        if (err?.Address) {
            setAddressError(err.Address[0]);
        }

        if (err?.City) {
            setCityError(err.City[0]);
        }
        if (err?.Zip) {
            setZipError(err.Zip[0]);
        }
        if (err?.State) {
            setStateError(err.State[0]);
        }

        if (err?.ManagerId) {
            setManagerIdError(err.ManagerId[0]);
        }
        if (err?.PhoneNumber) {
            setPhoneError(err.PhoneNumber[0]);
        }
        if (err?.ManagerEmail) {
            setManagerEmailError(err.ManagerEmail[0]);
        }
        if (err?.Website) {
            setWebsiteError(err.Website[0]);
        }

        setModalIsOpen(false);
        setIsLoading(false);
        toast.error("Please fill all the required fields");
    }

    function resetErrors() {
        setStoreNameError("");
        setAddressError("");
        setManagerIdError("");
        setManagerEmailError("");
        setPhoneError("");
        setStateError("");
        setZipError("");
        setWebsiteError("");
        setCityError("");
    }

    function getSelectedManagerInfo(managerId) {
        if (!managerId) {
            return <Fragment />;
        }
        var manager = managers.find((m) => {
            return m.id == managerId;
        });

        return (
            <div>
                <div>
                    {/* <div>
            <div className="badge">Name:</div>
            <div className="badge">
              {manager.firstName} {manager.lastName}
            </div>
          </div> */}
                    <div>
                        <div className="badge">Company:</div>
                        <div className="badge">{manager?.company?.name}</div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <React.Fragment>
            <DashboardLayout title={title}>
                <Loading isLoading={isLoading} />
                <AppModal
                    isOpen={modalIsOpen}
                    onClose={() => {
                        setModalIsOpen(false);
                    }}
                    onClick={() => {
                        registerStore(false);
                    }}
                />
                <div className="card">
                    <div className="card-header">
                        <div className="row">
                            <div className="col-sm-4" />
                            <div className="col-sm-4">
                                <div className="pro-pic">
                                    <FileButton
                                        onChange={(file) => {
                                            if (file) {
                                                setStoreImage(file);
                                            }
                                        }}
                                        image={storeImage}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-4" />
                        </div>
                    </div>
                    <div className="card-body">
                        <form className="row">
                            <div className="col-sm-12 col-md-6">
                                <div className="form-group mt-1">
                                    <label
                                        className="ml-3"
                                        htmlFor="store-name"
                                    >
                                        Store Name *
                                    </label>
                                    <TextBox
                                        autoFocus={true}
                                        type="text"
                                        value={storeName}
                                        error={storeNameError}
                                        className="form-control sign-in-inputs"
                                        onChange={(val) => setStoreName(val)}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <div className="form-group mt-1">
                                    <label
                                        className="ml-3"
                                        htmlFor="store-phone"
                                    >
                                        Phone *
                                    </label>
                                    <TextBox
                                        mask="999-999-9999"
                                        type="text"
                                        value={phone}
                                        error={phoneError}
                                        className="form-control sign-in-inputs"
                                        onChange={(val) => setPhone(val)}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <div className="form-group mt-1">
                                    <label
                                        className="ml-3"
                                        htmlFor="store-address"
                                    >
                                        Address *
                                    </label>
                                    <TextBox
                                        type="text"
                                        value={address}
                                        error={addressError}
                                        className="form-control sign-in-inputs"
                                        onChange={(val) => setAddress(val)}
                                    />
                                </div>
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <div className="form-group mt-1">
                                    <label
                                        className="ml-3"
                                        htmlFor="store-address"
                                    >
                                        City *
                                    </label>
                                    <TextBox
                                        type="text"
                                        value={city}
                                        error={cityError}
                                        className="form-control sign-in-inputs"
                                        onChange={(val) => setCity(val)}
                                    />
                                </div>
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <div className="form-group mt-1">
                                    <label
                                        className="ml-3"
                                        htmlFor="store-address"
                                    >
                                        State *
                                    </label>
                                    <TextBox
                                        type="text"
                                        value={state}
                                        error={stateError}
                                        className="form-control sign-in-inputs"
                                        onChange={(val) => setState(val)}
                                    />
                                </div>
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <div className="form-group mt-1">
                                    <label
                                        className="ml-3"
                                        htmlFor="store-address"
                                    >
                                        Zip *
                                    </label>
                                    <TextBox
                                        type="text"
                                        value={zip}
                                        error={zipError}
                                        className="form-control sign-in-inputs"
                                        onChange={(val) => setZip(val)}
                                    />
                                </div>
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <div className="form-group mt-1">
                                    <label
                                        className="ml-3"
                                        htmlFor="store-website"
                                    >
                                        Website
                                    </label>
                                    <TextBox
                                        type="text"
                                        value={website}
                                        error={websiteError}
                                        className="form-control sign-in-inputs"
                                        onChange={(val) => setWebsite(val)}
                                    />
                                </div>
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <div className="form-group mt-1">
                                    <label className="ml-3" htmlFor="manager">
                                        Manager *
                                    </label>

                                    <AppDropdown
                                        value={managerId.toString()}
                                        onChange={(val) => {
                                            setManagerId(val);
                                        }}
                                        error={managerIdError}
                                    >
                                        <option value="0">
                                            Select a manager
                                        </option>
                                        {managers.map((manager) => {
                                            return (
                                                <option
                                                    key={manager.id}
                                                    value={manager.id}
                                                >
                                                    {manager.firstName}{" "}
                                                    {manager.lastName} (
                                                    {manager.selectedRole})
                                                </option>
                                            );
                                        })}
                                    </AppDropdown>

                                    {/* <Link
                                        className="add-new-manager-btn"
                                        to="/users/addUser/Manager/true"
                                    >
                                        Add new manager
                                    </Link> */}
                                    <Modal buttonText={"Add New Manager"}
                                        body={<AddUser isModal={true} />}
                                        onClose={getManagersFromModal}
                                    />
                                    {getSelectedManagerInfo(managerId)}
                                </div>
                            </div>
                            {/* <div className="col-sm-12 col-md-6">
                                <div className="form-group mt-1">
                                    <label
                                        className="ml-3"
                                        htmlFor="manager-email"
                                    >
                                        Manager's Email
                                    </label>
                                    <TextBox
                                        type="text"
                                        value={managerEmail}
                                        error={managerEmailError}
                                        className="form-control sign-in-inputs"
                                        onChange={(val) => setManagerEmail(val)}
                                    />
                                </div>
                            </div> */}

                            <div className="col-12">
                                <div className="row justify-content-end">
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col">
                                                {checkClaim("admin") && storeId
                                                    ? (<div className="form-group mt-1">
                                                        <button className="btn btn-secondary btn-block btn-pcid-cancel mt-3"
                                                            onClick={() => { setModalDeleteIsOpen(true) }}>
                                                            Remove Store
                                                        </button>
                                                    </div>)
                                                    : (<div></div>)
                                                }
                                            </div>
                                            <div className="col">
                                                <div className="form-group mt-1">
                                                    <input
                                                        className="btn btn-secondary btn-block btn-pcid-cancel mt-3"
                                                        type="button"
                                                        value="Cancel"
                                                        onClick={() => {
                                                            props.history.push(
                                                                "/stores"
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="form-group mt-1">
                                                    <button
                                                        type="submit"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            registerStore();
                                                        }}
                                                        className="btn btn-success btn-block btn-pcid mt-3"
                                                    >
                                                        Save
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                {confirmDelete()}
            </DashboardLayout>
        </React.Fragment>
    );

    function deleteStore(deleteStore = false) {
        if (storeId && deleteStore) {

            setIsLoading(true);

            var data = {
                storeId: storeId
            }

            apiService.deleteStore(data).then((data: any) => {
                data = data.data;

                setIsLoading(false);

                props.history.push("/stores");
            }).catch((error) => {
                alert("Unable to delete selected.")
                setIsLoading(false);
            });
        } else {
            alert("Unable to delete selected.")
            setIsLoading(false);
        }
    }

    function confirmDelete() {
        return (
            <ModalDelete isOpen={modalDeleteIsOpen} ariaHideApp={false}
                onRequestClose={() => { setModalDeleteIsOpen(false); }} className="modal">
                <div className="row">
                    <div className="card text-center" style={{ width: "350px", height: "180px" }}>
                        <div className="card-body align-items-center d-flex justify-content-center">
                            Are you sure you want to delete this?
                        </div>
                        <div className="card-footer text-muted p-4">
                            <input className="btn btn-secondary btn-pcid-cancel" type="button"
                                value="Continue" onClick={() => { deleteStore(true); }}
                            />
                            <input className="btn btn-success btn-pcid ml-3" type="button"
                                value="Go Back"
                                onClick={() => { setModalDeleteIsOpen(false); }}
                            />
                        </div>
                    </div>
                </div>
            </ModalDelete>
        );
    }
};

export default AddStore;
