import React from "react";
import { useHistory } from "react-router-dom";

function FinalPage() {
    const history = useHistory();

    function redirectToDashboard() {
        history.push("/dashboard")
    }

    function redirectToStore(){
        history.push("/setup-store")
    }

    return (
        <React.Fragment>
            <div className="full-layout">
                <div className="container">
                    <div style={{ width: "100%" }} className="white-box">
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                            <div style={{ margin: "1.5rem" }}><i style={{ color: "#55af9d" }} className="far fa-check-circle fa-9x"></i></div>
                            <div className="text-resize" style={{ fontWeight: "bold", fontSize: "1.8rem", margin: "1.5rem" }}>Congratulations!!</div>
                            <div style={{ textAlign: "center", margin: "1.5rem", fontSize: "1.3rem" }}>You've finished setting up your store! Would you like to add another store or continue to the site?</div>
                            <div style={{ flexDirection: "row", display: "flex", margin: "1.5rem" }}>
                                <div className="margin-resize" style={{ marginRight: "1rem" }}><button className="btn add-store-btn" onClick={(e) => { e.preventDefault(); redirectToStore() }}>Add a store</button></div>
                                <div><button className="btn" onClick={(e) => { e.preventDefault(); redirectToDashboard(); }}>Continue</button></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default FinalPage;
