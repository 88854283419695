/* eslint-disable */

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ApiService from "../../services/ApiService";
import { Link } from "react-router-dom";
import DashboardLayout from "../../layouts/DashboardLayout";
import moment from "moment";

const VerificationLog = (props) => {
    const { deviceId } = useParams();
    const apiService = ApiService();
    const [logs, setLogs] = useState([] as any[]);
    const [noDataMsg, setNoDataMsg] = useState("");

    useEffect(() => {
        apiService.getDeviceLog(deviceId).then((data: any) => {
            data = data.data;
            setLogs(data);
            
            if (data.length == 0) {
                setNoDataMsg("No verification data yet");
            }
        });
    }, []);

    return (
        <DashboardLayout title="Verification Log">
            <span>{noDataMsg}</span>
            {logs.map((log, i) => {
                return (
                    <Link
                        key={i}
                        to={"/verification/deviceLogResult/" + log.id}
                    >
                        <div
                            className="row mt-3 colored-row p-3 pl-4"
                            key={log.id}
                        >
                            <div className="mr-3">
                                <i className="fas fa-clipboard-check mr-2"></i>
                                View
                            </div>
                            {moment(log.verifiedDate).format("LLLL")}
                        </div>
                    </Link>
                );
            })}
        </DashboardLayout>
    );
};

export default VerificationLog;
