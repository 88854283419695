/* eslint-disable */

import React from "react";
import InputMask from "react-input-mask";
import CurrencyFormat from "react-currency-format";

interface TextBoxProps {
  type: string;
  className?: string;
  onChange?: CallableFunction;
  value?: string;
  error?: string;
  mask?: string;
  autoFocus?: boolean;
  min?: number;
  cols?: number;
  rows?: number;
  placeholder?: string;
}

const TextBox = (props: TextBoxProps) => {
  function handleClick(e) {
    if (props.onChange) {
      props.onChange(e.target.value);
    }
  }

  var opts = {};
  opts["placeholder"] = props.placeholder;
  if (props.autoFocus) {
    opts["autoFocus"] = true;
  }

  function render() {
    var html = (
      <input
        min={props.min}
        {...opts}
        onChange={(e) => {
          handleClick(e);
        }}
        value={props.value ? props.value : ""}
        className={props.className}
        type={props.type}
      />
    );

    if (props.type == "textarea") {
      return (
        <textarea
          className={props.className}
          cols={props.cols}
          rows={props.rows}
          onChange={(e) => {
            handleClick(e);
          }}
          value={props.value ? props.value : ""}
        ></textarea>
      );
    }

    if (props.type == "currency") {
      return (
        <CurrencyFormat
          // onClick={(e: any) => {
          //   e.target.select();
          // }}
          onFocus={(e: any) => {
            if(e.target.value == 0){
              
            }
            e.target.select();
          }}
          className={props.className}
          value={props.value ? props.value : ""}
          thousandSeparator={true}
          autoComplete="none"
          prefix="$"
          onValueChange={(e) => {
            let val = e.value;
            const x = {
              target: {
                value: val,
              },
            };
            handleClick(x);
          }}
        />
      );
    }

    if (props.mask) {
      html = (
        <InputMask
          maskChar=""
          mask={props.mask}
          onChange={(e) => {
            handleClick(e);
          }}
          value={props.value ? props.value : ""}
          className={props.className}
          type={props.type}
          autoComplete="none"
        />
      );
    }

    return html;
  }
  return (
    <div className="textBox-wrapper">
      {render()}

      {getError()}
    </div>
  );

  function getError() {
    if (props.error) {
      return <span className="badge text-danger">{props.error}</span>;
    }
  }
};

export default TextBox;
