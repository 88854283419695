/* eslint-disable */

import React, { useState, useEffect } from "react";
import Modal from "react-modal";
// import DatePicker from "react-datepicker";
import TextBox from "../../components/TextBox";
import {
  toBase64,
  getImage,
  OptionsDropDown,
  handleErrors,
  checkClaim,
} from "../../services/HelperMethod";
import Loading from "../../components/Loading";
import ApiService from "../../services/ApiService";
import { toast } from "react-toastify";
import DashboardLayout from "../../layouts/DashboardLayout";
import AppDropdown from "../../components/AppDropdown";
import BestCalendar from "../../components/BestCalendar";
import { useParams, Link } from "react-router-dom";
import moment from "moment";
import FileButton from "../../components/FileButton";
import { Dropdown } from "react-bootstrap";
import AddVerification from "../Verification/AddVerification";
import VerificationUI from "../../components/VerificationUI";
import VerificationInfo from "../../components/VerificationInfo";
import { Fragment } from "react";
import { ConsoleSqlOutlined } from "@ant-design/icons";

const AddPICEquipment = (props) => {
  const apiService = ApiService();

  const { equipmentId, selectedStoreId, selectedLaneId } = useParams() as any;

  const [isLoading, setIsLoading] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalDeleteIsOpen, setModalDeleteIsOpen] = useState(false);
  const [isQuestionModalOpen, setIsQuestionModalOpen] = useState(false);
  const [title, setTitle] = useState("Add Terminals");

  const [stores, setStores] = useState([] as any[]);
  const [storeLanes, setStoreLanes] = useState([] as any[]);
  const [managers, setManagers] = useState([] as any[]);
  const [modelNumbers, setModelNumbers] = useState([] as any[]);
  const [questions, setQuestions] = useState([] as any[]);
  const [editQuestionId, setEditQuestionId] = useState(0);

  const [enableTopImage, setEnableTopImage] = useState(false);
  const [enableBackImage, setEnableBottomImage] = useState(false);
  const [enableRightImage, setEnableRightImage] = useState(false);
  const [enableLeftImage, setEnableLeftImage] = useState(false);

  const [isShowAdditionalImages, setIsShowAdditionalImages] = useState(false);

  //questions
  const [question, setQuestion] = useState("");
  const [questionError, setQuestionsError] = useState("");

  // form
  const [pinPad, setPinPad] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [laneId, setLaneId] = useState(0 as any);
  const [assetTag, setAssetTag] = useState("");
  const [modelNumber, setModelNumber] = useState("");
  const [storeId, setStoreId] = useState("");
  const [placedUser, setPlacedUser] = useState(0);
  const [imageFront, setImageFront] = useState("");
  const [imageBack, setImageBack] = useState("");

  const [imageTop, setImageTop] = useState("");
  const [imageBottom, setImageBottom] = useState("");
  const [imageLeft, setImageLeft] = useState("");
  const [imageRight, setImageRight] = useState("");
  const [imageMain, setImageMain] = useState("");

  // form validation
  const [datePlaced, setDatePlaced] = useState(new Date());
  const [modalError, setModalError] = useState("");
  const [pinPadError, setPinPadError] = useState("");
  const [serialError, setSerialError] = useState("");
  const [assetTagError, setAssetTagError] = useState("");
  const [placedByError, setPlacedByError] = useState("");
  const [laneIdError, setLaneIdError] = useState("");

  // verification form
  const [verificationUser, setVerificationUser] = useState("");
  const [verificationFrequency, setVerificationFrequency] = useState("");
  const [verificationDueDate, setVerificationDueDate] = useState(new Date());
  // const [verificationEndDate, setVerificationEndDate] = useState(new Date());
  const [receiveNotifications, setReceiveNotifications] = useState(true);
  const [isChanged, setIsChanged] = useState(false);

  const [note, setNote] = useState("");
  const [noteError, setNoteError] = useState("");

  useEffect(() => {
    apiService
      .getStores()
      .then((data: any) => {
        data = data.data;
        setStores(data);

        if (data) {
          data.forEach((store) => {
            if (store.id == selectedStoreId) {
              setStoreId(selectedStoreId!);
              setStoreLanes(store.lanes);
              setLaneId(selectedLaneId);
            }
          });
        }
      })
      .catch((err) => {
        handleErrors(err);
      });

    apiService.getManagers().then((data: any) => {
      data = data.data;
      if (data) {
        setManagers(data);
      }
    });

    getQuestions();

    apiService.getDeviceModelList().then((data: any) => {
      data = data.data;
      let items = [] as any[];
      if (data) {
        data.forEach((item) => {
          items.push({ value: item, label: item });
        });
      }
      setModelNumbers(items);
    });

    checkEquipmentEdit();
  }, []);

  function getQuestions(eqId = 0) {
    if (!eqId) {
      eqId = equipmentId;
    }
    apiService.getLaneQuestions(eqId).then((data: any) => {
      data = data.data;

      setQuestions(data);
      setIsLoading(false);
    });
  }

  function checkEquipmentEdit() {
    if (equipmentId) {
      setTitle("Edit Terminals");
      setIsLoading(true);
      apiService.getDeviceById(parseInt(equipmentId)).then((data: any) => {
        data = data.data;

        setPinPad(data.pinPad);
        setSerialNumber(data.serial);
        setDatePlaced(moment(data.datePlaced).toDate());
        setAssetTag(data.assetTag);
        setModelNumber(data.model);
        setPlacedUser(data.placedById);
        setNote(data.notes);

        setStoreId(data.lane?.store?.id);

        let lanes = data.lane?.store?.lanes;
        lanes.push(data.lane);
        setStoreLanes(lanes);
        setLaneId(data.laneId);

        setImageFront(getImage(data.frontImageURL));
        setImageBack(getImage(data.backImageURL));

        if (data.leftImageURL) {
          setEnableLeftImage(true);
        }
        if (data.rightImageURL) {
          setEnableRightImage(true);
        }
        if (data.bottomImageURL) {
          setEnableBottomImage(true);
        }
        if (data.topImageURL) {
          setEnableTopImage(true);
        }
        setImageLeft(getImage(data.leftImageURL));
        setImageRight(getImage(data.rightImageURL));
        setImageFront(getImage(data.frontImageURL));
        setImageBottom(getImage(data.bottomImageURL));
        setImageTop(getImage(data.topImageURL));

        setIsLoading(false);
      });
    }
  }

  function changeStore(storeId) {
    setStoreId(storeId);
    setLaneId(0);

    stores.forEach((store) => {
      if (store.id == storeId) {
        setStoreLanes(store.lanes);
        return;
      }
    });
  }

  async function createDevice(isValidateImage = true) {
    if (imageFront == "") {
      toast.error("Front image is required");
      return;
    }

    if (!imageMain) {
      if (isValidateImage) {
        setModalIsOpen(true);
        return;
      }
    }

    let data = {
      equipmentId: equipmentId,
      pinPad: pinPad,
      serial: serialNumber,
      assetTag: assetTag,
      model: modelNumber,
      laneId: laneId,
      PlacedBy: placedUser ? placedUser : null,
      datePlaced: datePlaced,

      imageFront,
      imageBack,

      imageMain,
      imageTop,
      imageLeft,
      imageBottom,
      imageRight,

      notes: note,
    };

    setModalError("");
    setPinPadError("");
    setSerialError("");
    setAssetTagError("");
    setPlacedByError("");
    setLaneIdError("");

    setIsLoading(true);

    apiService
      .createDevice(data)
      .then(async (data: any) => {
        data = data.data;

        setModalIsOpen(false);
        setIsLoading(false);

        // createVerification(data.id);

        if (isChanged) {
          await createVerification(laneId);
        }

        if (equipmentId) {
          props.history.push("/pciequipments");
        } else {
          toast("Setup success! You can set Questions for the equipment now");

          var buttonDom = document.getElementById("question-button");
          buttonDom?.scrollIntoView();
          props.history.push("/editEquipment/" + data.id);
          getQuestions(data.id);
        }
      })
      .catch((error) => {
        handleFormErrors(error?.response?.data?.errors);
      });
  }

  async function createVerification(laneId) {
    setIsLoading(true);
    //if (verificationUser && verificationFrequency && laneId && verificationDueDate && verificationEndDate) {
    let data = {
      assignToUserId: verificationUser,
      LaneId: laneId,
      DueDate: verificationDueDate,
      // EndDate: verificationEndDate,
      ReceiveNotifications: receiveNotifications,
      Frequency: verificationFrequency,
    };

    try {
      await apiService.createVerification(data);
    } catch (error) {
      handleErrors(error);
    }
    //}
    setIsLoading(false);
  }

  function handleFormErrors(err) {
    setModalIsOpen(false);
    setIsLoading(false);
    if (err) {
      toast.error("Please check all the required fields");
      setModalError(err.Model?.[0]);
      setPinPadError(err.PinPad?.[0]);
      setSerialError(err.Serial?.[0]);
      setAssetTagError(err.AssetTag?.[0]);
      setPlacedByError(err.PlacedBy?.[0]);
      setLaneIdError(err.LaneId?.[0]);
    } else {
      toast.error("Error. Try Again");
    }
  }

  function changeImage(data, type) {
    if (data) {
      if (type == "front *") {
        setImageFront(data);
      } else if (type == "back") {
        setImageBack(data);
      } else if (type == "Top") {
        setImageTop(data);
      } else if (type == "Bottom") {
        setImageBottom(data);
      } else if (type == "Upper Left *") {
        setImageLeft(data);
      } else if (type == "Lower Right *") {
        setImageRight(data);
      } else if (type == "main") {
        setImageMain(data);
      }
    }
  }

  function handleValidation(err) {
    if (err) {
      setQuestionsError(err.Question?.[0]);
    }
  }

  function addQuestion() {
    const data = {
      questionId: editQuestionId,
      question: question,
      equipmentId,
    };

    setIsLoading(true);

    apiService
      .addQuestion(data)
      .then((data) => {
        data = data.data;
        getQuestions();
        setIsQuestionModalOpen(false);

        // props.history.push("/editVerification/" + laneId);
      })
      .catch((error: any) => {
        handleValidation(error?.response?.data?.errors);
      });
  }

  function questionTitle() {
    if (editQuestionId) {
      return "Edit question";
    }
    return "Add new question";
  }

  function addQuestionModal() {
    return (
      <Modal
        isOpen={isQuestionModalOpen}
        ariaHideApp={false}
        onRequestClose={() => {
          setIsQuestionModalOpen(false);
        }}
        className="modal"
      >
        <div className="card add-question-card pt-3">
          <h2 className="card-title">{questionTitle()}</h2>
          <div className="card-body">
            <div className="form-group mt-1">
              <div className="row align-items-center">
                <div className="col-auto">
                  <label className="ml-3 mb-0" htmlFor="lane-name">
                    Question *
                  </label>
                </div>
                <div className="col">
                  <TextBox
                    type="text"
                    className="form-control sign-in-inputs"
                    onChange={(val) => setQuestion(val)}
                    value={question}
                    error={questionError}
                  />
                </div>
              </div>
            </div>
            <div className="form-group row justify-content-end">
              <div className="col-auto">
                <button
                  className="btn btn-secondary btn-block btn-pcid-cancel mt-3"
                  onClick={() => {
                    setEditQuestionId(0);
                    // props.history.push("/editVerification/" + laneId);
                    setIsQuestionModalOpen(false);
                  }}
                >
                  Cancel
                </button>
              </div>

              <div className="col-auto">
                <button
                  className="btn btn-success btn-block btn-pcid mt-3"
                  onClick={() => {
                    addQuestion();
                  }}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  function addQuestionButtonUI() {
    if (!equipmentId) {
      return <></>;
    }
    return (
      <div>
        <button
          id="question-button"
          className="btn btn-success btn-pcid mt-3 mb-2"
          onClick={() => {
            setEditQuestionId(0);
            setIsQuestionModalOpen(true);
            setQuestion("");
            // "/verification/addDeviceQuestions/" + equipmentId
          }}
        >
          Add New Questions
        </button>
      </div>
    );
  }

  function addQuestionUI() {
    return (
      <>
        {addQuestionButtonUI()}
        {/* <p className="questions-text">Questions</p> */}

        <div>
          {questions.map((question: any) => {
            return (
              <div key={question.id} className="mt-4 mb-4 border-black">
                <div className="row">
                  <div className="col-auto">
                    <OptionsDropDown>
                      <Dropdown.Item
                        onClick={() => {
                          setEditQuestionId(question.id);
                          setIsQuestionModalOpen(true);
                          setQuestion(question.question);
                        }}
                      >
                        Edit Question
                      </Dropdown.Item>
                    </OptionsDropDown>
                  </div>
                  <div className="col">{question.question}</div>
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  }

  return (
    <DashboardLayout title={title}>
      <React.Fragment>
        <Loading isLoading={isLoading} />
        <div className="card">
          <div className="card-header">
            <div className="row justify-content-center">
              <div
                className="col-auto"
                style={{ display: "flex", flexDirection: "row" }}
              >
                {getFileButton("Main", imageMain)}
                <div>
                  <VerificationInfo
                    laneId={laneId}
                    onChange={(data) => {
                      setVerificationUser(data.user);
                      setVerificationFrequency(data.frequency);
                      setVerificationDueDate(data.dueDate);
                      // setVerificationEndDate(data.endDate);
                      setIsChanged(data.isChanged);
                      setReceiveNotifications(data.receiveNotifications);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <div className="form-group mt-1">
                  <label className="ml-3" htmlFor="store">
                    Store *
                  </label>

                  <AppDropdown
                    value={storeId?.toString()}
                    onChange={(val) => {
                      changeStore(val);
                    }}
                    error=""
                  >
                    <option value={0}>Select store</option>
                    {stores.map((store) => {
                      return (
                        <option key={store.id} value={store.id}>
                          {store.name}
                        </option>
                      );
                    })}
                  </AppDropdown>
                </div>
              </div>
              <div className="col-sm-12 col-md-6">
                <div className="form-group mt-1">
                  <label className="ml-3" htmlFor="store2">
                    Lane *
                  </label>

                  <AppDropdown
                    value={laneId.toString()}
                    onChange={(val) => {
                      setLaneId(val);
                    }}
                    error={laneIdError}
                  >
                    <option value="0">Select a store lane</option>
                    {storeLanes.map((lane) => {
                      return (
                        <option key={lane.id} value={lane.id}>
                          {lane.number} - {lane.name}
                        </option>
                      );
                    })}
                  </AppDropdown>
                </div>
              </div>
              <div className="col-sm-12 col-md-6">
                <div className="form-group mt-1">
                  <label className="ml-3" htmlFor="lane-name">
                    Model Number *
                  </label>

                  <AppDropdown
                    items={modelNumbers}
                    onChange={(val) => {
                      setModelNumber(val);
                    }}
                    error={modalError}
                    value={modelNumber}
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-6">
                <div className="form-group mt-1">
                  <label className="ml-3" htmlFor="serial-number">
                    Serial Number
                  </label>
                  <TextBox
                    type="text"
                    onChange={(val) => {
                      setSerialNumber(val);
                    }}
                    min={0}
                    className="form-control sign-in-inputs"
                    error={serialError}
                    value={serialNumber}
                  />
                </div>
              </div>

              <div className="col-sm-12 col-md-6">
                <div className="form-group mt-1">
                  <label className="ml-3" htmlFor="lane-name">
                    PCeyeD Barcode *
                  </label>
                  <TextBox
                    min={0}
                    type="text"
                    onChange={(val) => {
                      setAssetTag(val);
                    }}
                    className="form-control sign-in-inputs"
                    error={assetTagError}
                    value={assetTag}
                  />
                </div>
              </div>

              <div className="col-sm-12 col-md-6">
                <div className="form-group mt-1">
                  <label className="ml-3" htmlFor="lane-name">
                    Date Placed in Service
                  </label>

                  <BestCalendar
                    selected={datePlaced}
                    onChange={(e) => {
                      setDatePlaced(e);
                    }}
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-6">
                <div className="form-group mt-1">
                  <label className="ml-3" htmlFor="placed-by">
                    Placed into service By
                  </label>
                  <AppDropdown
                    value={placedUser?.toString()}
                    onChange={(val) => {
                      setPlacedUser(val);
                    }}
                    error={placedByError}
                  >
                    <option value="0">Select a User..</option>
                    {managers.map((user) => {
                      return (
                        <option key={user.id} value={user.id}>
                          {user.firstName} {user.lastName}
                        </option>
                      );
                    })}
                  </AppDropdown>
                </div>
              </div>
              <div className="col-12 add-verification-section">
                <VerificationUI
                  laneId={laneId}
                  onChange={(data) => {
                    setVerificationUser(data.user);
                    setVerificationFrequency(data.frequency);
                    setVerificationDueDate(data.dueDate);
                    // setVerificationEndDate(data.endDate);
                    setIsChanged(data.isChanged);
                    setReceiveNotifications(data.receiveNotifications);
                  }}
                />
              </div>
              <div style={{ width: "100%", textAlign: "right" }}>
                <div
                  onClick={() => {
                    setIsShowAdditionalImages(!isShowAdditionalImages);
                  }}
                >
                  {/* {isShowAdditionalImages ? (
                                        <button className="toggle-additional-images">
                                            Hide Additional Images
                                            <i className="fas fa-minus"></i>
                                        </button>
                                    ) : (
                                        <button className="toggle-additional-images">
                                            Show Additional Images
                                            <i className="fas fa-plus"></i>
                                        </button>
                                    )} */}
                </div>
              </div>
              {true ? (
                <div className="col-12 mb-5 mt-2">
                  <div className="row bg-light p-2 justify-content-center">
                    <div className="col-md-3">
					  <div className="file-button-wrap">
							<span className="badge badge-info text-capitalize">Front *</span>
							<FileButton
							image={imageFront}
							onChange={(data) => {
								setImageFront(data);
							}}
							/>
						</div>
                    </div>
                    <div className="col-md-3">
					<div className="file-button-wrap">
							<span className="badge badge-info text-capitalize">Upper Left *</span>
							<FileButton
							image={imageLeft}
							onChange={(data) => {
								setImageLeft(data);
							}}
							/>
						</div>
                    </div>
                    <div className="col-md-3">
					<div className="file-button-wrap">
							<span className="badge badge-info text-capitalize">Lower Right *</span>
							<FileButton
							image={imageRight}
							onChange={(data) => {
								setImageRight(data);
							}}
							/>
						</div>
                    </div>
                    <div className="col-md-3">
					<div className="file-button-wrap">
							<span className="badge badge-info text-capitalize">Back</span>
							<FileButton
							image={imageBack}
							onChange={(data) => {
								setImageBack(data);
							}}
							/>
						</div>
                    </div>
                    {/* <div className="col-md-4">
                                            {getFileButton("Top", imageTop)}
                                        </div> */}
                    {/* <div className="col-md-4">
                                            {getFileButton("Bottom", imageBottom)}
                                        </div> */}
                  </div>
                </div>
              ) : (
                <Fragment />
              )}
            </div>

            <div className="col-sm-12 col-md-12 mt-5 mb-5">
              <p className="ml-3" style={{ fontSize: "20px", fontWeight: 300 }}>
                Notes
              </p>

              <TextBox
                type="textarea"
                className="form-control sign-in-inputs"
                cols={30}
                rows={5}
                value={note}
                onChange={(val) => {
                  setNote(val);
                }}
                error={noteError}
              />
            </div>

            <div>{addQuestionUI()}</div>
            <div className="row justify-content-end">
              {checkClaim("admin") && equipmentId ? (
                <div className="col-md-3">
                  <div className="form-group mt-1">
                    <button
                      className="btn btn-secondary btn-block btn-pcid-cancel mt-3"
                      onClick={() => {
                        setModalDeleteIsOpen(true);
                      }}
                    >
                      Remove Equipment
                    </button>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
              <div className="col-md-3">
                <div className="form-group mt-1">
                  <button
                    className="btn btn-secondary btn-block btn-pcid-cancel mt-3"
                    onClick={() => {
                      props.history.goBack("/pciequipments");
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group mt-1">
                  <button
                    className="btn btn-success btn-block btn-pcid mt-3"
                    onClick={() => {
                      createDevice();
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {addQuestionModal()}
        {laneVerificationUI()}
        {confirmModal()}
        {confirmDelete()}
      </React.Fragment>
    </DashboardLayout>
  );

  function laneVerificationUI() {
    return <div></div>;
  }

  function getFileButton(txt, img) {
    return (
      <div className="file-button-wrap">
        <span className="badge badge-info text-capitalize">{txt}</span>
        <FileButton
          image={img}
          onChange={(data) => {
            changeImage(data, txt.toLowerCase());
          }}
        />
      </div>
    );
  }

  function deleteEquipment(deleteEquipment = false) {
    if (equipmentId && deleteEquipment) {
      setIsLoading(true);

      var data = {
        equipmentId: equipmentId,
      };

      apiService
        .deleteEquipment(data)
        .then((data: any) => {
          data = data.data;

          setIsLoading(false);

          props.history.push("/pciequipments");
        })
        .catch((error) => {
          alert("Unable to delete selected.");
          setIsLoading(false);
        });
    } else {
      alert("Unable to delete selected.");
      setIsLoading(false);
    }
  }

  function confirmModal() {
    return (
      <Modal
        isOpen={modalIsOpen}
        ariaHideApp={false}
        onRequestClose={() => {
          setModalIsOpen(false);
        }}
        className="modal"
      >
        <div className="row">
          <div
            className="card text-center"
            style={{ width: "350px", height: "180px" }}
          >
            <div className="card-body align-items-center d-flex justify-content-center">
              You haven't set the main photo!
            </div>
            <div className="card-footer text-muted p-4">
              <input
                className="btn btn-secondary btn-pcid-cancel"
                type="button"
                value="Continue"
                onClick={() => {
                  createDevice(false);
                }}
              />
              <input
                className="btn btn-success btn-pcid ml-3"
                type="button"
                value="Go Back"
                onClick={() => {
                  setModalIsOpen(false);
                }}
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  function confirmDelete() {
    return (
      <Modal
        isOpen={modalDeleteIsOpen}
        ariaHideApp={false}
        onRequestClose={() => {
          setModalDeleteIsOpen(false);
        }}
        className="modal"
      >
        <div className="row">
          <div
            className="card text-center"
            style={{ width: "350px", height: "180px" }}
          >
            <div className="card-body align-items-center d-flex justify-content-center">
              Are you sure you want to delete this?
            </div>
            <div className="card-footer text-muted p-4">
              <input
                className="btn btn-secondary btn-pcid-cancel"
                type="button"
                value="Continue"
                onClick={() => {
                  deleteEquipment(true);
                }}
              />
              <input
                className="btn btn-success btn-pcid ml-3"
                type="button"
                value="Go Back"
                onClick={() => {
                  setModalDeleteIsOpen(false);
                }}
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  }
};

export default AddPICEquipment;
