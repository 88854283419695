import React, { useState, useEffect, useRef, useContext } from "react";
import DashboardLayout from "../../layouts/DashboardLayout";
import { Link } from "react-router-dom";
import ApiService from "../../services/ApiService";
import Loading from "../../components/Loading";
import Guid from "../../components/Guid";
import { toast } from "react-toastify";
import {
  getImage,
  checkClaim,
  OptionsDropDown,
  sortColumns,
  tableColumns,
  getSearch,
  getGuidElement,
} from "../../services/HelperMethod";
import StoreCalendar from "../../components/StoreCalendar";
import { Dropdown } from "react-bootstrap";
import { Fragment } from "react";
import moment from "moment";
import { ShowGuidContext } from "../../store/StoreProvider";
import Verification from "../Verification/Verification";

const Stores = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [stores, setStores] = useState([] as any[]);
  const [lanes, setLanes] = useState([] as any[]);
  const [showPaymentNotification, setShowPaymentNotification] = useState(false);
  const [originalStores, setOriginalStores] = useState([] as any[]);
  const apiService = ApiService();
  const [showGuid, setShowGuid] = useContext(ShowGuidContext);
  const [users, setUsers] = useState([] as any[]);
  const [verifications, setVerifications] = useState([] as any[]);

  useEffect(() => {
    getStores();
    getVerifications();
    let result = localStorage.getItem("GuidAddStore");
    if (!result) {
      showAddStoreGuid();
      localStorage.setItem("GuidAddStore", "1");
    }
  }, []);

  function setVerificationDueDate(date, lane) {
    let Verifications = verifications;
    let verification = null;
    Verifications.forEach((veri) => {
      if (veri.laneId == lane.id) verification = veri;
    });

    verification = {
      ...verification,
      dueDate: date,
    };

    const newVerifications = Verifications.map((v) => {
      let result = null;
      if (v.laneId == lane.id) {
        result = {
          ...v,
          dueDate: date,
        };
      } else {
        result = v;
      }
      return result;
    });

    setVerifications(newVerifications);

    let data = {
      assignToUserId: verification.assignToId,
      LaneId: lane.id,
      DueDate: date,
      ReceiveNotifications: verification.receiveNotifications,
      Frequency: verification.frequency,
    };
    apiService.createVerification(data);
  }

  async function setVerificationUser(assignedTo, lane) {
    let Verifications = verifications;
    let verification = null;
    Verifications.forEach((veri) => {
      if (veri.laneId == lane.id) verification = veri;
    });

    verification = {
      ...verification,
      assignToId: assignedTo,
    };

    const newVerifications = Verifications.map((v) => {
      let result = null;
      if (v.laneId == lane.id) {
        result = {
          ...v,
          assignToId: assignedTo,
        };
      } else {
        result = v;
      }
      return result;
    });

    setVerifications(newVerifications);

    let data = {
      assignToUserId: assignedTo,
      LaneId: lane.id,
      DueDate: verification.dueDate,
      ReceiveNotifications: verification.receiveNotifications,
      Frequency: verification.frequency,
    };

    apiService.createVerification(data);
  }

  function getStores() {
    setIsLoading(true);
    apiService.getStores().then((data: any) => {
      data = data.data;
      var lanesData = [];

      data.forEach((element) => (lanesData[element.id] = getLanes(element.id)));
      setStores(data);

      setOriginalStores(data);
      setIsLoading(false);
      guidConditions(data);
    });
  }

  async function getVerifications() {
    let users = await apiService.getAllUsers();
    setUsers(users.data);

    let Verifications = await apiService.getDashboardVerifications();
    setVerifications(Verifications.data);
  }

  function getLanes(id) {
    // apiService.getLanesByStore(id).then((data: any));
    apiService.getLanesByStore(id).then((data: any) => {
      data = data.data;
      setLanes(data);
    });
    return lanes;
  }

  function getLaneDueDate(id) {
    let Verifications = verifications;
    var returnDate = new Date();
    Verifications.forEach((veri) => {
      if (veri.laneId == id) {
        returnDate = new Date(veri.dueDate);
      }
    });
    return returnDate;
  }

  function getLaneAssignedTo(id) {
    let Verifications = verifications;
    var returnID = 0;
    Verifications.forEach((veri) => {
      if (veri.laneId == id) {
        returnID = veri.assignToId;
      }
    });
    return returnID;
  }

  function guidConditions(data) {
    if (data.length == 1) {
      if (!localStorage.getItem("GuidFirstStoreCreatedTime")) {
        localStorage.setItem(
          "GuidFirstStoreCreatedTime",
          new Date().toDateString()
        );
      } else {
        let time = new Date(localStorage.getItem("GuidFirstStoreCreatedTime"));
        if (
          moment(new Date(localStorage.getItem("GuidFirstStoreCreatedTime")))
            .add(60, "h")
            .toDate() < moment().toDate()
        ) {
        }
      }

      if (!localStorage.getItem("GuidShowAddLaneAfterStore")) {
        showAddLaneGuid(data);
        localStorage.setItem("GuidShowAddLaneAfterStore", "1");
      }
    }
    if (data?.length == 1 && data[0].lanes?.length == 1) {
      if (!localStorage.getItem("GuidAddLaneFromStoreShown")) {
        showLanesPageGuid1();
        localStorage.setItem("GuidAddLaneFromStoreShown", "1");
      }
    }
  }

  async function createDevice(equipment, lane) {
    let data = {
      equipmentId: equipment.id,
      serial: equipment.serial,
      assetTag: equipment.assetTag,
      model: equipment.model,
      laneId: lane.id,
      PlacedBy: equipment.placedBy,
      datePlaced: equipment.datePlaced,

      notes: equipment.note,
    };

    setIsLoading(true);

    apiService.createDevice(data).then(async (data: any) => {
      data = data.data;

      setIsLoading(false);
    });
  }

  function addStoreButtonUI() {
    let isAdmin = checkClaim("admin");
    if (!isAdmin) {
      return <Fragment />;
    }
    return (
      <button
        id="add-store-btn"
        className="btn btn-pcid mt-3 ml-3 mb-2"
        onClick={() => {
          setIsLoading(true);
          apiService
            .getStoreMessage()
            .then((data: any) => {
              data = data.data;
              setIsLoading(false);
              if (data == true) {
                setShowPaymentNotification(true);
              } else {
                props.history.push("/addStore");
              }
            })
            .catch((err) => {
              if (err.response.status == 403) {
                toast.error("You don't have permission to add stores");
              }
            });
        }}
      >
        Add Store
      </button>
    );
  }

  function showPaymentNotificationUI() {
    if (!showPaymentNotification) {
      return <></>;
    }

    return (
      <div className="pop">
        <div className="content-area">
          <h2 className="text-muted">Subscription Details</h2>
          <span className="big-text">$20/month</span>
          <br />
          <span>You will be charged at the end of every month</span>
          <hr />
          <div className="d-flex justify-content-between">
            <button
              className="btn"
              onClick={() => {
                setShowPaymentNotification(false);
              }}
            >
              <i className="fas fa-times mr-1" />
              Cancel
            </button>
            <button
              className="btn btn-pcid"
              onClick={() => {
                props.history.push("/addStore");
              }}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    );
  }

  function showAddStoreGuid() {
    setShowGuid(
      getGuidElement({
        elementId: "#add-store-btn",
        jsx: (
          <div>
            <p>To get started you'll have to add a store to "My Store" page</p>
            <div
              className="cursor-pointer"
              onClick={() => {
                if (!localStorage.getItem("GuidStoreHelp")) {
                  showInitGuid();
                } else {
                  setShowGuid(null);
                }
              }}
            >
              Got it
            </div>
          </div>
        ),
        top: 10,
        left: 50,
        onClose: () => {
          if (!localStorage.getItem("GuidStoreHelp")) {
            showInitGuid();
          }
        },
      })
    );
  }

  function showAddLaneGuid(data = null) {
    if (!data) {
      data = stores;
    }
    if (data.length <= 0) {
      toast.info("Create at least one store");
      return;
    }
    setShowGuid(
      getGuidElement({
        elementId: "#add-new-lane0",
        jsx: (
          <div>
            <span>
              Now that you've added a store, you can add lanes to your store
            </span>
            <br />
            <div
              style={{ marginTop: 15, cursor: "pointer" }}
              onClick={() => {
                setShowGuid(null);
              }}
            >
              Got it
            </div>
          </div>
        ),
        top: 10,
        left: 50,
      })
    );
  }

  function showEditManager() {
    if (stores.length <= 0) {
      toast.info("Create at least one store");
      return;
    }
    setShowGuid(
      getGuidElement({
        elementId: "#drop-menu0",
        jsx: (
          <div>
            <span>
              You can edit your store by clicking the image icon or open menu
            </span>
            <br />
            <div
              style={{ marginTop: 15, cursor: "pointer" }}
              onClick={() => {
                setShowGuid(null);
              }}
            >
              Got it
            </div>
          </div>
        ),
        top: 50,
        left: 0,
      })
    );
  }

  function showInitGuid() {
    setShowGuid(
      getGuidElement({
        elementId: "#store-guid",
        jsx: (
          <div>
            If you need help, this button
            <br />
            will show you a tutorial.
            <div
              style={{ marginTop: 15, cursor: "pointer" }}
              onClick={() => {
                setShowGuid(null);
              }}
            >
              Got it
            </div>
          </div>
        ),
        top: 0,
        left: 0,
      })
    );
  }

  function showLanesPageGuid1() {
    setShowGuid(
      getGuidElement({
        elementId: "#nav-lanes",
        jsx: (
          <div>
            <p>
              You can see every lane <br />
              you've added by clicking <br />
              'Lanes' Button
            </p>
            <div
              className="cursor-pointer"
              onClick={() => {
                showLanesPageGuid2();
              }}
            >
              Next
            </div>
          </div>
        ),
        top: 30,
        left: 50,
      })
    );
  }

  function showLanesPageGuid2() {
    setShowGuid(
      getGuidElement({
        elementId: "#nav-lanes",
        jsx: (
          <div>
            <p>
              You can also add PCI <br />
              equipment to a lane from <br />
              the 'Lanes' page
            </p>
            <div
              className="cursor-pointer"
              onClick={() => {
                setShowGuid(null);
              }}
            >
              Got it
            </div>
          </div>
        ),
        top: 0,
        left: 0,
      })
    );
  }

  function myAssets() {
    var data = "013354127";

    apiService.getVerificationByAsset(data).then((data: any) => {
      data = data.data;
      console.log("assettagtest", data);
    });
  }

  function showStoreGuid() {
    localStorage.setItem("GuidStoreHelp", "1");
    setShowGuid(
      getGuidElement({
        elementId: "#store-guid",
        jsx: (
          <div>
            <div
              className="store-guid-item"
              onClick={() => {
                showAddStoreGuid();
              }}
            >
              Adding a store
            </div>

            <div
              className="store-guid-item"
              onClick={() => {
                showAddLaneGuid();
              }}
            >
              Adding a lane to the store
            </div>
            <div
              onClick={() => {
                showEditManager();
              }}
              className="store-guid-item"
            >
              Changing store manager
            </div>
          </div>
        ),
        top: 0,
        left: 0,
      })
    );
  }

  return (
    <DashboardLayout
      title="Stores"
      onHelpClick={() => {
        showStoreGuid();
      }}
      helpId={"store-guid"}
    >
      <Loading isLoading={isLoading} />
      {myAssets()}
      {MyStores()}
      {showPaymentNotificationUI()}
    </DashboardLayout>
  );

  function MyStores() {
    return (
      <React.Fragment>
        {addStoreButtonUI()}
        <div className="table-responsive">
          <div className="search">
            {getSearch(
              stores,
              setStores,
              [
                "name",
                "phoneNumber",
                "manager.firstName",
                "manager.email",
                "address",
              ],
              originalStores
            )}
          </div>
          <table className="table table-borderless table-v-center">
            <thead>
              <tr className="colored-row">
                <th />
                <th />
                {tableColumns("Store", "name", stores, setStores)}
                {tableColumns(
                  "Manager",
                  "manager.firstName",
                  stores,
                  setStores
                )}
                {/* {tableColumns(
                                    "Manager's Email",
                                    "manager.email",
                                    stores,
                                    setStores
                                )} */}
                {tableColumns("Number", "phoneNumber", stores, setStores)}
                {tableColumns("Address", "address", stores, setStores)}
              </tr>
            </thead>
            <tbody>
              {stores.map((store, i) => {
                return (
                  <React.Fragment key={store.id}>
                    {/* Adds space between table columns */}
                    <div className="table-space"></div>

                    <tr>
                      <td scope="col" id={"drop-menu" + i}>
                        <OptionsDropDown>
                          <Dropdown.Item
                            onClick={() => {
                              props.history.push("/editStore/" + store.id);
                            }}
                          >
                            Edit
                          </Dropdown.Item>
                        </OptionsDropDown>
                      </td>
                      <td scope="col">
                        <Link to={"/editStore/" + store.id}>
                          <div
                            className="profile-image"
                            style={{
                              backgroundImage: `url(${getImage(
                                store.imageURL
                              )})`,
                            }}
                          />
                        </Link>
                      </td>
                      <td>{store.name}</td>
                      <td>
                        <Link className="table-links" to="/users">
                          {store.manager?.firstName}
                        </Link>
                      </td>
                      {/* <td>{store.managerEmail}</td> */}
                      <td className="text-nowrap">{store.phoneNumber}</td>
                      <td>{store.address}</td>
                    </tr>
                    <tr className="lane-section">
                      <td className="empty" />
                      <td colSpan={5} className="inner-row">
                        <div className="map-grid">
                          <div className="map-auto"></div>
                          <strong className="map-auto">#</strong>
                          <strong className="map-auto">Name</strong>
                          <strong className="map-auto">Terminal</strong>
                          <strong className="map-auto">Assigned To</strong>
                          <strong className="map-auto">
                            Next Verification Due
                          </strong>
                        </div>

                        {store.lanes.map((lane) => {
                          return (
                            <React.Fragment key={lane.id}>
                              <div className="map-grid">
                                <div className="map-auto">
                                  <OptionsDropDown>
                                    <Dropdown.Item
                                      onClick={() => {
                                        props.history.push(
                                          "/editLane/" + lane.id
                                        );
                                      }}
                                    >
                                      Edit
                                    </Dropdown.Item>
                                  </OptionsDropDown>
                                </div>
                                <div className="map-auto">{lane.number}</div>
                                <div className="map-auto">{lane.name}</div>
                                {lane.pciEquipments
                                  .slice(0, 1)
                                  .map((verification) => {
                                    return (
                                      <React.Fragment key={verification.id}>
                                        <div className="map-auto">
                                          {verification.model}
                                        </div>
                                        <div className="map-auto">
                                          <select
                                            onChange={(e) => {
                                              setVerificationUser(
                                                e.target.value,
                                                lane
                                              );
                                            }}
                                            value={getLaneAssignedTo(lane.id)}
                                            className="stores-select"
                                          >
                                            {users.map((user) => {
                                              return (
                                                <option
                                                  key={user.id}
                                                  value={user.id}
                                                >
                                                  {user.firstName}{" "}
                                                  {user.lastName}
                                                </option>
                                              );
                                            })}
                                          </select>
                                        </div>

                                        <div className="map-auto calendar">
                                          <div className="calendarStores">
                                            <StoreCalendar
                                              selected={getLaneDueDate(lane.id)}
                                              onChange={(e) => {
                                                setVerificationDueDate(e, lane);
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </React.Fragment>
                                    );
                                  })}
                              </div>
                            </React.Fragment>
                          );
                        })}
                      </td>
                    </tr>
                    <tr className="add-item">
                      <td className="empty" />
                      <td colSpan={5}>
                        <Link
                          className="table-add-link"
                          to={"/addLane/" + store.id}
                        >
                          <div className="row">
                            <div className="col-auto">
                              <i className="fas fa-plus-circle icon-green ml-3" />{" "}
                            </div>
                            <div id={"add-new-lane" + i} className="col-auto">
                              Add New Lane
                            </div>
                          </div>
                        </Link>
                      </td>
                      <th className="empty" />
                    </tr>
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
      </React.Fragment>
    );
  }
};

export default Stores;
