import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import FileButton from "../../components/FileButton";
import TextBox from "../../components/TextBox";
import ApiService from "../../services/ApiService";
import AppDropdown from "../../components/AppDropdown";
import { useParams } from "react-router-dom";
import BestCalendar from "../../components/BestCalendar";
import Loading from "../../components/Loading";
import AppModal from "../../components/AppModal";
import Steps from "../../components/Steps";
import { toast } from "react-toastify";

function SetupShopEquipment() {
    const history = useHistory();

    const { equipmentId } = useParams() as any;

    const [isLoading, setIsLoading] = useState(false);

    const [storeName, setStoreName] = useState("");

    const [baseImageOne, setBaseImageOne] = useState(null as any);
    const [baseImageTwo, setBaseImageTwo] = useState(null as any);
    const [pinPadNumber, setPinPadNumber] = useState(null as any);
    const [serialNumber, setSerialNumber] = useState("");
    const [modelNumber, setModelNumber] = useState("");
    const [modelNumbers, setModelNumbers] = useState([] as any[]);
    const [datePlaced, setDatePlaced] = useState(new Date());
    const [tagNumber, setTagNumber] = useState("");
    const [userId, setUserId] = useState(0);
    const [users, setUsers] = useState([] as any[]);

    const [maxRenderIndex, setMaxRenderIndex] = useState(0);
    const [currentRenderIndex, setCurrentRenderIndex] = useState(1);
    const [currentRenderObj, setCurrentRenderObj] = useState(null as any);

    const [shopData, setShopData] = useState(null as any);

    const [pinPadIdError, setPinPadIdError] = useState("");
    const [serialNumberError, setSerialNumberError] = useState("");
    const [modelNumberError, setModelNumberError] = useState("");
    const [datePlacedError, setDatePlacedError] = useState("");
    const [tagNumberError, setTagNumberError] = useState("");
    const [userIdError, setUserIdError] = useState("");

    const [savedLanes, setSavedLanes] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const apiService = ApiService();

    useEffect(() => {
        function setData() {
            const data = JSON.parse(localStorage.getItem("shopData"));

            if (data != undefined && savedLanes.length != 0) {

                var tempArr = [];

                data.forEach((element) => {

                    const foundLaneElement = savedLanes.find(findElement => findElement.name == element.laneName);

                    element.laneId = foundLaneElement.id;

                    tempArr.push(element);
                });

                setShopData(tempArr)
            }
        }

        setData();
    }, [savedLanes])

    useEffect(() => {
        function setCurrentObjFunction() {
            if (shopData != null) {

                const foundFirstElement = shopData.find(element => element.equipmentPerLane !== 0);

                const equipmentForIndex = foundFirstElement.equipmentPerLane

                setCurrentRenderObj(foundFirstElement);
                setMaxRenderIndex(equipmentForIndex);
                setCurrentRenderIndex(1);
            }
        }

        setCurrentObjFunction();
    }, [shopData])

    useEffect(() => {
        setIsLoading(true);

        apiService.getManagers().then((data: any) => {

            data = data.data;

            let users = data.filter((d) => {
                return d.selectedRole == "Manager" || d.selectedRole == "Admin";
            });

            setIsLoading(false);
            setUsers(users);
        }).catch((err) => {
            setIsLoading(false);
            setModalIsOpen(true)
        });

        setIsLoading(true);

        apiService.getStores().then((data: any) => {

            data = data.data;

            const lanesForCurrentStore = data[data.length - 1].lanes
            const getStoreName = data[data.length - 1].name;

            setIsLoading(false);
            setSavedLanes(lanesForCurrentStore);
            setStoreName(getStoreName);
        }).catch((err) => {
            setIsLoading(false);
            setModalIsOpen(true)
        });

        apiService.getDeviceModelList().then((data: any) => {

            data = data.data;

            let items = [] as any[];

            if (data) {
                data.forEach((item) => { items.push({ value: item, label: item }); });
            }

            setModelNumbers(items);
        });
    }, []);

    function clearPage() {
        //Clear Form Elements
        setBaseImageOne(null as any);
        setBaseImageTwo(null as any);
        setPinPadNumber(null);
        setSerialNumber("");
        setModelNumber("");
        setDatePlaced(new Date());
        setTagNumber("");
        setUserId(0);

        //Clear Erros
        setPinPadIdError("");
        setSerialNumberError("");
        setModelNumberError("");
        setDatePlacedError("");
        setTagNumberError("");
        setUserIdError("");
    }

    function handleFormErrors(err) {
        if (err) {
            toast.error("Please check all the required fields");
            setModelNumberError(err.Model?.[0]);
            setPinPadNumber(err.PinPad?.[0]);
            setSerialNumberError(err.Serial?.[0]);
            setTagNumberError(err.AssetTag?.[0]);
            setUserIdError(err.PlacedBy?.[0]);
        } else {
            toast.error("Error. Try Again");
        }
    }

    function saveShop() {
        if (currentRenderIndex === maxRenderIndex) {

            let data = {
                equipmentId: equipmentId,
                pinPad: pinPadNumber,
                serial: serialNumber,
                assetTag: tagNumber,
                model: modelNumber,
                laneId: currentRenderObj.laneId,
                PlacedBy: userId ? userId : null,
                datePlaced: datePlaced,
                imageFront: null,
                imageBack: null,
                imageMain: null,
                imageTop: null,
                imageLeft: null,
                imageBottom: null,
                imageRight: null,
            }

            setIsLoading(true);

            apiService.createDevice(data).then(async (data: any) => {
                data = data.data;

                if (shopData.length > 1) {
                    shopData.forEach((element, index) => {
                        if (element.laneNumber == currentRenderObj.laneNumber) {

                            const tempShopDataArr = shopData.slice(index + 1);

                            const foundNextElement = tempShopDataArr.find(element => element.equipmentPerLane > 0);

                            if (foundNextElement != undefined) {
                                setCurrentRenderObj(foundNextElement);
                                setMaxRenderIndex(foundNextElement.equipmentPerLane);
                                setCurrentRenderIndex(1);

                                setIsLoading(false);
                                clearPage();
                            } else {
                                setIsLoading(false);
                                localStorage.removeItem("shopData")
                                history.push("/done")
                            }
                        }
                    });
                } else {
                    setIsLoading(false);
                    localStorage.removeItem("shopData")
                    history.push("/done")
                }
            }).catch((error) => {
                handleFormErrors(error?.response?.data?.errors);
                setIsLoading(false);
            });
        } else if (currentRenderIndex < maxRenderIndex) {

            let data = {
                equipmentId: equipmentId,
                pinPad: pinPadNumber,
                serial: serialNumber,
                assetTag: tagNumber,
                model: modelNumber,
                laneId: currentRenderObj.laneId,
                PlacedBy: userId ? userId : null,
                datePlaced: datePlaced,
                imageFront: null,
                imageBack: null,
                imageMain: null,
                imageTop: null,
                imageLeft: null,
                imageBottom: null,
                imageRight: null,
            }

            setIsLoading(true);

            apiService.createDevice(data).then(async (data: any) => {
                data = data.data;

                setIsLoading(false);

                setCurrentRenderIndex(currentRenderIndex + 1)
                clearPage();
            }).catch((error) => {
                handleFormErrors(error?.response?.data?.errors);
                setIsLoading(false);
            });
        }
    }

    function continueTo() {
        history.push("/login");
    }

    function backTo() {
        setModalIsOpen(false)
        history.push("/setup-shop");
    }

    function renderEquipmentForm(element) {
        var equipmentForms = [];

        if (element !== null && element.equipmentPerLane !== 0) {
            equipmentForms.push(
                <div key={element.id + " " + currentRenderIndex}>
                    <div style={{ display: "flex", marginBottom: "1rem", flexWrap: "wrap" }} className="general-info"><div style={{ fontWeight: "bold", marginRight: "0.3rem" }}>'Equipment {currentRenderIndex}'</div> setup for Lane - {element.laneNumber}<div style={{ fontWeight: "bold", marginLeft: "0.3rem" }}>'{element.laneName}'</div></div>
                    <form>
                        <div className="justify-icons">
                            <div style={{ margin: "1rem" }}>
                                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                    <FileButton onChange={(file) => { if (file) { setBaseImageOne(file); } }} image={baseImageOne} styles={{ width: "4.5rem", height: "4.5rem" }} />
                                    <label style={{ fontSize: "0.6rem", color: "#3c19fb" }} htmlFor="base-line-image-one">Baseline Image 1</label>
                                </div>
                            </div>
                            <div style={{ margin: "1rem" }}>
                                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                    <FileButton onChange={(file) => { if (file) { setBaseImageTwo(file); } }} image={baseImageTwo} styles={{ width: "4.5rem", height: "4.5rem" }} />
                                    <label style={{ fontSize: "0.6rem", color: "#3c19fb" }} htmlFor="base-line-image-two">Baseline Image 2</label>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-md-4">
                                <div className="form-group mt-1">
                                    <label className="ml-3" htmlFor="serial-number">Serial Number *</label>
                                    <TextBox type="text" onChange={(val) => { setSerialNumber(val); }} placeholder={"Serial Number"} className="form-control sign-in-inputs" error={serialNumberError} value={serialNumber} />
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-4">
                                <div className="form-group mt-1">
                                    <label className="ml-3" htmlFor="model-number">Model Number *</label>
                                    <AppDropdown items={modelNumbers} onChange={(val) => { setModelNumber(val); }} error={modelNumberError} value={modelNumber} />
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-4">
                                <div className="mt-1">
                                    <label style={{ marginBottom: "0rem" }} htmlFor="date-placed-into-service">Date Placed Into Service *</label>
                                    <BestCalendar styles={{ paddingLeft: "0px" }} selected={datePlaced} onChange={(e) => { setDatePlaced(e); }} />
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-4">
                                <div className="form-group mt-1">
                                    <label className="ml-3" htmlFor="placed-into-service-by">Placed Into Service By *</label>
                                    <AppDropdown value={userId.toString()} onChange={(val) => { setUserId(val) }} error={userIdError}>
                                        <option value="0">Select a User</option>
                                        {users.map((user) => {
                                            return (
                                                <option key={user.id} value={user.id}>
                                                    {user.firstName}{" "}{user.lastName} ({user.selectedRole})
                                                </option>
                                            );
                                        })}
                                    </AppDropdown>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-4">
                                <div className="form-group mt-1">
                                    <label className="ml-3" htmlFor="tag-number">Asset Tag Number *</label>
                                    <TextBox type="text" onChange={(val) => { setTagNumber(val); }} placeholder={"Asset Tag Number"} className="form-control sign-in-inputs" error={tagNumberError} value={tagNumber} />
                                </div>
                            </div>
                        </div>

                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            <div style={{ width: 220, }}>
                                <button className="btn" onClick={(e) => { e.preventDefault(); saveShop(); }}>Next</button>
                            </div>
                        </div>
                    </form>
                </div>
            );
        }

        return equipmentForms;
    }

    function redirectToDashboard() {
        history.push("/dashboard")
    }

    return (
        <React.Fragment>
            <Loading isLoading={isLoading} />
            <AppModal isContinueButtonOnly={true} message={"Error has occured. Please proceed to login."} onClick={() => { continueTo() }} onClose={() => { backTo(); }} isOpen={modalIsOpen} />

            <div className="full-layout">
                <div style={{ marginBottom: "3rem" }}>
                    <div>
                        <button className="btn-redirect" onClick={(e) => { e.preventDefault(); redirectToDashboard(); }}>Skip</button>
                    </div>
                </div>
                <div className="container">
                    <div style={{ width: "100%" }} className="white-box">
                        <div style={{ marginBottom: "1.5rem" }} className="row">
                            <div className="col-sm-12 col-md-5">
                                <h2 style={{ marginTop: "0.7rem" }} className="mb-3 topic-text ht" id={"shop-profile"}>'{storeName}' Equipment</h2>
                            </div>
                            <div className="col-sm-12 col-md-7">
                                <Steps iteration={4} activeIndex={3} />
                            </div>
                        </div>
                        <div>
                            <div>{renderEquipmentForm(currentRenderObj)}</div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default SetupShopEquipment;
