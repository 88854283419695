import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';

interface modalProps {
    buttonText: string;
    body: any;
    onClose?: CallableFunction;
}
function getModalStyle() {
    const top = "4rem";
    const left = "20rem";

    return {
        top: `${top}`,
        left: `${left}`
    };
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            position: 'absolute',
            width: '50rem',
            maxHeight: '40rem',
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            overflow: "auto"
        },
        buttonStyles: {
            border: 'none',
            background: 'white',
            color: 'blue',
            fontSize: ' 0.7rem'
        }
    }),
);

const FlexibleModal = (props: modalProps) => {
    const classes = useStyles();
    const [modalStyle] = React.useState(getModalStyle);
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        if (props.onClose != undefined) {
            props.onClose()
        }
        setOpen(false);
    };

    const body = (
        <div style={modalStyle} className={classes.paper}>{props.body}</div>
    );

    return (
        <div>
            <button className={classes.buttonStyles} type="button" onClick={handleOpen}>{props.buttonText}</button>
            <Modal open={open} onClose={handleClose} aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description">
                {body}
            </Modal>
        </div>
    );
}

export default FlexibleModal;