/* eslint-disable */

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ApiService from "../../services/ApiService";
import DashboardLayout from "../../layouts/DashboardLayout";
import { getImage } from "../../services/HelperMethod";
import Loading from "../../components/Loading";
import moment from "moment";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

const MaintenanceLogDetails = (props) => {
    const { logId } = useParams() as any;
    const apiService = ApiService();
    const [result, setResult] = useState(null as any);
    const [additionalImages, setAdditionalImages] = useState([] as any[]);
    const [isLoading, setisLoading] = useState(false);

    useEffect(() => {
        setisLoading(true);
        apiService.getMaintenanceLogResult(logId).then((data: any) => {
            setisLoading(false);
            data = data.data;

            setResult(data);
            if (data.additionalImages) {
                var imgs = data.additionalImages.split(",");
                imgs = imgs.filter((img) => img);
                setAdditionalImages(imgs);
            }
        });
    }, []);

    return (
        <DashboardLayout title="Maintenance Log">
            <Loading isLoading={isLoading} />
            <div className="mb-4">
                <div>Model: {result?.asset?.modelNumber}</div>
                <div>Serial: {result?.asset?.serialNumber}</div>
                <div>Description: {result?.asset?.description}</div>
            </div>
            <div className="row result-row">
                <div className="col-md-6">Does the barcode match</div>
                <div className="col-md-6">
                    {result?.isBarcodeMatch ? (
                        <span className="text-success">
                            <i className="fas fa-check"></i> Yes
                        </span>
                    ) : (
                        <span className="text-danger">
                            <i className="fas fa-times"></i> No
                        </span>
                    )}
                </div>
            </div>
            <div className="row result-row">
                <div className="col-md-6">Does the serial number match</div>
                <div className="col-md-6">
                    {result?.isSerialCodeMatch ? (
                        <span className="text-success">
                            <i className="fas fa-check"></i> Yes
                        </span>
                    ) : (
                        <span className="text-danger">
                            <i className="fas fa-times"></i> No
                        </span>
                    )}
                </div>
            </div>
            <div className="row result-row">
                <div className="col-md-6">Was Maintenance Complete</div>
                <div className="col-md-6">
                    {result?.isComplete ? (
                        <span className="text-success">
                            <i className="fas fa-check"></i> Yes
                        </span>
                    ) : (
                        <span className="text-danger">
                            <i className="fas fa-times"></i> No
                        </span>
                    )}
                </div>
            </div>

            <div className="row result-row">
                <div className="col-md-6">Additional Info</div>
                <div className="col-md-6">{result?.additionalInfo}</div>
            </div>
            <div className="row result-row">
                <div className="col-md-6">Attached Image</div>
                <div className="col-md-6">
                    <a href={result?.mainImage} target="_blank">
                        <img src={result?.mainImage} width="300" />
                    </a>
                </div>
            </div>
            {/* <div className="row result-row">
                <div className="col-md-6">Additional Images</div>
                <div className="col-md-6">
                    {additionalImages.map((img, i) => {
                        return (
                            <a key={i} href={getImage(img)} target="_blank">
                                <img
                                    className="mb-1"
                                    src={getImage(img)}
                                    width="300"
                                />
                            </a>
                        );
                    })}
                </div>
            </div> */}

            <h5 className="mt-4 mb-3">Additional:</h5>
            <div className="row result-row">
                <label>
                    Submit by: {result?.user?.firstName}{" "}
                    {result?.user?.lastName}
                </label>
            </div>
            <div className="row result-row">
                <label>
                    Date: {moment(result?.maintenance.completedDate).local().format("ll")}
                </label>
            </div>
            <div className="text-right">
                <button
                    onClick={() => {
                        props.history.goBack();
                    }}
                    className="btn btn-secondary btn-pcid-cancel mt-3"
                >
                    Back
                </button>
            </div>
        </DashboardLayout>
    );
};

export default MaintenanceLogDetails;
