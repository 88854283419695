/* eslint-disable */

import React, { useState, useEffect } from "react";
import DashboardLayout from "../../layouts/DashboardLayout";
import ApiService from "../../services/ApiService";
import {
  getImage,
  tableColumns,
  getSearch,
  OptionsDropDown,
} from "../../services/HelperMethod";
import Loading from "../../components/Loading";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import Item from "antd/lib/list/Item";

import AppButton from "../../components/AppButton";

var moment = require("moment");

function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return () => setValue((value) => value + 1); // update the state to force render
}

function Reports(props) {
  const forceUpdate = useForceUpdate();
  const [isLoading, setisLoading] = useState(false);

  const [filters, setFilters] = useState([]);

  const [reports, setReports] = useState([]);
  const [MaintReports, setMaintenanceReports] = useState([]);
  const [AssetReports, setAssetReports] = useState([]);
  const [originalReports, setOriginalReports] = useState([]);

  const apiService = ApiService();

  useEffect(() => {
    getReports();
  }, []);

  function getReports() {
    setisLoading(true);

    apiService.getReports().then((data: any) => {
      setReports(data.data);
      setOriginalReports(data.data);
    });

    setisLoading(false);
  }

  function addReportBtn() {
    // apiService.addReport();

    window.location.reload();
  }

  function DeleteReport(ReportID) {
    apiService.deleteReport(ReportID);

    window.location.reload();
  }

  function filter() {
    setReports(originalReports);
    var filteredReport = originalReports;
    var filterValues = [];
    var OR = [];

    //get variables from filter
    for (var i = 0; i < filters.length; i++) {
      filterValues[i] = filters[i][0];
    }

    //get inclusive variables
    for (var i = 0; i < filters.length; i++) {
      if (filters[i][1] == 1) {
        OR.push(i);
      }
    }

    //filter inclusive variables
    filteredReport = filteredReport.filter((item) => {
      return (
        item.includes(filterValues[OR[0]]) ||
        item.includes(filterValues[OR[1]]) ||
        item.includes(filterValues[OR[2]]) ||
        item.includes(filterValues[OR[3]]) ||
        item.includes(filterValues[OR[4]])
      );
    });

    //filter exclusive variables
    for (var i = 0; i < filters.length; i++) {
      if (filters[i][1] == 2) {
        filteredReport = filteredReport.filter((item) => {
          return item.includes(filterValues[i]);
        });
      }
    }

    //filter dates

    console.log(filteredReport);

    setReports(filteredReport);
    if (filters == null) {
      setReports(originalReports);
    }

    forceUpdate();
  }

  function addValue(item, int) {
    var newValues = filters;
    newValues[newValues.length] = [item, int];
    setFilters(newValues);

    filter();
  }

  function removeValue(item) {
    var currentValues = filters;
    var newValues = currentValues.filter((e) => e !== item);
    var filteredReport = reports;

    for (var i = 0; i < filters.length; i++) {
      filteredReport = filteredReport.filter((e) => e == item);
    }

    setFilters(newValues);
    filter();
  }

  return (
    <DashboardLayout title="My Reports">
      <React.Fragment>
        <input
          className="btn btn-success btn-pcid mt-3 ml-3 mb-2"
          type="button"
          value="Generate Report"
          onClick={() => {
            addReportBtn();
          }}
        />
        <Loading isLoading={isLoading} />
        <div className="table-responsive">
          <div className="search">
            {getSearch(
              reports,
              setReports,
              ["number", "name", "CreationDate"],
              originalReports
            )}
          </div>

          <table className="table table-borderless table-v-center">
            <thead>
              <tr className="colored-row">
                <th scope="col" />
                {tableColumns("Report Name", "string", reports, setReports)}
                {tableColumns("Edited By", "string", reports, setReports)}
                {tableColumns("Last Modified", "DateTime", reports, setReports)}
              </tr>
            </thead>
            <tbody>
              {reports.map((report: any) => {
                return (
                  <React.Fragment key={report.id}>
                    <tr>
                      <td scope="col">
                        <OptionsDropDown>
                          <Dropdown.Item
                            onClick={() => {
                              DeleteReport(report.id);
                            }}
                          >
                            Delete Report
                          </Dropdown.Item>
                        </OptionsDropDown>
                      </td>
                      <td>
                        <Link to={"/ComplianceReport/" + report.id}>
                          <td scope="col">PCI_Section9_Compliance Report</td>
                        </Link>
                      </td>
                      <td scope="col">{report.lastModified}</td>
                      <td scope="col">{report.lastModified}</td>
                    </tr>
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
      </React.Fragment>
    </DashboardLayout>
  );
}

export default Reports;
