import { useStaticState } from "@material-ui/pickers";
import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { CSSProperties } from "react";

export default function Guid(props: {
    top?: number;
    left?: number;
    isShow: boolean;
    onClose: CallableFunction;
    children;
    element: string;
}) {
    const [style, setStyle] = useState(null as any);
    useEffect(() => {
        let $ = (window as any).jQuery;
        let element = $(props.element);
        let guidPosition = element?.[0]?.getBoundingClientRect();
        let topAdd =
            props.top != undefined && props.top != null ? props.top : 0;
        let leftAdd =
            props.left != undefined && props.left != null ? props.left : 0;
        setStyle({
            // top: guidPosition?.top + props.top != null ? props.top : 0,
            // left: guidPosition?.left + props.left != null ? props.left : 0,

            top: guidPosition?.top + topAdd,
            left: guidPosition?.left + leftAdd,
            width: 300,
            textAlign: "left",
            flexDirection: "column",
        });
        
    }, [props.isShow, props.element]);
    if (!props.isShow) {
        return <Fragment />;
    }

    return (
        <div>
            <div
                className="guid animate__animated animate__fadeIn"
                data-element={props.element}
                onClick={() => {
                    props.onClose();
                }}
            >
                <div
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    className="helper"
                    style={style}
                >
                    <i
                        className="fas fa-caret-up"
                        style={{
                            position: "absolute",
                            top: -23,
                            color: "#138eac",
                            fontSize: 35,
                        }}
                    />
                    {props.children}
                </div>
            </div>
        </div>
    );
}
