import React, { useEffect, useState } from "react";
import BestCalendar from "./BestCalendar";
import AppTooltip from "./AppTooltip";
import { handleErrors } from "../services/HelperMethod";
import ApiService from "../services/ApiService";
import Loading from "./Loading";
import Switch from '@material-ui/core/Switch';
import moment from "moment";
import { colors } from "@material-ui/core";
import { Link } from "react-router-dom";

interface VerificationInfoProps {
    laneId: number;
    onChange: CallableFunction;
}
export default function VerificationInfo(props: VerificationInfoProps) {
    const apiService = ApiService();
    const [isLoading, setIsLoading] = useState(false);

    const [verificationUser, setVerificationUser] = useState("");
    const [verificationFrequency, setVerificationFrequency] = useState("2");
    const [verificationDueDate, setVerificationDueDate] = useState(new Date());
    // const [verificationEndDate, setVerificationEndDate] = useState(new Date());
    const [isChanged, setIsChanged] = useState(false);
    const [users, setUsers] = useState([] as any[]);

    const [receiveNotifications, setReceiveNotifications] = useState(true);

    const [wasPreviousVerificationAutoCompleted, setWasPreviousVerificationAutoCompleted] = useState();
    const [wasPreviousVerificationSuccessful, setWasPreviousVerificationSuccessful] = useState();
    const [previousVerificationDate, setPreviousVerificationDate] = useState(null as any);
    const [nextVerificationDate, setNextVerificationDate] = useState(null as any);
    const [lastVerificationLogId, setLastVerificationLogId] = useState(0);
    const [previousVerificationDoneBy, setPreviousVerificationDoneBy] = useState(null as any);

    // const handleChange = () => {
    //     setReceiveNotifications(!receiveNotifications);
    // };

    useEffect(() => {
        if (props.laneId) {
            getLane();
        }
    }, [props.laneId]);

    useEffect(() => {
        setNextVerificationDate(getNextDueDate(verificationFrequency))
    }, [verificationDueDate, verificationFrequency]);

    useEffect(() => {
        callOnChange();
    }, [
        verificationUser,
        verificationFrequency,
        verificationDueDate,
        // verificationEndDate,
        receiveNotifications,
        isChanged,
    ]);

    async function getLane() {
        setIsLoading(true);
        try {
            let users = await apiService.getAllUsers();
            setUsers(users.data);

            let result = (await apiService.getVerification(props.laneId)) as any;

            result = result.data;

            if (result) {
                setVerificationFrequency(result.frequency);
                setVerificationUser(result.assignToId);
                setVerificationDueDate(result.dueDate);
                setReceiveNotifications(result.receiveNotifications);

                let previousVerification = await apiService.wasPreviousVerificationCompleted(props.laneId);
                let wasPreviousVerificationSuccessful = await apiService.wasPreviousVerificationSuccessful(props.laneId);

                setWasPreviousVerificationSuccessful(wasPreviousVerificationSuccessful?.data?.result)
                setLastVerificationLogId(wasPreviousVerificationSuccessful?.data?.verificationLogId);
                setPreviousVerificationDoneBy(previousVerification?.data?.assignTo?.firstName + " " + previousVerification?.data?.assignTo?.lastName + " - " + previousVerification?.data?.assignTo?.username);
                setWasPreviousVerificationAutoCompleted(previousVerification?.data?.autoCreated);
                setPreviousVerificationDate(previousVerification?.data?.dueDate);
                // setVerificationEndDate(result.endDate);
            } else {
                setVerificationFrequency("2");
                setVerificationUser("0");
                setVerificationDueDate(new Date());
                setReceiveNotifications(true);
                // setVerificationEndDate(new Date());
            }
        } catch (error) {
            handleErrors(error);
        }
        setIsLoading(false);
    }

    return (
        <div>
                <Loading
                    isLoading={isLoading}
                />
                {props.laneId ?
                    (<div style={{ display: "flex", flexDirection: "column" }}>
                        <div className="col-sm-12 col-md-6">
                            <div style={{ display: "flex", flexDirection: "row" }} className="form-group mt-1">
                                <label style={{ width: "20rem" }} className="ml-2 mt-1" htmlFor="end-date">
                                    {getLastVerificationInfo(wasPreviousVerificationAutoCompleted, wasPreviousVerificationSuccessful)}
                                </label>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <div style={{ display: "flex", flexDirection: "row" }} className="form-group mt-1">
                                <label style={{ width: "20rem" }} className="ml-2 mt-1" htmlFor="end-date">
                                    Next Verification Due Date{" "}
                                </label>
                                <label style={{ width: "20rem" }} className="ml-2 mt-1" htmlFor="end-date">
                                    {moment(nextVerificationDate).local().format("ll")}
                                </label>
                            </div>
                        </div>
                    </div>) :
                    (<div></div>)
                }
        </div>
    );

    function getLastVerificationInfo(isLastVerificationAutoCompleted, isLastVerificationSuccessful) {
        if (isLastVerificationAutoCompleted != undefined && isLastVerificationSuccessful != undefined) {
            if (isLastVerificationAutoCompleted) {
                return (<div>Last verification have not been started or completed</div>)
            }

            if (isLastVerificationSuccessful) {
                return (
                    <div>Completed successfully - Last verification: Completed successfully on
                        <span>{moment(previousVerificationDate).local().format("ll")}
                        </span> by <span>{previousVerificationDoneBy}</span>.
                        Click <Link style={{ color: "blue", textDecoration: "underline" }} to={"/verifcation/deviceLog/" + lastVerificationLogId}>here</Link>
                    </div>
                )
            }

            if (!isLastVerificationSuccessful) {
                return (
                    <div>Completed with Failure - Last verification: Completed but Failed
                        on <span>{moment(previousVerificationDate).local().format("ll")}
                        </span> by <span>{previousVerificationDoneBy}</span>.
                        Click <Link style={{ color: "blue", textDecoration: "underline" }} to={"/verifcation/deviceLog/" + lastVerificationLogId}>here</Link>
                    </div>
                )
            }
        }
    }

    function showAlert() {
        alert("No logs found.")
    }

    function getNextDueDate(dueDateValue) {
        var getNextDueDate;

        if (dueDateValue == 1) {
            getNextDueDate = moment(verificationDueDate).add(1, 'weeks').local().format("ll");
        } else if (dueDateValue == 2) {
            getNextDueDate = moment(verificationDueDate).add(2, 'weeks').local().format("ll");
        } else if (dueDateValue == 3) {
            getNextDueDate = moment(verificationDueDate).add(1, 'M').local().format("ll");
        } else if (dueDateValue == 4) {
            getNextDueDate = moment(verificationDueDate).add(3, 'M').local().format("ll");
        } else if (dueDateValue == 5) {
            getNextDueDate = moment(verificationDueDate).add(12, 'M').local().format("ll");
        }

        return getNextDueDate;
    }

    function callOnChange() {
        props.onChange({
            user: verificationUser,
            frequency: verificationFrequency,
            dueDate: verificationDueDate,
            // endDate: verificationEndDate,
            receiveNotifications: receiveNotifications,
            isChanged: isChanged,
        });
    }
}
