/* eslint-disable */

import { stringify } from "query-string";
import React, { useState } from "react";
import { Fragment } from "react";
import Guid from "../components/Guid";

export const UserCompany = React.createContext([] as any);
export const CropperContext = React.createContext([] as any);
export const ShowGuidContext = React.createContext([] as any);

export interface showGuidType {
    onClose?: CallableFunction;
    elementId?: string;
    jsx: JSX.Element;
    top: number;
    left: number;
}
const Store = ({ children }) => {
    const [userCompany, setUserCompany] = useState("");
    const [showCropper, setShowCropper] = useState(false);
    const [showGuid, setShowGuid] = useState(null as showGuidType);

    return (
        <CropperContext.Provider value={[showCropper, setShowCropper]}>
            <UserCompany.Provider value={[userCompany, setUserCompany]}>
                <ShowGuidContext.Provider
                    value={[showGuid as showGuidType, setShowGuid]}
                >
                    {guidUI()}
                    {children}
                </ShowGuidContext.Provider>
            </UserCompany.Provider>
        </CropperContext.Provider>
    );

    function guidUI() {
        if (!showGuid || !showGuid.elementId) {
            return <Fragment />;
        }
        return (
            <div>
                <Guid
                    top={showGuid.top + 30}
                    left={showGuid.left}
                    onClose={() => {
                        setShowGuid(null);
                        showGuid.onClose?.();
                    }}
                    element={showGuid.elementId}
                    isShow={true}
                >
                    <div style={{ fontSize: 20 }}>{showGuid.jsx}</div>
                </Guid>
            </div>
        );
    }
};

export default Store;
