import React, { useEffect, useState } from "react";
import BestCalendar from "./BestCalendar";
import AppTooltip from "./AppTooltip";
import { handleErrors } from "../services/HelperMethod";
import ApiService from "../services/ApiService";
import Loading from "./Loading";
import Switch from '@material-ui/core/Switch';
import moment from "moment";
import { Link } from "react-router-dom";
interface MaintenanceUIProps {
    assetId: number;
    onChange: CallableFunction;
}
export default function MaintenanceUI(props: MaintenanceUIProps) {
    const apiService = ApiService();
    const [isLoading, setIsLoading] = useState(false);

    const [maintenanceUser, setMaintenanceUser] = useState("");
    const [maintenanceFrequency, setMaintenanceFrequency] = useState("2");
    const [maintenanceDueDate, setMaintenanceDueDate] = useState(new Date());
    // const [maintenanceEndDate, setMaintenanceEndDate] = useState(new Date());
    const [isChanged, setIsChanged] = useState(false);
    const [users, setUsers] = useState([] as any[]);

    const [receiveNotifications, setReceiveNotifications] = useState(true);

    const [wasPreviousMaintenanceAutoCompleted, setWasPreviousMaintenanceAutoCompleted] = useState();
    const [previousMaintenanceDate, setPreviousMaintenanceDate] = useState(null as any);
    const [nextMaintenanceDate, setNextMaintenanceDate] = useState(null as any);
    const [wasPreviousMaintenanceSuccessful, setWasPreviousMaintenanceSuccessful] = useState();
    const [lastMaintenanceLogId, setLastMaintenanceLogId] = useState(0);
    const [previousMaintenanceDoneBy, setPreviousMaintenanceDoneBy] = useState(null as any);

    useEffect(() => {
        getAsset();
    }, []);

    function showAlert() {
        alert("No logs found.")
    }

    useEffect(() => {
        callOnChange();
    }, [maintenanceUser, maintenanceFrequency, maintenanceDueDate,/*maintenanceEndDate,*/isChanged, receiveNotifications]);

    useEffect(() => {
        setNextMaintenanceDate(getNextDueDate(maintenanceFrequency))
    }, [maintenanceDueDate, maintenanceFrequency]);

    async function getAsset() {
        setIsLoading(true);
        try {
            await apiService.getManagers().then((data: any) => {
                data = data.data;
                setUsers(data);
            });

            if (props.assetId) {
                let result = (await apiService.getMaintenanceById(props.assetId)) as any;

                result = result.data;

                if (result) {
                    setMaintenanceFrequency(result.frequency);
                    setMaintenanceUser(result.assignToId);
                    setMaintenanceDueDate(result.dueDate);
                    // setMaintenanceEndDate(result.endDate);
                    setReceiveNotifications(result.receiveNotifications)

                    let previousMaintenance = await apiService.wasPreviousMaintenanceCompleted(props.assetId);
                    let wasPreviousMaintenanceSuccessful = await apiService.wasPreviousMaintenanceSuccessful(props.assetId);

                    setWasPreviousMaintenanceSuccessful(wasPreviousMaintenanceSuccessful?.data?.result)
                    setLastMaintenanceLogId(wasPreviousMaintenanceSuccessful?.data?.maintenanceLogId);
                    setPreviousMaintenanceDoneBy(previousMaintenance?.data?.assignTo?.firstName + " " + previousMaintenance?.data?.assignTo?.lastName + " - " + previousMaintenance?.data?.assignTo?.username);
                    setWasPreviousMaintenanceAutoCompleted(previousMaintenance?.data?.autoCreated);
                    setPreviousMaintenanceDate(previousMaintenance?.data?.dueDate);
                } else {
                    setMaintenanceFrequency("2");
                    setMaintenanceUser("0");
                    setMaintenanceDueDate(new Date());
                    // setMaintenanceEndDate(new Date());
                    setReceiveNotifications(true)
                }
            }
            else {
                setMaintenanceFrequency("2");
                setMaintenanceUser("0");
                setMaintenanceDueDate(new Date());
                // setMaintenanceEndDate(new Date());
                setReceiveNotifications(true)
            }
        } catch (error) {
            handleErrors(error);
        }
        setIsLoading(false);
    }

    return (
        <form>
            <div className="row" style={{ position: "relative" }}>
                <Loading
                    style={{
                        position: "absolute",
                        top: -24,
                        bottom: -75,
                        left: 4,
                        right: 4,
                    }}
                    isLoading={isLoading}
                />
                <div className="col-sm-12 col-md-6">
                    <div className="form-group mt-1">
                        <label className="ml-3" htmlFor="assign-to">
                            Assign To *
                        </label>
                        <select
                            onChange={(e) => {
                                setMaintenanceUser(e.target.value);
                                setIsChanged(true);
                            }}
                            value={maintenanceUser}
                            className="form-control sign-in-inputs"
                        >
                            <option value="0">Select User</option>
                            {users.map((manager: any) => {
                                return (
                                    <option key={manager.id} value={manager.id}>
                                        {manager.firstName} {manager.lastName} - {manager.username}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                </div>
                <div className="col-sm-12 col-md-6">
                    <div className="form-group mt-1">
                        <label className="ml-3" htmlFor="frequency">
                            Frequency *
                        </label>
                        <select
                            value={maintenanceFrequency}
                            onChange={(e) => {
                                setMaintenanceFrequency(e.target.value);
                                setIsChanged(true);
                            }}
                            className="form-control sign-in-inputs"
                            id="frequency"
                            name="frequency"
                        >

                            <option value="2">Every two weeks</option>
                            <option value="1">Weekly</option>
                            <option value="3">Monthly</option>
                            <option value="4">Quarterly</option>
                            <option value="5">Yearly</option>
                        </select>
                    </div>
                </div>
                <div className="col-sm-12 col-md-6">
                    <div className="form-group mt-1">
                        <AppTooltip
                            className="ml-1"
                            tip="The date set here will represent the date in which the maintenance should be performed"
                        />
                        <label className="ml-2 mt-1" htmlFor="due-date">
                            Current Maintenance Due Date *
                        </label>

                        <BestCalendar
                            minDate={Date.now()}
                            className="form-control sign-in-inputs w-100"
                            selected={maintenanceDueDate}
                            onChange={(e) => {
                                setMaintenanceDueDate(e);
                                setIsChanged(true);
                            }}
                        />
                    </div>
                </div>
                <div className="col-sm-12 col-md-6">
                    <div className="form-group mt-1">
                        {/* <AppTooltip tip="" /> */}
                        <label className="ml-2 mt-1" htmlFor="end-date">
                            Receive Notifications{" "}
                        </label>
                        <Switch
                            checked={receiveNotifications}
                            onChange={() => { setReceiveNotifications(!receiveNotifications); setIsChanged(true) }}
                            color="primary"
                            name="checkedB"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    </div>
                </div>
                {props.assetId ?
                    (<div>
                        <div className="col-sm-12 col-md-6">
                            <div style={{ display: "flex", flexDirection: "row" }} className="form-group mt-1">
                                <label style={{ width: "50rem" }} className="ml-2 mt-1" htmlFor="end-date">
                                    {getLastMaintenanceInfo(wasPreviousMaintenanceAutoCompleted, wasPreviousMaintenanceSuccessful)}
                                </label>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <div style={{ display: "flex", flexDirection: "row" }} className="form-group mt-1">
                                <label style={{ width: "20rem" }} className="ml-2 mt-1" htmlFor="end-date">
                                    Next Maintenance Due Date{" "}
                                </label>
                                <label style={{ width: "20rem" }} className="ml-2 mt-1" htmlFor="end-date">
                                    {moment(nextMaintenanceDate).local().format("ll")}
                                </label>
                            </div>
                        </div>
                    </div>) :
                    (<div></div>)
                }
                {/* <div className="col-sm-12 col-md-6">
                    <div className="form-group mt-1">
                        <AppTooltip tip="End Date will be last date in which a assigned maintenance should be performed, if it’s not met it will be automatically assigned to the next scheduled slot based on the interval assigned" />

                        <label className="ml-2 mt-1" htmlFor="end-date">
                            End Date{" "}
                        </label>
                        <BestCalendar
                            minDate={maintenanceDueDate}
                            className="form-control sign-in-inputs w-100"
                            selected={maintenanceEndDate}
                            onChange={(e) => {
                                setMaintenanceEndDate(e);
                                setIsChanged(true);
                            }}
                        />
                    </div>
                </div> */}
            </div>
        </form>
    );

    function getLastMaintenanceInfo(isLastMaintenaceAutoCompleted, isLastMaintenanceSuccessful) {
        if (isLastMaintenaceAutoCompleted != undefined && isLastMaintenanceSuccessful != undefined) {
            if (isLastMaintenaceAutoCompleted) {
                return (<div>Last maintenance have not been started or completed</div>)
            }

            if (isLastMaintenanceSuccessful) {
                return (
                    <div>Completed successfully - Last Maintenance: Completed successfully on
                        <span>{moment(previousMaintenanceDate).local().format("ll")}
                        </span> by <span>{previousMaintenanceDoneBy}</span>.
                        Click <Link style={{ color: "blue", textDecoration: "underline" }} to={"/maintenance/log/details/" + lastMaintenanceLogId}>here</Link>
                    </div>
                )
            }

            if (!isLastMaintenanceSuccessful) {
                return (
                    <div>Completed with Failure - Last Maintenance: Completed but Failed
                        on <span>{moment(previousMaintenanceDate).local().format("ll")}
                        </span> by <span>{previousMaintenanceDoneBy}</span>.
                        Click <Link style={{ color: "blue", textDecoration: "underline" }} to={"/maintenance/log/details/" + lastMaintenanceLogId}>here</Link>
                    </div>
                )
            }
        }
    }

    function getNextDueDate(dueDateValue) {
        var getNextDueDate;

        if (dueDateValue == 1) {
            getNextDueDate = moment(maintenanceDueDate).add(1, 'weeks').local().format("ll");
        } else if (dueDateValue == 2) {
            getNextDueDate = moment(maintenanceDueDate).add(2, 'weeks').local().format("ll");
        } else if (dueDateValue == 3) {
            getNextDueDate = moment(maintenanceDueDate).add(1, 'M').local().format("ll");
        } else if (dueDateValue == 4) {
            getNextDueDate = moment(maintenanceDueDate).add(3, 'M').local().format("ll");
        } else if (dueDateValue == 5) {
            getNextDueDate = moment(maintenanceDueDate).add(12, 'M').local().format("ll");
        }

        return getNextDueDate;
    }

    function callOnChange() {
        props.onChange({
            user: maintenanceUser,
            frequency: maintenanceFrequency,
            dueDate: maintenanceDueDate,
            // endDate: maintenanceEndDate,
            receiveNotifications: receiveNotifications,
            isChanged: isChanged,
        });
    }
}
