/* eslint-disable */

import React, { useState, useEffect, useContext } from "react";
import "../assets/css/login.scss";
import ApiService from "../services/ApiService";
import { toast } from "react-toastify";
import Loading from "../components/Loading";
import { Link } from "react-router-dom";
import AppButton from "../components/AppButton";
import GoogleLogin from "react-google-login";

import {
    getQueryVariable,
    navigateTo,
    handleRedirect,
    getVersionNumber,
    handleErrors,
    setLocalStorageFromAuth,
} from "../services/HelperMethod";
import { UserCompany } from "../store/StoreProvider";

function Login(props) {
    const apiService = ApiService();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [_, setCompanyName] = useContext(UserCompany);

    const [url, setURL] = useState("");

    useEffect(() => {
        if (getQueryVariable("clear")) {
            localStorage.removeItem("jwt");
            localStorage.removeItem("user");
        }
        handleRedirect(window.location.href);
    }, []);

    function auth() {
        // validation
        if (!username || !password) {
            toast.error("Username & password required");
            return;
        }

        setIsLoading(true);
        apiService.authenticate(username, password, (result) => {
            if (result.isError) {
                toast.error(result.message);
            } else {
                navigateTo(props.history, result.navigateTo);
            }
            setIsLoading(false);
        });
    }

    return (
        <div className="full-layout">
            <div className="container">
                <div className="row">
                    <div className="col-lg"></div>
                    <div className="col-lg">
                        <div className="white-box">
                            <div>
                                <img
                                    className="pcid-logo"
                                    src="./assets/images/pcid_logo.png"
                                    alt="pcid"
                                    width="200"
                                />

                                <form
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                    }}
                                >
                                    <div className="form-group mt-5">
                                        <label>Username</label>
                                        <input
                                            autoFocus
                                            onChange={(e) => {
                                                setUsername(e.target.value);
                                            }}
                                            className="form-control pcid-textbox"
                                            type="text"
                                            name="username"
                                            id="username"
                                            placeholder="Type your Username"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Password</label>
                                        <input
                                            onChange={(e) => {
                                                setPassword(e.target.value);
                                            }}
                                            className="form-control pcid-textbox"
                                            type="password"
                                            name="password"
                                            id="password"
                                            placeholder="Type your Password"
                                        />
                                    </div>
                                    <div className="form-group mt-5">
                                        <AppButton
                                            isLoading={isLoading}
                                            className="btn"
                                            onClick={() => {
                                                auth();
                                            }}
                                        >
                                            LOG IN
                                        </AppButton>
                                    </div>

                                    <div className="form-group mt-2">
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                textTransform: "uppercase",
                                            }}
                                        >
                                            <Link to={"/signup"}>
                                                New User? Register
                                            </Link>

                                            <Link
                                                to="/ResetPassword"
                                                className=""
                                            >
                                                Forgot Password?
                                            </Link>
                                        </div>
                                    </div>
                                </form>
                                <div>
                                    <label className="or">
                                        <span
                                            style={{
                                                backgroundColor: "white",
                                                padding: 30,
                                            }}
                                        >
                                            Or
                                        </span>
                                    </label>
                                    <div
                                        style={{ fontWeight: "bold" }}
                                        className="text-center"
                                    >
                                        <div className="google-btn">
                                            <GoogleLogin
                                                style={{ fontWeight: "bold" }}
                                                clientId="1096371712270-c7mnine8jrb8logt8b8c51frk1h5i9jp.apps.googleusercontent.com"
                                                buttonText="Sign in with Google"
                                                onSuccess={(e: any) => {
                                                    handleGoogleLogin(
                                                        e?.tokenId
                                                    );
                                                }}
                                                onFailure={(e) => {
                                                }}
                                                cookiePolicy={
                                                    "single_host_origin"
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <span className="badge badge-info version">
                {getVersionNumber()}
            </span>
        </div>
    );

    async function handleGoogleLogin(token) {
        try {
            let result = (await apiService.googleLogin({
                idToken: token,
                deviceId: "1",
            })) as any;
            result = result.data;
            if (result.errorType != null) {
                toast.error(result.errorExplanation);
                return;
            }
            navigateTo(props.history, result.navigateTo);
            setLocalStorageFromAuth(result, setCompanyName);
        } catch (error) {
            handleErrors(error);
        }
    }
}

export default Login;

