/* eslint-disable */

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ApiService from "../../services/ApiService";
import DashboardLayout from "../../layouts/DashboardLayout";
import { getImage } from "../../services/HelperMethod";
import moment from "moment";
import { Link } from "react-router-dom";

const VerificationResult = (props) => {
    const { verificationResultId } = useParams() as any;
    const apiService = ApiService();
    const [result, setResult] = useState(null as any);

    useEffect(() => {
        apiService
            .getDeviceLogResult(verificationResultId)
            .then((data: any) => {
                data = data.data;
                
                setResult(data);
            });
    }, []);

    function getAnswerText(answer) {
        if (answer == 1) {
            return (
                <span className="text-success">
                    <i className="fas fa-check"></i> Pass
                </span>
            );
        } else if (answer == 2) {
            return (
                <span className="text-danger">
                    <i className="fas fa-times"></i> Failed
                </span>
            );
        } else if (answer == 3) {
            return (
                <span className="text-warning">
                    <i className="fas fa-question"></i> N/A
                </span>
            );
        }
    }

    function otherImages() {
        let leftImage = <></>;
        if (result?.leftIma1geURL) {
            leftImage = (
                <div className="row result-row">
                    <div className="col-md-6">Image Left</div>
                    <div className="col-md-6">
                        <img
                            src={getImage(result?.leftIma1geURL)}
                            width="300"
                        />
                    </div>
                </div>
            );
        }

        let rightImage = <></>;
        if (result?.rightImageURL) {
            rightImage = (
                <div className="row result-row">
                    <div className="col-md-6">Image Right</div>
                    <div className="col-md-6">
                        <img
                            src={getImage(result?.rightImageURL)}
                            width="300"
                        />
                    </div>
                </div>
            );
        }

        let bottomImage = <></>;
        if (result?.bottomImageURL) {
            bottomImage = (
                <div className="row result-row">
                    <div className="col-md-6">Image Bottom</div>
                    <div className="col-md-6">
                        <img
                            src={getImage(result?.bottomImageURL)}
                            width="300"
                        />
                    </div>
                </div>
            );
        }

        let topImage = <></>;
        if (result?.topImageURL) {
            topImage = (
                <div className="row result-row">
                    <div className="col-md-6">Image Top</div>
                    <div className="col-md-6">
                        <img src={getImage(result?.topImageURL)} width="300" />
                    </div>
                </div>
            );
        }

        return (
            <div>
                {topImage}
                {leftImage}
                {rightImage}
                {bottomImage}
            </div>
        );
    }

    return (
        <DashboardLayout title="Verification Log">
            <div className="mb-3 h4 mb-5">
                <div>Model: {result?.equipment?.model}</div>
                <br/>
                <div>Serial: {result?.equipment?.serial}</div>
            </div>

            <div className="row result-row">
                <div className="col-md-3 font-weight-bold">Does the model number match?</div>
                <div className="col-md-2 ml-3">
                    {result?.isModelMatch ? (
                        <span className="text-success">
                            <i className="fas fa-check"></i> Yes
                        </span>
                    ) : (
                        <span className="text-danger">
                            <i className="fas fa-times"></i> No
                        </span>
                    )}
                </div>
            </div>
            <div className="row result-row">
                <div className="col-md-3 font-weight-bold">Does the serial number match?</div>
                <div className="col-md-2 ml-3">
                    {result?.isSerialMatch ? (
                        <span className="text-success">
                            <i className="fas fa-check"></i> Yes
                        </span>
                    ) : (
                        <span className="text-danger">
                            <i className="fas fa-times"></i> No
                        </span>
                    )}
                </div>
            </div>
            <div className="row result-row">
                <div className="col-md-3 font-weight-bold">Does the barcode match?</div>
                <div className="col-md-2 ml-3">
                    {result?.isAssetMatch ? (
                        <span className="text-success">
                            <i className="fas fa-check"></i> Yes
                        </span>
                    ) : (
                        <span className="text-danger">
                            <i className="fas fa-times"></i> No
                        </span>
                    )}
                </div>
            </div>
            <div className="row result-row">
                <div className="col-md-3 font-weight-bold">Image Front</div>
                <div className="col-md-7" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <div style={{ margin: "0.5rem" }}>
                        <div>Baseline Image</div>
                        <a href={getImage(result?.equipment.frontImageURL)} target="_blank">
                            <img src={getImage(result?.equipment.frontImageURL)} width="300" />
                        </a>
                    </div>
                    <div style={{ margin: "0.5rem" }}>
                        <div>Verification Log Image</div>
                        <a href={result?.frontImageURL} target="_blank">
                            <img src={result?.frontImageURL} width="300" />
                        </a>
                    </div>
                </div>
            </div>
            <div className="row result-row">
                <div className="col-md-3 font-weight-bold">Image Upper Left</div>
                <div className="col-md-7" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <div style={{ margin: "0.5rem" }}>
                        <div>Baseline Image</div>
                        <a href={getImage(result?.equipment.leftImageURL)} target="_blank">
                            <img src={getImage(result?.equipment.leftImageURL)} width="300" />
                        </a>
                    </div>
                    <div style={{ margin: "0.5rem" }}>
                        <div>Verification Log Image</div>
                        <a href={result?.leftImageURL} target="_blank">
                            <img src={result?.leftImageURL} width="300" />
                        </a>
                    </div>
                </div>
            </div>
            <div className="row result-row">
                <div className="col-md-3 font-weight-bold">Image Lower Right</div>
                <div className="col-md-7" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <div style={{ margin: "0.5rem" }}>
                        <div>Baseline Image</div>
                        <a href={getImage(result?.equipment.rightImageURL)} target="_blank">
                            <img src={getImage(result?.equipment.rightImageURL)} width="300" />
                        </a>
                    </div>
                    <div style={{ margin: "0.5rem" }}>
                        <div>Verification Log Image</div>
                        <a href={result?.rightImageURL} target="_blank">
                            <img src={result?.rightImageURL} width="300" />
                        </a>
                    </div>
                </div>
            </div>
            <div className="row result-row">
                <div className="col-md-3 font-weight-bold">Image Back</div>
                <div className="col-md-7" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <div style={{ margin: "0.5rem" }}>
                        <div>Baseline Image</div>
                        <a href={getImage(result?.equipment.backImageURL)} target="_blank">
                            <img src={getImage(result?.equipment.backImageURL)} width="300" />
                        </a>
                    </div>
                    <div style={{ margin: "0.5rem" }}>
                        <div>Verification Log Image</div>
                        <a href={result?.backImageURL} target="_blank">
                            <img src={result?.backImageURL} width="300" />
                        </a>
                    </div>
                </div>
            </div>
            {/* {otherImages()} */}
            <h5 className="mt-4 mb-3">Questions:</h5>
            <div>
                {result?.verificationAnswers.map((answer) => {
                    return (
                        <div key={answer.id} className="row result-row">
                            <div className="col-md-3 font-weight-bold">
                                {answer.question?.question}
                            </div>
                            <div className="col-md-2">
                                {getAnswerText(answer.answer)}
                            </div>
                            <div className="col-md-2">
                                {answer.failedReason}
                            </div>
                        </div>
                    );
                })}

                <h5 className="mt-4 mb-3">Additional:</h5>
                <div className="row result-row">
                    <label className="font-weight-bold">
                        Submit by: {result?.user?.firstName}{" "}
                        {result?.user?.lastName}
                    </label>
                </div>
                <div className="row result-row font-weight-bold">
                    <label>
                        Date:{" "}
                        {moment(result?.verification.completedDate).local().format("ll")}
                    </label>
                </div>

                <div className="text-right">
                    <button
                        onClick={() => {
                            props.history.goBack();
                        }}
                        className="btn btn-secondary btn-pcid-cancel mt-3"
                    >
                        Back
                    </button>
                </div>
            </div>
        </DashboardLayout>
    );
};

export default VerificationResult;