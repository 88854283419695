/* eslint-disable */

import React, { useState, useEffect, Fragment } from "react";
import DashboardLayout from "../../layouts/DashboardLayout";
import Modal from "react-modal";
import { Dropdown } from "react-bootstrap";
import ApiService from "../../services/ApiService";
import {
    OptionsDropDown,
    frequencyText,
    getImage,
    tableColumns,
    getColumnSearch,
    dateDiff,
} from "../../services/HelperMethod";
import Loading from "../../components/Loading";
var moment = require("moment");
import { Link } from "react-router-dom";

const Verification = (props) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [verifications, setVerifications] = useState([]);
    const [verificationDonut, setVerificationDonut] = useState({} as any);
    const [verificationInfo, setVerificationInfo] = useState({} as any);
    const [originalVerifications, setOriginalVerifications] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [filters, setFilters] = useState([] as any);

    const apiService = ApiService();
    useEffect(() => {
        getVerifications();
    }, []);

    function getVerifications() {
        apiService
        .getDashboardVerifications()
        .then((data: any) => {
            data = data.data;

            let info = getCount(data);

            setVerifications(data);
            setVerificationInfo(info);

            setVerificationDonut({
                labels: ["Due today", "Due Soon", "Late", "Failed", "Done"],
                datasets: [
                    {
                        label: "# of Votes",
                        data: [info.today, info.soon, info.late, info.failed, info.done],
                        backgroundColor: ["#ff8931", "#ffb93c", "#ff485e", "#c43957", "#00be9c"],
                        borderColor: ["#ff8931", "#ffb93c", "#ff485e", "#c43957", "#00be9c"],
                        borderWidth: 1,
                    },
                ],
                onHover: (event, chartElement) => {
                    event.target.style.cursor = chartElement[0]
                        ? "pointer"
                        : "default";
                },
                legend: {
                    display: false
                },
            });

            setFilters([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])

            setIsLoading(false);
        })
        .catch((err) => {
            setIsLoading(false);
        });
    }

    function getCount(items) {
        let dueDatesInfo = {
            today: 0,
            soon: 0,
            done: 0,
            failed: 0,
            late: 0,
        };

        items.forEach((v) => {
            console.log(v);
            var dueDate = moment(v.dueDate).startOf('day');
            var today = moment().startOf('day');

            var momentDiff = moment.duration(Math.abs(today.diff(dueDate)));
            var difference = Math.floor(momentDiff.asDays())
            console.log(difference);
            if (difference == 0) {
                dueDatesInfo.today += 1
            } else if (today > dueDate){
                dueDatesInfo.late += 1
            } else if (difference < 30) {
                dueDatesInfo.soon += 1
            }  else if (v.isComplete) {
                dueDatesInfo.done += 1;
            }
            
        });

        return dueDatesInfo;
    }

    function filterStatus(item){
        var dueDate = moment(item.dueDate).startOf('day');
        var today = moment().startOf('day');

        var momentDiff = moment.duration(Math.abs(today.diff(dueDate)));
        var difference = Math.floor(momentDiff.asDays())

        let classNames = "status";
        var text;

            if (difference == 0 && filters[0] == 1) {
                return false;
            } else if (today > dueDate && filters[3] == 1){
                return false;
            } else if (difference < 15 && filters[1] == 1 && today < dueDate && difference != 0) {
                return false;
            }  else if (item.isComplete && filters[2] == 1) {
                return false;
            } else{
                return true;
            }
    }

    function status(date, item){
        var dueDate = moment(item.dueDate).startOf('day');
        var today = moment().startOf('day');

        var momentDiff = moment.duration(Math.abs(today.diff(dueDate)));
        var difference = Math.floor(momentDiff.asDays())

        let classNames = "status";
        var text;

            if (difference == 0) {
                classNames += " today";
                text = "Today"
            } else if (today > dueDate){
                classNames += " late";
                text = "Late";
            } else if (difference < 30) {
                classNames += " soon"
                text = "Soon"
            }  else if (item.isComplete) {
                classNames += " done"
                text = "Done"
            }
    
        return ([
            <span className={classNames}/>,
            <span>
                {text}
            </span>
        ]);
    }

    function dateDifference(verification){
        var now = moment(new Date()).startOf('day');
        var diffNumber = moment(verification.dueDate).diff(now);
        
        if(diffNumber<0){
            return "-" + (moment(moment().startOf('day')).diff(verification.dueDate, 'days') + 1);
        }
        else{
            return moment(moment().startOf('day')).diff(verification.dueDate, 'days') + 1;
        }
    }

    return (
        <DashboardLayout title="Verifications">
            <Loading isLoading={isLoading} />

            <React.Fragment>
                <Loading isLoading={isLoading} />
                <div className="Inspection">
                <table className="InspectionTable">
                                        <thead className="InspectionGridTitles">
                                            <tr>
                                                {tableColumns("Status", "string", verifications, setVerifications)}
                                                {tableColumns("Stores", "string", verifications, setVerifications)}
                                                {tableColumns("Lanes", "string", verifications, setVerifications)}
                                                {tableColumns("Model Number", "string", verifications, setVerifications)}
                                                {tableColumns("Serial Number", "string", verifications, setVerifications)}
                                                {tableColumns("Due Date", "DateTime", verifications, setVerifications)}
                                                {tableColumns("Assigned To", "string", verifications, setVerifications)}  
                                            </tr>
                                    
                                        </thead>

                                        <tbody className="InspectionGrid">
                                        { 
                                        verifications.map((verification) => {
                                            if(filterStatus(verification)){
                                                return ([
                                                    <tr>
                                                        <th className="inspectionStatus">{status(moment(verification.dueDate).local(), verification)}</th>
                                                        <td><Link to={"/editStore/" + verification.lane?.store?.id}>{verification.lane?.store?.name}</Link></td>
                                                        <td>{verification.lane?.name}</td>
                                                        <td><Link to={"/editEquipment/" + verification.lane?.pciEquipments?.map((device) => {return(device.id);})}>{verification.lane?.pciEquipments?.map((device) => {return (device.model);})}</Link></td>
                                                        <td>{verification.lane?.pciEquipments?.map((device) => {return (device.serial);})}</td>
                                                        <td>{moment(verification.dueDate).local().format("MM/DD/YYYY")}</td>
                                                        <td><Link to={"/editUser/" + verification?.assignTo?.id}> {verification?.assignTo?.firstName}{" "} {verification?.assignTo?.lastName} </Link></td>
                                                    </tr>
                                                ]);
                                            }
                                        })
                                        }
                                        </tbody>
                </table>
                </div>
            </React.Fragment>
        </DashboardLayout>
    );
};

export default Verification;
