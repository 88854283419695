/* eslint-disable */

import React, { useState, useEffect } from "react";
import { toBase64, getImage, checkClaim } from "../../services/HelperMethod";
import ApiService from "../../services/ApiService";
import DashboardLayout from "../../layouts/DashboardLayout";
import TextBox from "../../components/TextBox";
import AppDropdown from "../../components/AppDropdown";
import Loading from "../../components/Loading";
import { useParams } from "react-router-dom";
import FileButton from "../../components/FileButton";
import AppModal from "../../components/AppModal";
import Modall from "../../components/Modal";
import Map from "../../components/Map";
import Modal from "react-modal";
import AppTooltip from "../../components/AppTooltip";
import { toast } from "react-toastify";

const AddLane = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const apiService = ApiService();
    const { laneId, selectedStoreId } = useParams() as any;
    const [title, setTitle] = useState("Add Lane");

    const [stores, setStores] = useState([]);
    // form
    const [laneName, setLaneName] = useState("");
    const [laneNumber, setLaneNumber] = useState("");
    const [storeId, setStoreId] = useState(0);
    const [laneImage, setLaneImage] = useState(null as any);
    const [lat, setLat] = useState(null as any);
    const [long, setLong] = useState(null as any);

    // form validation
    const [laneNameError, setLaneNameError] = useState("");
    const [laneNumberError, setLaneNumberError] = useState("");
    const [storeIdError, setStoreIdError] = useState("");
    const [latError, setLatError] = useState(null as any);
    const [longError, setLongError] = useState(null as any);

    const [modalDeleteIsOpen, setModalDeleteIsOpen] = useState(false);

    const [markers, setMarkers] = useState([] as any);

    useEffect(() => {
        if (selectedStoreId) {
            setStoreId(selectedStoreId);
        }

        apiService.getStores().then((data: any) => {
            data = data.data;
            setStores(data);
        });
        checkEditLane();
    }, []);

    useEffect(() => {
        var selectedMarkers = []

        var position = { lat: lat, lng: long }

        selectedMarkers.push(position)

        setMarkers(selectedMarkers);
    }, [lat, long])

    function checkEditLane() {
        if (laneId) {
            setTitle("Edit Lane");

            setIsLoading(true);
            apiService
                .getLaneById(laneId)
                .then((data: any) => {
                    data = data.data;
                    setIsLoading(false);
                    setLaneName(data.name);
                    setLaneNumber(data.number);
                    setStoreId(data.storeId);
                    setLaneImage(getImage(data.imageURL));
                    setLat(data.lat);
                    setLong(data.long);
                })
                .catch((err) => {
                    // handle
                });
        }
    }

    function saveLane(isValidate = true) {
        if (!laneImage && isValidate) {
            setModalIsOpen(true);
            return;
        }

        let data = {
            id: laneId,
            name: laneName,
            storeId: storeId,
            number: laneNumber,
            long: long,
            lat: lat,
            image: laneImage,
        };

        setIsLoading(true);
        setModalIsOpen(false);
        apiService
            .saveLane(data)
            .then((data) => {
                data = data.data;
                setIsLoading(false);
                props.history.goBack("/lanes");
            })
            .catch((error) => {
                handleFormErrors(error?.response?.data?.errors);
            });
    }

    function handleFormErrors(err) {
        setLaneNameError(err?.Name?.[0]);
        setLaneNumberError(err?.Number?.[0]);
        setStoreIdError(err?.StoreId?.[0]);

        setLatError(err?.Lat?.[0]);
        setLongError(err?.Long?.[0]);

        setIsLoading(false);
        setModalIsOpen(false);
        toast.error("Please fill all the required fields");
    }

    function setLatLong(selectedLat?, selectedLong?) {
        if (!(lat == selectedLat)) {
            setLat(selectedLat)
        }

        if (!(long == selectedLong)) {
            setLong(selectedLong)
        }
    }

    return (
        <DashboardLayout title={title}>
            <Loading isLoading={isLoading} />
            <AppModal
                onClick={() => {
                    saveLane(false);
                }}
                onClose={() => {
                    setModalIsOpen(false);
                }}
                isOpen={modalIsOpen}
            />

            <React.Fragment>
                <div className="card">
                    <div className="card-header">
                        <div className="row">
                            <div className="col-sm-4" />
                            <div className="col-sm-4">
                                <div className="pro-pic">
                                    <FileButton
                                        onChange={(file) => {
                                            setLaneImage(file);
                                        }}
                                        image={laneImage}
                                        getLatLong={(lat, long) => {
                                            setLat(lat);
                                            setLong(long);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-4" />
                        </div>
                    </div>
                    <div className="card-body">
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                saveLane();
                            }}
                            className="row"
                        >
                            <div className="col-sm-12 col-md-6">
                                <div className="form-group mt-1">
                                    <label className="ml-3" htmlFor="store">
                                        Store *
                                    </label>

                                    <AppDropdown
                                        autoFocus={true}
                                        value={storeId.toString()}
                                        onChange={(val) => {
                                            setStoreId(parseInt(val));
                                        }}
                                        error={storeIdError}
                                    >
                                        <option value="0">
                                            Select a Store
                                        </option>
                                        {stores.map((store: any) => {
                                            return (
                                                <option
                                                    key={store.id}
                                                    value={store.id}
                                                >
                                                    {store.name}
                                                </option>
                                            );
                                        })}
                                    </AppDropdown>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <div className="form-group mt-1">
                                    <label className="ml-3" htmlFor="lane-name">
                                        Lane name *
                                    </label>
                                    <TextBox
                                        type="text"
                                        className="form-control sign-in-inputs"
                                        onChange={(val) => setLaneName(val)}
                                        value={laneName}
                                        error={laneNameError}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <div className="form-group mt-1">
                                    <label
                                        className="ml-3"
                                        htmlFor="lane-number"
                                    >
                                        Lane Number *
                                    </label>
                                    <TextBox
                                        min={0}
                                        type="number"
                                        className="form-control sign-in-inputs"
                                        onChange={(val) => setLaneNumber(val)}
                                        value={laneNumber}
                                        error={laneNumberError}
                                    />
                                </div>
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <div className="form-group mt-1">
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <label className="ml-3" htmlFor="lane-number">Lat/Long</label>
                                        <Modall buttonText={"Open Map"}
                                            body={
                                                <div style={{ display: "flex", flexDirection: "column" }}>
                                                    <div style={{
                                                        display: "flex", alignContent: "stretch",
                                                        justifyContent: "flex-end", padding: "0.2rem 0.5rem 0 0",
                                                        maxHeight: "2.3rem"
                                                    }}>
                                                        <AppTooltip className="ml-1"
                                                            tip="Click outside the map to close"
                                                        />
                                                    </div>
                                                    <Map loadingElement={<div style={{ height: `100%` }} />}
                                                        containerElement={<div style={{ height: `32rem` }} />}
                                                        mapElement={<div style={{ height: `100%` }} />}
                                                        googleMapURL="https://maps.googleapis.com/maps/api/js?key=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"
                                                        setLatLong={setLatLong}
                                                        markers={markers} />
                                                </div>
                                            }
                                        />
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <TextBox
                                                type="number"
                                                className="form-control sign-in-inputs"
                                                onChange={(val) => setLat(val)}
                                                value={lat}
                                                error={latError}
                                            />
                                        </div>
                                        <div className="col-lg-6">
                                            <TextBox
                                                type="number"
                                                className="form-control sign-in-inputs"
                                                onChange={(val) => setLong(val)}
                                                value={long}
                                                error={longError}
                                            />
                                        </div>
                                    </div>
                                    <div className="badge">
                                        (Lat/Long fields are auto-fill if you
                                        upload an image with location
                                        information)
                                    </div>
                                    {/* { <div className="badge">
                                        <div>
                                            <a
                                                target="_blank"
                                                href="https://maps.google.com/"
                                            >
                                                https://maps.google.com/
                                            </a>
                                            &nbsp; (right click anywhere on the
                                            map to copy current geo-coordinates)
                                        </div>
                                    </div> } */}
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="row justify-content-end">
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col">
                                                {checkClaim("admin") && laneId
                                                    ? (<div className="form-group mt-1">
                                                        <button className="btn btn-secondary btn-block btn-pcid-cancel mt-3"
                                                            onClick={(e) => { setModalDeleteIsOpen(true); e.preventDefault(); }}>
                                                            Remove Lane
                                                        </button>
                                                    </div>)
                                                    : (<div></div>)
                                                }
                                            </div>
                                            <div className="col">
                                                <div className="form-group mt-1">
                                                    <button
                                                        className="btn btn-secondary btn-block btn-pcid-cancel mt-3"
                                                        type="button"
                                                        onClick={() => {
                                                            props.history.goBack(
                                                                "/lanes"
                                                            );
                                                        }}
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="form-group mt-1">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-success btn-block btn-pcid mt-3"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            saveLane();
                                                        }}
                                                    >
                                                        Save
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                {confirmDelete()}
            </React.Fragment>
        </DashboardLayout>
    );

    function deleteLane(deleteLane = false) {
        if (laneId && deleteLane) {

            setIsLoading(true);

            var data = {
                laneId: laneId
            }

            apiService.deleteLane(data).then((data: any) => {
                data = data.data;

                setIsLoading(false);

                props.history.push("/lanes");
            }).catch((error) => {
                alert("Unable to delete selected.")
                setIsLoading(false);
            });
        } else {
            alert("Unable to delete selected.")
            setIsLoading(false);
        }
    }

    function confirmDelete() {
        return (
            <Modal isOpen={modalDeleteIsOpen} ariaHideApp={false}
                onRequestClose={() => { setModalDeleteIsOpen(false); }} className="modal">
                <div className="row">
                    <div className="card text-center" style={{ width: "350px", height: "180px" }}>
                        <div className="card-body align-items-center d-flex justify-content-center">
                            Are you sure you want to delete this?
                        </div>
                        <div className="card-footer text-muted p-4">
                            <input className="btn btn-secondary btn-pcid-cancel" type="button"
                                value="Continue" onClick={() => { deleteLane(true); }}
                            />
                            <input className="btn btn-success btn-pcid ml-3" type="button"
                                value="Go Back"
                                onClick={() => { setModalDeleteIsOpen(false); }}
                            />
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
};

export default AddLane;
