import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import FileButton from "../../components/FileButton";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import AppModal from "../../components/AppModal";
import ApiService from "../../services/ApiService";
import Loading from "../../components/Loading";
import Steps from "../../components/Steps";

function getArray(arrayLength) {
    var numberOfLanesArray = [];
    var length = arrayLength;

    for (var i = 0; i < length; i++) {
        numberOfLanesArray.push(i);
    }

    return numberOfLanesArray;
}

function SetupLanes() {
    const history = useHistory();

    const { laneId } = useParams() as any;

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [storeId, setStoreId] = useState("");
    const [storeName, setStoreName] = useState("");
    const [numberOfLanes, setNumberOfLanes] = useState(0);
    const [storeImage, setStoreImage] = useState(null as any);
    const [laneName, setLaneName] = useState(null as any);

    const [store, setStore] = useState([]);

    const apiService = ApiService();

    useEffect(() => {
        function init() {
            var initArray = [];

            setStoreImage(initArray);
            setLaneName(initArray);
            setIsLoading(true);

            apiService.getStores().then((data: any) => {
                data = data.data;

                setStore(data);
                setIsLoading(false);
            });
        }

        init()
    }, []);

    useEffect(() => {
        function setStoreIdFunction() {
            if (store.length != 0) {
                const storeLength = store.length;

                const lastElement = store[storeLength - 1];

                setStoreId(lastElement?.id);
                setStoreName(lastElement.name);
            }
        }

        setStoreIdFunction()
    }, [store]);

    let setLanes = (e, index) => {

        const arrayIndex = index;
        const enteredLaneName = e.target.value;
        const laneNumber = index + 1;

        var laneObj = {
            "arrayIndex": arrayIndex,
            "laneName": enteredLaneName,
            "laneNumber": laneNumber
        }

        var existingLaneObjIndex = null;

        if (laneName.length === 0) {
            var temp = [];

            temp.push(laneObj);

            return setLaneName(temp);
        }

        laneName.map((element) => {
            if (element.arrayIndex === laneObj.arrayIndex) {
                return existingLaneObjIndex = element.arrayIndex;
            } else {
                return existingLaneObjIndex = null;
            }
        })

        if (existingLaneObjIndex != null) {
            var ifTempArr = laneName.map((element) => {
                if (element.arrayIndex === existingLaneObjIndex) {
                    return laneObj;
                } else {
                    return element;
                }
            })

            setLaneName(ifTempArr);
        } else {
            var elseTempArr: any = [];

            elseTempArr = laneName.map((element) => {
                return element;
            })

            elseTempArr.push(laneObj)

            setLaneName(elseTempArr);
        }
    }

    let setImage = (file, index) => {
        const imageIndex = index;
        const image = file;

        var imageObj = {
            "arrayIndex": imageIndex,
            "image": image
        }

        var existingImageIndex = null;

        if (storeImage.length === 0) {
            var temp = [];

            temp.push(imageObj);

            return setStoreImage(temp);
        }

        storeImage.map((element) => {
            if (element.arrayIndex === imageObj.arrayIndex) {
                return existingImageIndex = element.arrayIndex;
            } else {
                return existingImageIndex = null;
            }
        })

        if (existingImageIndex != null) {
            var ifTempArr = storeImage.map((element) => {
                if (element.arrayIndex === existingImageIndex) {
                    return imageObj;
                } else {
                    return element;
                }
            })

            setStoreImage(ifTempArr);
        } else {
            var elseTempArr: any = [];

            elseTempArr = storeImage.map((element) => {
                return element;
            })

            elseTempArr.push(imageObj)

            setStoreImage(elseTempArr);
        }
    }

    function getImage(imageIndex) {

        var imageElement = null;

        if (storeImage.length !== 0) {
            storeImage.forEach((element) => {
                if (element.arrayIndex === imageIndex) {
                    imageElement = element.image;
                }
            })
        }

        return imageElement
    }

    function continueTo() {
        history.push("/done")
    }

    async function saveLanes() {
        const laneData = laneName;
        const imageData = storeImage;

        setIsLoading(true);

        if (numberOfLanes === 0) {
            setIsLoading(false);
            setModalIsOpen(true);
        }

        if (laneData.length == numberOfLanes) {
            laneData.forEach((laneObj) => {

                const foundLaneImage = imageData.find(findElement => findElement.arrayIndex == laneObj.arrayIndex);

                var laneObject = {
                    id: laneId,
                    name: laneObj.laneName,
                    storeId: storeId,
                    number: laneObj.laneNumber,
                    long: null,
                    lat: null,
                    image: foundLaneImage?.image || null,
                }

                apiService.saveLane(laneObject).then((data) => {
                    data = data.data;

                    setTimeout(function () { history.push({ pathname: "/setup-equipment" }) }, 2000);
                }).catch((error) => {
                    setIsLoading(false);
                });
            })
        } else {
            setIsLoading(false);
            toast.error("Please fill all required fields");
        }
    }

    function redirectToDashboard() {
        history.push("/dashboard")
    }

    return (
        <React.Fragment>
            <Loading isLoading={isLoading} />
            <AppModal message={"Are you sure you want to continue with 0 lanes?"} onClick={() => { continueTo() }} onClose={() => { setModalIsOpen(false); }} isOpen={modalIsOpen} />

            <div className="full-layout">
                <div style={{ marginBottom: "3rem" }}>
                    <div>
                        <button className="btn-redirect" onClick={(e) => { e.preventDefault(); redirectToDashboard(); }}>Skip</button>
                    </div>
                </div>
                <div className="container">
                    <div style={{ width: "100%" }} className="white-box">
                        <div>
                            <div style={{ marginBottom: "1.5rem" }} className="row">
                                <div className="col-sm-12 col-md-5">
                                    <h2 style={{ marginTop: "0.7rem" }} className="mb-3 topic-text ht" id={"lane-profile"}>'{storeName}' Lanes</h2>
                                </div>
                                <div className="col-sm-12 col-md-7">
                                    <Steps iteration={4} activeIndex={1} />
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div style={{ display: "flex", flexWrap: "wrap" }} className="pl-3 general-info">How many lanes does <span style={{ fontWeight: "bold", margin: "0 0.3rem" }}>{storeName}</span> have?</div>
                                    <form>
                                        <div className="row">
                                            <div className="col-sm-4 col-md-2">
                                            </div>
                                            <div className="col-sm-4 col-md-10 lane-center">
                                                <div className="form-group mt-1">
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        {numberOfLanes != 0 ?
                                                            <i style={{ cursor: "pointer" }} onClick={() => { setNumberOfLanes(numberOfLanes - 1) }} className="fas fa-caret-left fa-2x"></i> :
                                                            <i className="fas fa-caret-left fa-2x"></i>}
                                                        <div style={{ fontWeight: "bold", fontSize: "3.5rem", margin: "0 1rem 0 1rem" }}>{numberOfLanes}</div>
                                                        <i style={{ cursor: "pointer" }} onClick={() => { setNumberOfLanes(numberOfLanes + 1) }} className="fas fa-caret-right fa-2x"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            {getArray(numberOfLanes).map((i, index) => {
                                                return <div key={i} style={{ margin: "1rem 0" }} className="col-sm-12 col-md-4">
                                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                            <FileButton cropperStyles={{ top: "-120px" }} onChange={(file) => { if (file) { setImage(file, index); } }} image={getImage(index)} styles={{ width: "4.5rem", height: "4.5rem" }} />
                                                            <label style={{ fontSize: "0.6rem", color: "#3c19fb" }} htmlFor="lane-image">Choose Image</label>
                                                        </div>
                                                        <div style={{ margin: "0 1rem" }}>
                                                            <div className="form-group mt-1">
                                                                <label className="ml-3" htmlFor="lane-name">Lane {index + 1} Name *</label>
                                                                <input placeholder={`Lane ${index + 1} Name`} type="text" required onInputCapture={(e) => { setLanes(e, index); }} className="form-control sign-in-inputs" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            })}
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                            <div style={{ width: 220, }}>
                                                <button className="btn" onClick={(e) => { e.preventDefault(); saveLanes() }}>Next</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default SetupLanes;
