import React from "react";
import Select from "react-select";

interface AppDropdownProps {
    onChange: CallableFunction;
    children?: React.ReactNode;
    error?: string;
    value?: string;
    autoFocus?: boolean;
    items?: any;
}

const AppDropdown = (props: AppDropdownProps) => {
    var opts = {};
    if (props.autoFocus) {
        opts["autoFocus"] = true;
    }
    
    return (
        <div>
            {props.children ? (
                <select
                    
                    {...opts}
                    value={props.value}
                    onChange={e => {
                        props.onChange(e.target.value);
                    }}
                    className="form-control sign-in-inputs"
                >
                    {props.children}
                </select>
            ) : (
                <Select
                    value={{ value: props.value, label: props.value }}
                    options={props.items}
                    onChange={e => {
                        props.onChange(e.value);
                    }}
                />
            )}

            {getError()}
        </div>
    );

    function getError() {
        if (props.error) {
            return <span className="badge text-danger">{props.error}</span>;
        }
    }
};

export default AppDropdown;
