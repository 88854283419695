/* eslint-disable */

import React, { useEffect, useState } from "react";
import ApiService from "../../services/ApiService";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import DashboardLayout from "../../layouts/DashboardLayout";
import Loading from "../../components/Loading";
import moment from "moment";
import VerificationUI from "../../components/VerificationUI";
import { handleErrors } from "../../services/HelperMethod";
import { createTrue } from "typescript";

const AddVerification = (props: any) => {
    const [isLoading, setIsLoading] = useState(false);

    //form
    const [verificationUser, setVerificationUser] = useState("");
    const [verificationFrequency, setVerificationFrequency] = useState("");
    const [verificationDueDate, setVerificationDueDate] = useState(new Date());
    // const [verificationEndDate, setVerificationEndDate] = useState(new Date());
    const [receiveNotification, setReceiveNotification] = useState(true);
    const apiService = ApiService();

    const { laneId } = useParams() as any;

    useEffect(() => {}, []);

    function createVerification() {
        let data = {
            assignToUserId: verificationUser,
            LaneId: laneId,
            DueDate: verificationDueDate,
            // EndDate: verificationEndDate,
            Frequency: verificationFrequency,
            ReceiveNotification:receiveNotification
        };

        setIsLoading(true);
        apiService
            .createVerification(data)
            .then((data: any) => {
                setIsLoading(false);
                props.history.push("/verification");
            })
            .catch((err) => {
                setIsLoading(false);
                handleErrors(err);
            });
    }

    return (
        <DashboardLayout title="Verifications">
            <Loading isLoading={isLoading} />
            <React.Fragment>
                <div className="card">
                    <div className="card-header">
                        <p className="mb-5"></p>
                    </div>
                    <div className="card-body mb-5 mt-3">
                        <VerificationUI
                            laneId={laneId}
                            onChange={(data) => {
                                setVerificationUser(data.user);
                                setVerificationFrequency(data.frequency);
                                setVerificationDueDate(data.dueDate);
                                setReceiveNotification(data.receiveNotification);
                            }}
                        />
                        <div className="row mt-4 justify-content-end">
                            <div className="col-md-3">
                                <input
                                    className="btn btn-secondary btn-block btn-pcid-cancel mt-3"
                                    type="button"
                                    value="Back"
                                    onClick={() => {
                                        props.history.push("/verification");
                                    }}
                                />
                            </div>
                            <div className="col-md-3">
                                <input
                                    className="btn btn-success btn-block btn-pcid mt-3"
                                    type="button"
                                    value="Save"
                                    onClick={() => {
                                        createVerification();
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        </DashboardLayout>
    );
};

export default AddVerification;
