/* eslint-disable */

import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import DashboardLayout from "../../layouts/DashboardLayout";
import ApiService from "../../services/ApiService";
import TextBox from "../../components/TextBox";
import FileButton from "../../components/FileButton";
import moment from "moment";
import AppDropdown from "../../components/AppDropdown";
import { useParams } from "react-router-dom";
import Loading from "../../components/Loading";
import { checkClaim, getImage, isURL } from "../../services/HelperMethod";
import { toast } from "react-toastify";
import BestCalendar from "../../components/BestCalendar";
import MaintenanceUI from "../../components/MaintenanceUI";
import { ToastHeader } from "react-bootstrap";

const AddAsset = (props: any) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const apiService = ApiService();
  const [departments, setDepartments] = useState([]);
  const [title, setTitle] = useState("Create New Asset");
  const [isLoading, setIsLoading] = useState(false);
  const { assetId } = useParams();

  // form
  const [assetType, setAssetType] = useState(0);
  const [departmentId, setDepartmentId] = useState(0);
  const [serialNumber, setSerialNumber] = useState("");
  const [modelNumber, setModelNumber] = useState("");
  const [description, setDescription] = useState("");
  const [barcodeNumber, setBarcodeNumber] = useState("");
  const [initialCost, setInitialCost] = useState("" as any);
  const [replacementCost, setReplacementCost] = useState("" as any);
  const [datePlaced, setDatePlaced] = useState(new Date() as any);
  const [note, setNote] = useState("");

  // validation
  const [assetTypeError, setAssetTypeError] = useState("");
  const [departmentIdError, setDepartmentIdError] = useState("");
  const [serialNumberError, setSerialNumberError] = useState("");
  const [modelNumberError, setModelNumberError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [initialCostError, setInitialCostError] = useState("");
  const [replacementCostError, setReplacementCostError] = useState("");
  const [assetImage, setAssetImage] = useState("");

  const [additionalImages, setAdditionalImages] = useState([] as string[]);
  const [additionalAttachments, setAdditionalAttachments] = useState([] as string[]);

  const [datePlacedError, setDatePlacedError] = useState("");
  const [noteError, setNoteError] = useState("");

  // Maintenance form
  const [maintenanceUser, setMaintenanceUser] = useState("");
  const [maintenanceFrequency, setMaintenanceFrequency] = useState("");
  const [maintenanceDueDate, setMaintenanceDueDate] = useState(new Date());
  // const [maintenanceEndDate, setMaintenanceEndDate] = useState(new Date());
  const [isChanged, setIsChanged] = useState(false);
  const [receiveNotifications, setReceiveNotifications] = useState(true);

  const [modalDeleteIsOpen, setModalDeleteIsOpen] = useState(false);

  const [storeIdError, setStoreIdError] = useState("");
  const [barcodeNumberError, setBarcodeNumberError] = useState("");

  const [stores, setStores] = useState([]);
  const [storeId, setStoreId] = useState(0);

  useEffect(() => {
    apiService.getStores().then((data: any) => {
      data = data.data;
      setStores(data);
    });

    getEditAsset();
  }, []);

  useEffect(() => {
    apiService.getDepartmentsByStoreId(storeId).then((data: any) => {
      data = data.data;
      setDepartments(data);
    });
  }, [storeId]);

  function getEditAsset() {
    if (!assetId) {
      return;
    }
    setTitle("Edit Asset");
    setIsLoading(true);
    apiService.getAsset(assetId).then((data: any) => {
      data = data.data;

      setStoreId(data?.storesId)
      setDepartmentId(data?.departmentId);
      setSerialNumber(data?.serialNumber);
      setModelNumber(data?.modelNumber);
      setInitialCost(data?.initialCost);
      setReplacementCost(data?.replacementCost);
      setDatePlaced(data?.date);
      setAssetImage(getImage(data?.imageURL));
      // setStoreId(parseInt(data?.storeId));
      setBarcodeNumber(data?.barcode)
      setNote(data?.note);
      setDescription(data?.description);
      setAssetType(data?.assetType);
      setDepartmentId(data?.departmentId);

      var arrAdditionalImages = data?.additionalImages?.split(",");
      arrAdditionalImages = arrAdditionalImages?.filter((item) => {
        return item;
      });

      var arrAdditionalAttachments = data?.additionalAttachments?.split(",");
      arrAdditionalAttachments = arrAdditionalAttachments?.filter((item) => {
        return item;
      });

      arrAdditionalImages = arrAdditionalImages?.map((item) => {
        return getImage(item);
      });
      arrAdditionalAttachments = arrAdditionalAttachments?.map((item) => {
        return getImage(item);
      });

      setAdditionalAttachments(arrAdditionalAttachments);
      setAdditionalImages(arrAdditionalImages);

      setIsLoading(false);
    });
  }

  async function createMaintenance(assetId) {
    setIsLoading(true);

    if (maintenanceUser && maintenanceFrequency && maintenanceDueDate) {

      let data = {
        frequency: maintenanceFrequency,
        AssignedToId: maintenanceUser,
        assetId: assetId,
        dueDate: maintenanceDueDate,
        // endDate: maintenanceEndDate,
        receiveNotifications: receiveNotifications
      };

      try {
        await apiService.createMaintenance(data);
      } catch (error) {
      }
    }

    setIsLoading(false);
  }

  function addAsset(validateImage = true) {

    if (barcodeNumber.trim() == "") {
      toast.error("Barcode is required")
      return;
    }

    if (validateImage && !assetImage) {
      setModalIsOpen(true);
      return;
    }

    setModalIsOpen(false);
    setIsLoading(true);

    let data = {
      id: assetId,
      MainImage: assetImage,
      additionalImages,
      additionalAttachments,
      departmentId,
      storeId: storeId,
      initialCost: (initialCost ? initialCost : 0),
      date: datePlaced,
      modelNumber,
      replacementCost: (replacementCost ? replacementCost : 0),
      assetType,
      description,
      serialNumber,
      note,
      barcode: barcodeNumber
    };

    apiService.addAsset(data).then((data: any) => {
      data = data.data;

      if (data.errorType) {
        toast.error(data.errorExplanation);
        setIsLoading(false);
        return;
      }

      if (isChanged) {
        createMaintenance(data.id);
      }

      setIsLoading(false);
      props.history.push("/assets");
    })
      .catch((error) => {

        setIsLoading(false);

        handleValidation(error?.response?.data?.errors);

        if (error.code === 'ECONNABORTED') {
          toast.error("Your request timeout! It might be one or more of your attachments too large.");
        }
        if (error?.response?.data?.errors) {
          toast.error("Error! please fill all required fields");
        } else {
          toast.error("Error! try again");
        }
      });
  }

  function handleValidation(err) {
    if (err) {
      setAssetTypeError(err.AssetType?.[0]);
      setDepartmentIdError(err.DepartmentId?.[0]);
      setSerialNumberError(err.SerialNumber?.[0]);
      setModelNumberError(err.ModelNumber?.[0]);
      setDescriptionError(err.Description?.[0]);
      // setInitialCostError("");
      // setReplacementCostError("");
      // setDatePlacedError("");
      // setNoteError("");
    }
  }

  function openPDF(pdfString) {
    let pdfWindow = window.open("")

    let string = pdfString.split(",")

    pdfWindow.document.write(
      "<iframe title='PCeyeD File Preview' width='100%' height='100%' src='data:application/pdf;base64, " +
      encodeURI(string[1]) + "'></iframe>"
    )
  }

  function handleAdd(file, i, type) {
    if (!file) {
      return;
    }

    var imgs = [] as string[];

    if (type == "images") {
      imgs = [...additionalImages];
      imgs[i] = file;
      setAdditionalImages(imgs);
    }

    if (type == "attachments") {
      imgs = [...additionalAttachments];
      imgs[i] = file;
      setAdditionalAttachments(imgs);
    }
  }

  function removeColumn(i, type) {
    if (type == "images") {
      var imgs = [...additionalImages];
      imgs.splice(i, 1);
      setAdditionalImages(imgs);
    }

    if (type == "attachments") {
      var imgs = [...additionalAttachments];
      imgs.splice(i, 1);
      setAdditionalAttachments(imgs);
    }
  }

  function addColumn(type) {
    if (type == "images") {
      var imgs = [...additionalImages];
      imgs.push("");
      setAdditionalImages(imgs);
    }

    if (type == "attachments") {
      var imgs = [...additionalAttachments];
      imgs.push("");
      setAdditionalAttachments(imgs);
    }
  }

  return (
    <DashboardLayout title={title}>
      <React.Fragment>
        <Loading isLoading={isLoading} />
        {modal()}

        <div className="card">
          <div className="card-header">
            <div className="row justify-content-center">
              <div className="col-sm-4">
                <div className="pro-pic">
                  <FileButton
                    image={assetImage}
                    onChange={(data) => {
                      setAssetImage(data);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="card-body">
            <form>
              <p className="" style={{ fontSize: "20px", fontWeight: 300 }}>
                General Info
              </p>
              <div className="row">
                <div className="col-sm-12 col-md-6">
                  <div className="form-group mt-1">
                    <label className="ml-3">Asset Type *</label>
                    <AppDropdown
                      onChange={(val) => setAssetType(val)}
                      error={assetTypeError}
                      value={assetType != null || undefined ? assetType.toString() : "0"}
                    >
                      <option value="0">Choose type</option>
                      <option value="1">Moving equipment</option>
                      <option value="2">Janitorial</option>
                      <option value="3">Electronics</option>
                      <option value="4">Major appliances</option>
                      <option value="5">Vehicles</option>
                      <option value="5">Landscape</option>
                    </AppDropdown>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="form-group mt-1">
                    <label className="ml-3">Store *</label>
                    <AppDropdown autoFocus={true} value={storeId != null || undefined ? storeId.toString() : "0"}
                      onChange={(val) => { setStoreId(parseInt(val)); }} error={storeIdError}>
                      <option value="0">Select a Store *</option>
                      {stores.map((store: any) => {
                        return (
                          <option key={store.id} value={store.id}>{store.name}</option>
                        );
                      })}
                    </AppDropdown>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="form-group mt-1">
                    <label className="ml-3">Department *</label>
                    <AppDropdown onChange={(val) => setDepartmentId(val)} value={departmentId != null || undefined ? departmentId.toString() : "0"}
                      error={departmentIdError}>
                      <option value="0">Select an entity..</option>
                      {departments != []
                        ? (departments.map((d: any) => {
                          return (
                            <option key={d.id} value={d.id}>
                              {d.name}
                            </option>
                          );
                        }))
                        : (<div></div>)}
                    </AppDropdown>
                  </div>
                </div>

                <div className="col-sm-12 col-md-6">
                  <div className="form-group mt-1">
                    <label className="ml-3">PCeyeD Barcode *</label>
                    <TextBox type="text" className="form-control sign-in-inputs"
                      value={barcodeNumber}
                      onChange={(val) => { setBarcodeNumber(val); }}
                      error={barcodeNumberError} />
                  </div>
                </div>

                <div className="col-sm-12 col-md-6">
                  <div className="form-group mt-1">
                    <label className="ml-3">Serial Number *</label>
                    <TextBox type="text" className="form-control sign-in-inputs"
                      value={serialNumber}
                      onChange={(val) => { setSerialNumber(val); }}
                      error={serialNumberError}
                    />
                  </div>
                </div>

                <div className="col-sm-12 col-md-6">
                  <div className="form-group mt-1">
                    <label className="ml-3">Model Number *</label>
                    <TextBox
                      type="text"
                      className="form-control sign-in-inputs"
                      value={modelNumber}
                      onChange={(val) => {
                        setModelNumber(val);
                      }}
                      error={modelNumberError}
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="form-group mt-1">
                    <label className="ml-3">Description</label>
                    <TextBox
                      type="text"
                      className="form-control sign-in-inputs"
                      value={description}
                      onChange={(val) => {
                        setDescription(val);
                      }}
                      error={descriptionError}
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="form-group mt-1">
                    <label className="ml-3">Initial Cost</label>

                    <TextBox
                      type="currency"
                      min={0}
                      className="form-control sign-in-inputs"
                      value={initialCost != null || undefined ? initialCost.toString() : "0"}
                      onChange={(val) => {
                        setInitialCost(val);
                      }}
                      error={initialCostError}
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="form-group mt-1">
                    <label className="ml-3">Replacement Cost</label>
                    <TextBox
                      type="currency"
                      min={0}
                      className="form-control sign-in-inputs"
                      value={replacementCost != null || undefined ? replacementCost.toString() : "0"}
                      onChange={(val) => {
                        setReplacementCost(val);
                      }}
                      error={replacementCostError}
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="form-group mt-1">
                    <label className="ml-3">Date Placed in Service</label>

                    <BestCalendar
                      className="form-control sign-in-inputs w-100"
                      // minDate={Date.now()}
                      selected={moment(datePlaced).toDate()}
                      onChange={(e: any) => {
                        setDatePlaced(moment(e).toDate());
                      }}
                    />
                  </div>
                </div>
                <div className="col-12 add-verification-section">
                  <MaintenanceUI
                    assetId={assetId}
                    onChange={(data) => {
                      setMaintenanceUser(data.user);
                      setMaintenanceFrequency(data.frequency);
                      setMaintenanceDueDate(data.dueDate);
                      // setMaintenanceEndDate(data.endDate);
                      setReceiveNotifications(data.receiveNotifications)
                      setIsChanged(data.isChanged);
                    }}
                  />
                </div>
                <div className="col-sm-12 col-md-12 mt-5">
                  <p
                    className="ml-3"
                    style={{
                      fontSize: "20px",
                      fontWeight: 300,
                    }}
                  >
                    Additional Images
                  </p>
                </div>
                <div className="col-12">
                  <div className="row">
                    {additionalImages?.map((img, i) => {
                      return (
                        <div key={i} className="col-auto file-button-wrap">
                          <FileButton
                            image={img}
                            onChange={(data) => {
                              handleAdd(data, i, "images");
                            }}
                          />
                          <div
                            className="img-remove btn btn-sm btn-danger"
                            onClick={() => {
                              removeColumn(i, "images");
                            }}
                          >
                            <i className="fas fa-times"></i>
                          </div>
                        </div>
                      );
                    })}
                    <div className="col-auto">
                      <img
                        className="add-image"
                        src="./assets/icons/add-image.png"
                        alt="add-image"
                        width="100"
                        height="100"
                        onClick={() => {
                          addColumn("images");
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-12 mt-5">
                  <p
                    className="ml-3"
                    style={{
                      fontSize: "20px",
                      fontWeight: 300,
                    }}
                  >
                    Attachments{" "}
                    <span
                      style={{
                        color: "gray",
                        fontSize: "13px",
                      }}
                    >
                      (Manuals, Warantees, etc..)
                    </span>{" "}
                  </p>
                </div>
                <div className="col-sm-12 col-md-12">
                  <div className="row">
                    {additionalAttachments?.map((img, i) => {
                      return (
                        <div key={i} className="col-auto file-button-wrap">
                          <FileButton image={img ? "/assets/images/attachment-icon.png" : ""}
                            onChange={(data) => { handleAdd(data, i, "attachments"); }}
                            isHideCropper={true}
                            accept=".pdf"
                          />
                          {img ?
                            (<button onClick={(e) => { e.preventDefault(); openPDF(img) }} className="btn btn-sm btn-link view-attachment">
                              View
                            </button>) :
                            (<></>)
                          }
                          <div className="img-remove btn btn-sm btn-danger"
                            onClick={() => { removeColumn(i, "attachments"); }}>
                            <i className="fas fa-times"></i>
                          </div>
                        </div>
                      )
                    })}
                    <div className="col-auto">
                      <img
                        className="add-image"
                        src="./assets/icons/add-image.png"
                        alt="add-image"
                        width="100"
                        height="100"
                        onClick={() => {
                          addColumn("attachments");
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-12 mt-5 mb-5">
                  <p
                    className="ml-3"
                    style={{
                      fontSize: "20px",
                      fontWeight: 300,
                    }}
                  >
                    Notes
                  </p>

                  <TextBox
                    type="textarea"
                    className="form-control sign-in-inputs"
                    cols={30}
                    rows={5}
                    value={note}
                    onChange={(val) => {
                      setNote(val);
                    }}
                    error={noteError}
                  />
                </div>
                <div className="col-12">
                  <div className="row justify-content-end">
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col">
                          {checkClaim("admin") && assetId
                            ? (<div className="form-group mt-1">
                              <button className="btn btn-secondary btn-block btn-pcid-cancel mt-3"
                                onClick={() => { setModalDeleteIsOpen(true) }}>Remove Asset
                              </button>
                            </div>)
                            : (<div></div>)
                          }
                        </div>
                        <div className="col">
                          <div className="form-group mt-1">
                            <input
                              className="btn btn-secondary btn-block btn-pcid-cancel mt-3"
                              type="button"
                              value="Cancel"
                              onClick={() => {
                                // props.history.push("/assets");
                                props.history.goBack();
                              }}
                            />
                          </div>
                        </div>
                        <div className="col">
                          <div className="form-group mt-1">
                            <input
                              className="btn btn-success btn-block btn-pcid mt-3"
                              type="button"
                              value="Save"
                              onClick={() => {
                                addAsset();
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        {confirmDelete()}
      </React.Fragment>
    </DashboardLayout>
  );

  function deleteAsset(deleteAsset = false) {
    if (assetId && deleteAsset) {

      setIsLoading(true);

      var data = {
        assetId: assetId
      }

      apiService.deleteAsset(data).then((data: any) => {
        data = data.data;

        setIsLoading(false);

        props.history.push("/assets");
      }).catch((error) => {
        alert("Unable to delete selected.")
        setIsLoading(false);
      });
    } else {
      alert("Unable to delete selected.")
      setIsLoading(false);
    }
  }

  function confirmDelete() {
    return (
      <Modal isOpen={modalDeleteIsOpen} ariaHideApp={false}
        onRequestClose={() => { setModalDeleteIsOpen(false); }} className="modal">
        <div className="row">
          <div className="card text-center" style={{ width: "350px", height: "180px" }}>
            <div className="card-body align-items-center d-flex justify-content-center">
              Are you sure you want to delete this?
            </div>
            <div className="card-footer text-muted p-4">
              <input className="btn btn-secondary btn-pcid-cancel" type="button"
                value="Continue" onClick={() => { deleteAsset(true); }}
              />
              <input className="btn btn-success btn-pcid ml-3" type="button"
                value="Go Back"
                onClick={() => { setModalDeleteIsOpen(false); }}
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  function modal() {
    return (
      <Modal
        isOpen={modalIsOpen}
        ariaHideApp={false}
        onRequestClose={() => {
          setModalIsOpen(false);
        }}
        className="modal"
      >
        <div className="row">
          <div
            className="card text-center"
            style={{ width: "350px", height: "180px" }}
          >
            <div className="card-body align-items-center d-flex justify-content-center">
              You haven't set a photo!
            </div>
            <div className="card-footer text-muted p-4">
              <input
                className="btn btn-secondary btn-pcid-cancel"
                type="button"
                value="Continue"
                onClick={() => {
                  addAsset(false);
                }}
              />
              <input
                className="btn btn-success btn-pcid ml-3"
                type="button"
                value="Go Back"
                onClick={() => {
                  setModalIsOpen(false);
                }}
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  }
};

export default AddAsset;
