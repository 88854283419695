/* eslint-disable */

import React, { Fragment } from "react";
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
    DateTimePicker,
    DatePicker,
} from "@material-ui/pickers";
import moment from "moment";
import { CSSProperties } from "@material-ui/core/styles/withStyles";

interface BestCalendarProps {
    onChange: CallableFunction;
    selected?: Date;
    minDate?: Date | Number;
    className?: String;
    styles?: CSSProperties;
}

const BestCalendar = (props: BestCalendarProps) => {
    return (
        <div style={props.styles} className="best-calendar">
            <div className="textbox">
                <DatePicker
                    minDate={props.minDate}
                    disableToolbar={false}
                    variant="inline"
                    margin="normal"
                    format="MMM DD YYYY"
                    value={moment(props.selected)}
                    onChange={(e: any) => {
                        if (e) {
                            props.onChange(e.toDate());
                        }
                    }}
                />
            </div>

            {process.env.NODE_ENV == "development" ? (
                <div className="inline">
                    <KeyboardDatePicker
                        disableToolbar={false}
                        variant="inline"
                        tabIndex={-1}
                        margin="normal"
                        value={moment().toDate()}
                        onChange={(e: any) => {
                            if (e) {
                                props.onChange(e.toDate());
                            }
                        }}
                    />
                </div>
            ) : (
                    <Fragment />
                )}
        </div>
    );
};

export default BestCalendar;
