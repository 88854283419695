/* eslint-disable */

import React, { useState, useContext, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import ApiService from "../../services/ApiService";
import Loading from "../../components/Loading";
import { toast } from "react-toastify";

import FileButton from "../../components/FileButton";
import TextBox from "../../components/TextBox";
import { UserCompany } from "../../store/StoreProvider";
import {
    setLocalStorageFromAuth,
    navigateTo,
    setUser,
} from "../../services/HelperMethod";
import AppDropdown from "../../components/AppDropdown";
import countryCodes from "../Users/PhoneCode.json";
import AppButton from "../../components/AppButton";
import { symbolName } from "typescript";
import Steps from "../../components/Steps";

const SignUp = () => {
    const history = useHistory();
    const [countryCodeList, setCountryCodeList] = useState([] as any);

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [profilePicture, setProfilePicture] = useState(null as any);

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    // errors
    const [firstNameError, setFirstNameError] = useState("");
    const [lastNameError, setLastNameError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [phoneNumberError, setPhoneNumberError] = useState("");
    const [companyNameError, setCompanyNameError] = useState("");

    const [usernameError, setUsernameError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");
    const [countryCode, setCountryCode] = useState("");
    const [countryCodeError, SetCountryCodeError] = useState("");

    // validations
    const [isContain8Char, setIsContain8Char] = useState(null as any);
    const [bothLowerUpper, setBothLowerUpper] = useState(null as any);
    const [containSymbol, setContainSymbol] = useState(null as any);

    const apiService = ApiService();
    const [_, setCompanyNameStore] = useContext(UserCompany);

    useEffect(() => {
        setCountryCodeList(countryCodes);
        setCountryCode("+1");
    }, []);

    useEffect(() => {
        setConfirmPasswordError("");
    }, [password, confirmPassword]);

    function getStepsUI() {
        return FirstStep();
    }

    return (
        <div>
            <Loading isLoading={isLoading} />
            {getStepsUI()}
        </div>
    );

    function registerUser() {
        // validation
        if (password != confirmPassword) {
            setConfirmPasswordError("Password does not match");
            return;
        }

        const request = {
            firstName,
            lastName,
            email,
            phoneNumber,
            companyName,
            username,
            password,
            profilePicture,
            countryCode,
        };
        setIsLoading(true);
        clearFormErrors();
        apiService
            .registerAdminUser(request)
            .then((data: any) => {
                data = data.data;
                setIsLoading(false);

                if (data.errorType) {
                    toast.error(data.errorExplanation);
                    return;
                }

                setLocalStorageFromAuth(data, setCompanyNameStore);
                navigateTo(history, data.navigateTo);
            })
            .catch((err) => {
                setIsLoading(false);
                handleFormErrors(err?.response?.data?.errors);
            });
    }

    function handleFormErrors(err) {
        if (err) {
            setCompanyNameError(err.CompanyName?.[0]);
            setEmailError(err.Email?.[0]);
            setFirstNameError(err.FirstName?.[0]);
            setLastNameError(err.LastName?.[0]);
            setPhoneNumberError(err.PhoneNumber?.[0]);
            SetCountryCodeError(err.CountryCode?.[0]);

            setUsernameError(err.Username?.[0]);
            setPasswordError(err.Password?.[0]);
        } else {
            toast.error("Error. Try again!");
        }
    }

    function clearFormErrors() {
        setCompanyNameError("");
        setEmailError("");
        setFirstNameError("");
        setLastNameError("");
        setPhoneNumberError("");

        setUsernameError("");
        setPasswordError("");
        setConfirmPasswordError("");
    }

    function checkPasswordValidation(val) {
        if (val) {
            if (val.length >= 8) {
                setIsContain8Char(true);
            } else {
                setIsContain8Char(false);
            }
        }

        if (val.toLowerCase() != val && val.toUpperCase() != val) {
            setBothLowerUpper(true);
        } else {
            setBothLowerUpper(false);
        }

        //contain number
        var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        if (/\d/.test(val) || format.test(val)) {
            setContainSymbol(true);
        } else {
            setContainSymbol(false);
        }
    }

    function FirstStep() {
        return (
            <div className="full-layout">
                <div className="container">
                    <div className="row">
                        <div className="col-lg"></div>
                        <div className="col-lg">
                            <div className="white-box mw">
                                <div>
                                    <div style={{ marginBottom: "1.5rem" }} className="row">
                                        <div className="col-sm-12 col-md-5">
                                            <h2 style={{ marginTop: "0.7rem", whiteSpace: "nowrap" }} className="mb-3 topic-text ht" id={"signup-profile"}>Create an account</h2>
                                        </div>
                                        <div className="col-sm-12 col-md-7">
                                            <Steps iteration={3} activeIndex={0} />
                                        </div>
                                    </div>
                                    <p>
                                        Already have an account?{" "}
                                        <Link
                                            style={{ color: "#2e89bc" }}
                                            to={"login"}
                                        >
                                            Sign In
                                        </Link>
                                    </p>
                                    <div>
                                        {/* <div className="row justify-content-center">
                                        <div className="col-auto">
                                            <FileButton
                                                onChange={(file) => {
                                                    if (file) {
                                                        setProfilePicture(file);
                                                    }
                                                }}
                                                image={profilePicture}
                                            />
                                        </div>
                                    </div> */}
                                        <div className="row mt-3">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>First Name *</label>
                                                    <TextBox
                                                        type="text"
                                                        onChange={(val) => {
                                                            setFirstName(val);
                                                        }}
                                                        className="form-control sign-in-inputs"
                                                        error={firstNameError}
                                                        value={firstName}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Last Name *</label>
                                                    <TextBox
                                                        type="text"
                                                        onChange={(val) => {
                                                            setLastName(val);
                                                        }}
                                                        className="form-control sign-in-inputs"
                                                        error={lastNameError}
                                                        value={lastName}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <label>Email *</label>
                                            <TextBox
                                                type="email"
                                                onChange={(val) => {
                                                    setEmail(val);
                                                    setUsername(val);
                                                }}
                                                className="form-control sign-in-inputs"
                                                error={emailError}
                                                value={email}
                                            />
                                        </div>
                                        {/* <div className="form-group">
                                        <label>Phone *</label>
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <AppDropdown
                                                    value={countryCode}
                                                    onChange={(val) => {
                                                        setCountryCode(val);
                                                    }}
                                                    error={countryCodeError}
                                                    items={countryCodeList}
                                                />
                                            </div>
                                            <div className="col">
                                                <TextBox
                                                    type="text"
                                                    mask="999-999-9999"
                                                    onChange={(val) => {
                                                        setPhoneNumber(val);
                                                    }}
                                                    className="form-control sign-in-inputs"
                                                    error={phoneNumberError}
                                                    value={phoneNumber}
                                                />
                                            </div>
                                        </div>
                                    </div> */}
                                        {/* <div className="form-group">
                                        <label>Company *</label>
                                        <TextBox
                                            type="text"
                                            onChange={(val) => {
                                                setCompanyName(val);
                                            }}
                                            className="form-control sign-in-inputs"
                                            error={companyNameError}
                                            value={companyName}
                                        />
                                    </div> */}
                                        {/* <h2>Login Credentials</h2> */}
                                        <div className="form-group">
                                            <label>Username *</label>
                                            <TextBox
                                                type="text"
                                                onChange={(val) => {
                                                    setUsername(val);
                                                }}
                                                className="form-control sign-in-inputs"
                                                error={usernameError}
                                                value={username}
                                            />
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Password *</label>
                                                    <TextBox
                                                        type="password"
                                                        onChange={(val) => {
                                                            setPassword(val);
                                                            checkPasswordValidation(
                                                                val
                                                            );
                                                        }}
                                                        className="form-control sign-in-inputs"
                                                        error={passwordError}
                                                        value={password}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>
                                                        Confirm password *
                                                    </label>
                                                    <TextBox
                                                        type="password"
                                                        onChange={(val) => {
                                                            setConfirmPassword(
                                                                val
                                                            );
                                                        }}
                                                        className="form-control sign-in-inputs"
                                                        error={
                                                            confirmPasswordError
                                                        }
                                                        value={confirmPassword}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        Password must contain:
                                        <ul className={"password-rule"}>
                                            <li
                                                className={
                                                    isContain8Char
                                                        ? "active"
                                                        : containSymbol == null
                                                            ? ""
                                                            : "not-valid"
                                                }
                                            >
                                                At least 8 characters
                                            </li>
                                            <li
                                                className={
                                                    bothLowerUpper
                                                        ? "active"
                                                        : containSymbol == null
                                                            ? ""
                                                            : "not-valid"
                                                }
                                            >
                                                Both lower (a-z) and upper case
                                                letters (A-Z)
                                            </li>
                                            <li
                                                className={
                                                    containSymbol
                                                        ? "active"
                                                        : containSymbol == null
                                                            ? ""
                                                            : "not-valid"
                                                }
                                            >
                                                At least one number or one
                                                symbol
                                            </li>
                                        </ul>
                                    </div>
                                    <div>
                                        {!isContain8Char &&
                                            !bothLowerUpper &&
                                            !containSymbol}
                                        <AppButton
                                            disabled={
                                                !isContain8Char ||
                                                !bothLowerUpper ||
                                                !containSymbol
                                            }
                                            isLoading={isLoading}
                                            className="btn mt-4"
                                            onClick={() => {
                                                registerUser();
                                            }}
                                        >
                                            Create an account
                                        </AppButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default SignUp;
