import React, { useContext, useEffect, useState } from "react";
import ApiService from "../../services/ApiService";
import { toast } from "react-toastify";
import { setLocalStorageFromAuth, parseQueryString, } from "../../services/HelperMethod";
import { UserCompany } from "../../store/StoreProvider";
import { useHistory } from "react-router-dom";
import Steps from "../../components/Steps";

function Payment() {

    const history = useHistory();

    const apiService = ApiService();

    const [isLoading, setIsLoading] = useState(false);
    const [paymentToken, setPaymentToken] = useState("");
    const [_, setCompanyNameStore] = useContext(UserCompany);

    useEffect(() => {
        payFromCard();
    }, [])

    function redirectToLogin(){
        history.push("/login");
    }

    function payFromCard() {
        setIsLoading(true);

        let data = { SourceURL: window.location.href };

        apiService.getPaymentToken(data).then((data: any) => {

            data = data.data;

            setPaymentToken(data.token);

            (window as any).AuthorizeNetIFrame = {};
            (window as any).AuthorizeNetIFrame.onReceiveCommunication = function (querystr) {

                const params = parseQueryString(querystr);

                switch (params["action"]) {
                    case "successfulSave":
                        setIsLoading(false);
                        break;
                    case "cancel":
                        setIsLoading(false);
                        redirectToLogin();
                        break;
                    case "resizeWindow":
                        setIsLoading(false);
                        break;
                    case "transactResponse":

                        setIsLoading(false);

                        let transactionData = JSON.parse(params.response);

                        apiService.validatePayment({ transactionId: transactionData.transId, }).then((data: any) => {

                            data = data.data;

                            if (data.errorType) {
                                setIsLoading(false);
                                toast.error(data.errorExplanation);
                                return;
                            }

                            setLocalStorageFromAuth(data, setCompanyNameStore);
                            setIsLoading(false);
                            toast.success("Credit Card Added");

                            history.push("/setup-store")
                        }).catch((err) => {
                            setIsLoading(false);
                            toast.error("Failed try again");
                        });
                        break;
                }
            };

            const submitForm = document.getElementById("submit_payment") as HTMLFormElement;

            submitForm?.submit();
        }).catch((error) => {
            toast.error("Error loading payment");
        });
    }

    return (
        <React.Fragment>
            <div className="full-layout">
                <div className="container">
                    <div className="login-wrapper-gray">
                        <form id="submit_payment" action="https://test.authorize.net/payment/payment" method="post" target="add_payment">

                            <input type="hidden" name="token" value={paymentToken} />
                        </form>

                        <div className="payment-iframe-wrap" style={{ display: "flex" }}>
                            <div style={{ display: "flex", flexDirection: "column" }} className="content-area">
                                <div style={{ display: "flex", flexDirection: "column", width: "100%", marginTop: "2.5rem" }}>
                                    <div style={{ marginBottom: "1.5rem" }} className="row">
                                        <div className="col-sm-12 col-md-5">
                                            <h2 style={{ margin: "0.7rem 2rem" }} className="mb-3 topic-text ht" id={"payment-profile"}>Add a Payment Method</h2>
                                        </div>
                                        <div className="col-sm-12 col-md-7">
                                            <Steps iteration={3} activeIndex={2} />
                                        </div>
                                    </div>
                                    <iframe title="payment" id="add_payment" name="add_payment" className="embed-responsive-item panel" frameBorder={0} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Payment;
