/* eslint-disable */

import React, { useContext } from "react";
import { Dropdown } from "react-bootstrap";
import {
    ShowGuidContext,
    showGuidType,
    UserCompany,
} from "../store/StoreProvider";
import { toast } from "react-toastify";
import moment from "moment";
var _ = require("lodash");
import packageJson from "../../package.json";

export const toBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = function (e) {
			alert("error " + e.target.error.code + " \n\niPhone iOS8 Permissions Error.");
		}
    });

export const setUserJWT = (jwt) => {
    localStorage.setItem("jwt", jwt);
};

export const getUserJWT = () => {
    return localStorage.getItem("jwt");
};

export function getQueryVariable(variable) {
    var query = window.location.hash.split("?")[1];
    if (!query) {
        return false;
    }
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) {
            return pair[1];
        }
    }
    return false;
}

export function parseQueryString(str) {
    var vars = [] as any;
    var arr = str.split("&");
    var pair;
    for (var i = 0; i < arr.length; i++) {
        pair = arr[i].split("=");
        vars.push(pair[0]);
        vars[pair[0]] = unescape(pair[1]);
    }
    return vars;
}

export function checkClaim(userClaim) {
    let isClaimIn = getUser()?.claims.find((claim) => {
        return claim.toLowerCase() == userClaim.toLowerCase();
    });

    return isClaimIn;
}

export function showValidationErrors(error) {
    let err = error?.response?.data?.errors;
    if (!err) {
        toast.error("Network Error! try again");
        return;
    }

    let keys = Object.keys(err);
    if (keys) {
        keys.forEach((er) => {
            toast.error(err[er][0]);
        });
    }
}

export function setLocalStorageFromAuth(data, setCompanyName) {
    if (data.token) {
        setUserJWT(data.token);
        setUser({
            company: data.company,
            claims: data.claims,
            user: data.user,
        });
        setCompanyName(data.company?.name);
    }
}

export function navigateTo(history, navigateTo) {
    if (navigateTo == "payment") {
        history.push("/payment");
        return;
    }
    if (navigateTo == "companySetup") {
        history.push("/businessProfile");
        return;
    }

    var canAddStore = checkClaim("Admin");
    if (canAddStore) {
        history.push("/dashboard");
    } else {
        // history.push("/stores");
        history.push("/dashboard");
    }
}

interface UserType {
    company: CompanyType;
    claims: string[];
    user: any;
}
interface CompanyType {
    name: string;
}

export const setUser = (user: UserType) => {
    var strUser = JSON.stringify(user);
    localStorage.setItem("user", strUser);
};

export const getUser = () => {
    var strUser = localStorage.getItem("user");
    if (strUser) {
        try {
            var user = JSON.parse(strUser);
            return user as UserType;
        } catch (error) {
            return null;
        }
    }
};

export function getApiURL() {
    let baseUlr = process.env.REACT_APP_BASE_URL;
    if (process.env.NODE_ENV == "development") {
        baseUlr = "https://localhost:44374/";
    }

    return baseUlr;
}

export const getImage = (imgURL) => {
    if (imgURL) {
        return imgURL;
    } else {
        return "/assets/images/placeholder.png";
        // /assets/images/stores/missing.jpg
    }
};

export function getVersionNumber() {
    return "V" + packageJson.version;
}

export function handleRedirect(url) {
    var params = getParameterByName("redirect", url);
}

export function getGuidElement(data: showGuidType) {
    return data;
}

export function dateDiff(date) {

    var eventdate = moment(date).startOf('day');
    var todaysdate = moment().startOf('day');

    var diffNumber = eventdate.diff(todaysdate);
    var diff = eventdate.diff(todaysdate, "days");

    let classNames = "text";
    let text = diff == 1 ? "Day Left" : "Days Left";

    if (diffNumber < 0) {
        classNames += " text-danger";
        text = "Over-due";
    }

    return (
        diff == 0 ?
            (<span className={classNames}>
                {"Due Today"}
            </span>) :
            (<span className={classNames}>
                {diffNumber < 0 ? "" : diff} {text}
            </span>)
    );
}

function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export function isURL(str) {
    var pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
        "i"
    ); // fragment locator
    return pattern.test(str);
}

export function frequencyText(fq) {
    if (fq === 1) {
        return <>Weekly</>;
    } else if (fq === 2) {
        return <>By Weekly</>;
    } else if (fq === 3) {
        return <>Monthly</>;
    } else if (fq === 4) {
        return <>Quarterly</>;
    } else if (fq === 5) {
        return <>Yearly</>;
    }

    return <div className="badge badge-info">[not assigned]</div>;
}

export function tableColumns(name, selector, data, setter, className?) {
    return (
        <th
            data-sorted="default"
            className = {`sortable ${className ? className : ''}`}
            onClick={(e) => {
                sortColumns(data, selector, setter, e);
            }}
        >
            {name}
        </th>
    );
}


export function sortColumns(data, name, setter, target) {
    const $ = (window as any).$;

    let unsorted = [...data];
    let currentTarget = target.currentTarget;
    let currentSort = currentTarget.getAttribute("data-sorted");

    if (currentSort == "true") {
        var sorted = unsorted.sort((a, b) => (a[name] < b[name] ? 1 : -1));
        currentSort = "false";
    } else {
        var sorted = unsorted.sort((a, b) => (a[name] > b[name] ? 1 : -1));
        currentSort = "true";
    }
    setter(sorted);

    $(".sortable").attr("data-sorted", "default");
    setTimeout(() => {
        $(currentTarget).attr("data-sorted", currentSort.toString());
    }, 10);
}

export function getSearch(data, setter, searchColumns, original) {
    const $ = (window as any).$;

    return (
        <input
            className="table-search"
            placeholder="Search"
            onChange={(e) => {
                let keyword = e.target.value;
                let searchResult = [] as any[];
                if (keyword) {
                    original.forEach((d) => {
                        let isFound = false;
                        searchColumns.forEach((colum) => {
                            var searchTarget = _.get(d, colum);
                            if (searchTarget) {
                                if (
                                    searchTarget
                                        .toString()
                                        .toLowerCase()
                                        .search(keyword.toLocaleLowerCase()) >=
                                    0
                                ) {
                                    isFound = true;
                                }
                            }
                        });

                        if (isFound) {
                            searchResult.push(d);
                        }
                    });
                    setter(searchResult);
                    // $('.searchable').each(function(searchable, target){

                    //   var targetString = $(target).html();
                    //   // var target = searchable.html();
                    // })
                } else {
                    setter(original);
                }
            }}
        />
    );
}

export function getColumnSearch(data, setter, searchColumns, original) {
    const $ = (window as any).$;

    return (
        <input
            className="column-search"
            placeholder="Search"
            onChange={(e) => {
                let keyword = e.target.value;
                let searchResult = [] as any[];
                if (keyword) {
                    original.forEach((d) => {
                        let isFound = false;
                        searchColumns.forEach((colum) => {
                            var searchTarget = _.get(d, colum);
                            if (searchTarget) {
                                if (
                                    searchTarget
                                        .toString()
                                        .toLowerCase()
                                        .search(keyword.toLocaleLowerCase()) >=
                                    0
                                ) {
                                    isFound = true;
                                }
                            }
                        });

                        if (isFound) {
                            searchResult.push(d);
                        }
                    });
                    setter(searchResult);
                    // $('.searchable').each(function(searchable, target){

                    //   var targetString = $(target).html();
                    //   // var target = searchable.html();
                    // })
                } else {
                    setter(original);
                }
            }}
        />
    );
}

export function handleErrors(err) {
    if (err?.response?.data?.error && !err?.response?.data?.error?.errorType) {
        toast.error(err?.response?.data?.error?.message);
    } else if (err?.response?.data?.errors) {
        for (const [key, value] of Object.entries(err.response?.data?.errors)) {
            let title = key as any;
            let message = (value as any)[0] as any;
            toast.error(message);
        }
    } else if (err?.response?.data?.error?.errorType) {
        toast.error(err?.response?.data?.error?.errorMessage);
    } else {
        toast.error("Network error! try again");
    }

    console.error(err, err.response);
}

export function OptionsDropDown(props: any) {
    return (
        <Dropdown drop="right" className="dropdown">
            <Dropdown.Toggle id="row-edit">
                <i className="fas fa-ellipsis-h"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu>{props.children}</Dropdown.Menu>
        </Dropdown>
    );
}
