/* eslint-disable */

import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import ApiService from "../../services/ApiService";
import { toast } from "react-toastify";
import {
  toBase64,
  getImage,
  getUser,
  checkClaim,
} from "../../services/HelperMethod";
import DashboardLayout from "../../layouts/DashboardLayout";
import TextBox from "../../components/TextBox";
import { useParams } from "react-router-dom";
import Loading from "../../components/Loading";
import AppCropper from "../../components/AddCropper";
import FileButton from "../../components/FileButton";
import AppDropdown from "../../components/AppDropdown";
import countryCodes from "./PhoneCode.json";
import Select from "react-select";

const AddUser = (props) => {
  const { userId, securityId, isGoBack } = useParams() as any;
  const [countryCodeList, setCountryCodeList] = useState([] as any);

  //form
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [userName, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [security, setSecurity] = useState("");
  const [profilePicture, setProfilePicture] = useState(null as any);
  const [countryCode, setCountryCode] = useState("");

  // form validation
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [userNameError, setUserNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [securityError, setSecurityError] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [title, setTitle] = useState("Add User");
  const [isEditUser, setIsEditUser] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [countryCodeError, SetCountryCodeError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [timeZone, setTimeZone] = useState(1);
  countryCodeError;
  const apiService = ApiService();
  const [changePassword, setChangePassword] = useState(false);
  var cd = false;

  useEffect(() => {
    handleEditUser();
    setCountryCodeList(countryCodes);
    setCountryCode("+1");
    setSecurity(securityId);
    // var countryCodes = JSON.parse(countryCodes);
  }, []);

  function openPassword() {
    setChangePassword(true);
  }

  function getPasswordUI() {
    if (changePassword) {
      return (
        <div className="col-sm-12 col-md-6">
          <div className="form-group mt-1">
            <label className="ml-3" htmlFor="username">
              Password
            </label>

            <TextBox
              type="password"
              placeholder={passwordPlaceholderText()}
              onChange={(val) => {
                setPassword(val);
              }}
              className="form-control sign-in-inputs"
              error={passwordError}
              value={password}
            />
          </div>
          <div className="form-group mt-1">
            <label className="ml-3" htmlFor="username">
              Confirm Password
            </label>

            <TextBox
              type="password"
              placeholder={passwordPlaceholderText()}
              onChange={(val) => {
                setConfirmPassword(val);
              }}
              className="form-control sign-in-inputs"
              error={confirmPasswordError}
              value={confirmPassword}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div className="col-sm-12 col-md-6">
          <label></label>
          <input
            className="btn btn-secondary btn-block btn-pcid-cancel mt-3"
            type="button"
            value="Change Password"
            onClick={() => {
              openPassword();
            }}
          />
        </div>
      );
    }
  }

  function changeTimeZone(value) {
    setTimeZone(value);
  }

  var opts = {};
  if (props.autoFocus) {
    opts["autoFocus"] = true;
  }

  function registerUser(validateImage = true) {
    resetValidation();

    // validation
    if (password != confirmPassword) {
      setConfirmPasswordError("Password and confirm password does not match");
      return;
    }

    if (!profilePicture && validateImage) {
      setModalIsOpen(true);
      return;
    }

    let data = {
      id: userId,
      firstName,
      lastName,
      username: userName,
      password,
      phone: phoneNumber,
      email,
      selectedRole: security,
      phoneCountryCode: countryCode,
      profileImageBase64: profilePicture,
      location: timeZone,
    };

    setIsLoading(true);
    apiService
      .registerUser(data)
      .then((data: any) => {
        data = data.data;
        if (data.errorType) {
          toast.error(data.errorExplanation);
          setIsLoading(false);
          setModalIsOpen(false);
        } else {
          setModalIsOpen(false);
          if (props.isModal) {
            toast.info(
              "Manager " + data.firstName + " " + data.lastName + " saved."
            );
          }
          if (!isGoBack) {
            props.history.push("/users");
          } else {
            props.history.goBack();
          }
        }
      })
      .catch((error) => {
        handleFormErrors(error?.response?.data?.errors);
      });
  }

  function removeUser(isCheckRemove?: false) {
    setIsLoading(true);
    if (isCheckRemove) {
    }

    apiService
      .removeUser(userId)
      .then((data: any) => {
        data = data.data;
        if (data === true) {
          props.history.push("/users");
        }

        toast.error(data.errorExplanation);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.errors);
        setIsLoading(false);
      });
  }

  function resetValidation() {
    setEmailError("");
    setPhoneNumberError("");
    setLastNameError("");
    setUserNameError("");
    setFirstNameError("");
    setSecurityError("");
    setPasswordError("");
    setConfirmPasswordError("");
  }

  function handleFormErrors(err) {
    if (err) {
      toast.error("Please fill all the required fields");
      setEmailError(err.Email?.[0]);
      setPhoneNumberError(err.Phone?.[0]);
      setLastNameError(err.LastName?.[0]);
      setUserNameError(err.Username?.[0]);
      setPasswordError(err.Password?.[0]);
      setFirstNameError(err.FirstName?.[0]);
      setSecurityError(err.SelectedRole?.[0]);
      SetCountryCodeError(err.PhoneCountryCode?.[0]);
    }

    setIsLoading(false);
    setModalIsOpen(false);
  }

  function handleEditUser() {
    if (userId) {
      setIsLoading(true);
      apiService.getUser(userId).then(({ data }) => {
        setIsLoading(false);
        setIsEditUser(true);
        setTitle("Edit User");

        setFirstName(data.firstName || "");
        setLastName(data.lastName || "");
        setUsername(data.username || "");
        setPhoneNumber(data.phone || "");
        setEmail(data.email || "");
        setSecurity(data.selectedRole || "");
        setCountryCode(data.phoneCountryCode || "");
        setProfilePicture(getImage(data.imageURL));
        setTimeZone(data.location);
      });
    }
  }

  function getRoleOptionsUI() {
    var claims = getUser()?.claims;

    if (!claims) {
      return <></>;
    }
    var canAddUsers = checkClaim("Admin");

    var canAddPCIDUsers = checkClaim("PCIDAdmin");

    let adminOptions = (
      <>
        <option value="PCID Admin">PCeyeD Admin</option>
        <option value="PCID Customer Service">PCeyeD Customer Service</option>
      </>
    );

    let usersOptions = (
      <>
        <option value=""></option>
        <option value="Admin">Admin</option>
        <option value="Manager">Manager</option>
        <option value="Clerk">Clerk</option>
        <option value="Power Admin">Power Admin</option>
      </>
    );

    if (canAddPCIDUsers) {
      return (
        <>
          {usersOptions}
          {adminOptions}
        </>
      );
    }

    if (canAddUsers) {
      return usersOptions;
    }

    return <></>;
  }

  function passwordPlaceholderText() {
    if (userId) {
      return "Keep empty to use the current password";
    }
    return "";
  }

  return props.isModal ? (
    <div title={title}>
      <Loading isLoading={isLoading} />
      <React.Fragment>
        <div className="card">
          <div className="card-header">
            <div className="row justify-content-center">
              <div className="col-auto">
                <FileButton
                  onChange={(file) => {
                    if (file) {
                      setProfilePicture(file);
                    }
                  }}
                  image={profilePicture}
                />
              </div>
            </div>
          </div>

          <div className="card-body">
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <div className="form-group mt-1">
                  <label className="ml-3" htmlFor="first-name">
                    First name *
                  </label>

                  <TextBox
                    type="text"
                    onChange={(val) => {
                      setFirstName(val);
                    }}
                    className="form-control sign-in-inputs"
                    error={firstNameError}
                    value={firstName}
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-6">
                <div className="form-group mt-1">
                  <label className="ml-3" htmlFor="last-name">
                    Last name *
                  </label>
                  <TextBox
                    type="text"
                    onChange={(val) => {
                      setLastName(val);
                    }}
                    className="form-control sign-in-inputs"
                    error={lastNameError}
                    value={lastName}
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-6">
                <div className="form-group mt-1">
                  <label className="ml-3" htmlFor="username">
                    Username *
                  </label>

                  <TextBox
                    type="text"
                    onChange={(val) => {
                      setUsername(val);
                    }}
                    className="form-control sign-in-inputs"
                    error={userNameError}
                    value={userName}
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-6">
                <div className="form-group mt-1">
                  <label className="ml-3" htmlFor="username">
                    Password
                  </label>

                  <TextBox
                    type="password"
                    placeholder={passwordPlaceholderText()}
                    onChange={(val) => {
                      setPassword(val);
                    }}
                    className="form-control sign-in-inputs"
                    error={passwordError}
                    value={password}
                  />
                </div>
                <div className="form-group mt-1">
                  <label className="ml-3" htmlFor="username">
                    Confirm Password
                  </label>

                  <TextBox
                    type="password"
                    placeholder={passwordPlaceholderText()}
                    onChange={(val) => {
                      setConfirmPassword(val);
                    }}
                    className="form-control sign-in-inputs"
                    error={confirmPasswordError}
                    value={confirmPassword}
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-6">
                <div className="form-group mt-1">
                  <label className="ml-3" htmlFor="phone">
                    Phone{" "}
                  </label>
                  <div className="row">
                    <div className="col-lg-4">
                      <AppDropdown
                        value={countryCode}
                        onChange={(val) => {
                          setCountryCode(val);
                        }}
                        error={countryCodeError}
                        items={countryCodeList}
                      ></AppDropdown>
                    </div>
                    <div className="col">
                      <TextBox
                        mask="999-999-9999"
                        type="text"
                        onChange={(val) => {
                          setPhoneNumber(val);
                        }}
                        className="form-control sign-in-inputs"
                        error={phoneNumberError}
                        value={phoneNumber}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6">
                <div className="form-group mt-1">
                  <label className="ml-3" htmlFor="email">
                    Email *
                  </label>

                  <TextBox
                    type="text"
                    onChange={(val) => {
                      setEmail(val);
                    }}
                    className="form-control sign-in-inputs"
                    error={emailError}
                    value={email}
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-6">
                <div className="form-group mt-1">
                  <label className="ml-3" htmlFor="security">
                    Security *
                  </label>
                  <AppDropdown
                    onChange={(value: any) => {
                      setSecurity(value);
                    }}
                    value={security}
                    error={securityError}
                  >
                    {getRoleOptionsUI()}
                  </AppDropdown>
                </div>
              </div>
            </div>

            <div className="row justify-content-center align-content-center">
              <div className="col-md-6">{showRemoveButtonUI()}</div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group mt-1">
                      <input
                        className="btn btn-secondary btn-block btn-pcid-cancel mt-3"
                        type="button"
                        value="Cancel"
                        onClick={() => {
                          // props.history.push("/users");
                          props.history.goBack();
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mt-1">
                      <input
                        className="btn btn-success btn-block btn-pcid mt-3"
                        type="button"
                        value="Save"
                        onClick={() => {
                          registerUser(true);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {confirmModal()}
      </React.Fragment>
    </div>
  ) : (
    <DashboardLayout title={title}>
      <Loading isLoading={isLoading} />
      <React.Fragment>
        <div className="card">
          <div className="card-header">
            <div className="row justify-content-center">
              <div className="col-auto">
                <FileButton
                  onChange={(file) => {
                    if (file) {
                      setProfilePicture(file);
                    }
                  }}
                  image={profilePicture}
                />
              </div>
            </div>
          </div>

          <div className="card-body">
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <div className="form-group mt-1">
                  <label className="ml-3" htmlFor="first-name">
                    First name *
                  </label>

                  <TextBox
                    type="text"
                    onChange={(val) => {
                      setFirstName(val);
                    }}
                    className="form-control sign-in-inputs"
                    error={firstNameError}
                    value={firstName}
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-6">
                <div className="form-group mt-1">
                  <label className="ml-3" htmlFor="last-name">
                    Last name *
                  </label>
                  <TextBox
                    type="text"
                    onChange={(val) => {
                      setLastName(val);
                    }}
                    className="form-control sign-in-inputs"
                    error={lastNameError}
                    value={lastName}
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-6">
                <div className="form-group mt-1">
                  <label className="ml-3" htmlFor="username">
                    Username *
                  </label>

                  <TextBox
                    type="text"
                    onChange={(val) => {
                      setUsername(val);
                    }}
                    className="form-control sign-in-inputs"
                    error={userNameError}
                    value={userName}
                  />
                </div>
              </div>

              {getPasswordUI()}

              <div className="col-sm-12 col-md-6">
                <div className="form-group mt-1">
                  <label className="ml-3" htmlFor="phone">
                    Phone{" "}
                  </label>
                  <div className="row">
                    <div className="col-lg-4">
                      <AppDropdown
                        value={countryCode}
                        onChange={(val) => {
                          setCountryCode(val);
                        }}
                        error={countryCodeError}
                        items={countryCodeList}
                      ></AppDropdown>
                    </div>
                    <div className="col">
                      <TextBox
                        mask="999-999-9999"
                        type="text"
                        onChange={(val) => {
                          setPhoneNumber(val);
                        }}
                        className="form-control sign-in-inputs"
                        error={phoneNumberError}
                        value={phoneNumber}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6">
                <div className="form-group mt-1">
                  <label className="ml-3" htmlFor="email">
                    Email *
                  </label>

                  <TextBox
                    type="text"
                    onChange={(val) => {
                      setEmail(val);
                    }}
                    className="form-control sign-in-inputs"
                    error={emailError}
                    value={email}
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-6">
                <div className="form-group mt-1">
                  <label className="ml-3" htmlFor="security">
                    Security *
                  </label>
                  <AppDropdown
                    onChange={(value: any) => {
                      setSecurity(value);
                    }}
                    value={security}
                    error={securityError}
                  >
                    {getRoleOptionsUI()}
                  </AppDropdown>
                </div>
              </div>
              <div className="col-sm-12 col-md-6">
                <div className="form-group mt-1">
                  <label className="ml-3" htmlFor="security">
                    Timezone for Notifications *
                  </label>
                  <select
                    value={timeZone}
                    className="form-control sign-in-inputs"
                    onChange={(e) => {
                      changeTimeZone(e.target.value);
                    }}
                  >
                    <option value={1}>
                      Mountain Daylight Time - Denver (GMT-6)
                    </option>
                    <option value={0}>
                      Central Daylight Time - Chicago (GMT-5)
                    </option>
                    <option value={2}>
                      Mountain Standard Time - Phoenix (GMT-7)
                    </option>
                    <option value={3}>
                      Pacific Daylight Time - Los Angeles (GMT-7)
                    </option>
                    <option value={4}>
                      Alaska Daylight Time - Anchorage (GMT-8)
                    </option>
                    <option value={5}>
                      Hawaii-Aleutian Standard Time - Honolulu (GMT-10)
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div className="row justify-content-center align-content-center">
              <div className="col-md-6">{showRemoveButtonUI()}</div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group mt-1">
                      <input
                        className="btn btn-secondary btn-block btn-pcid-cancel mt-3"
                        type="button"
                        value="Cancel"
                        onClick={() => {
                          // props.history.push("/users");
                          props.history.goBack();
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mt-1">
                      <input
                        className="btn btn-success btn-block btn-pcid mt-3"
                        type="button"
                        value="Save"
                        onClick={() => {
                          registerUser(true);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {confirmModal()}
      </React.Fragment>
    </DashboardLayout>
  );

  function showRemoveButtonUI() {
    if (!userId) {
      return <></>;
    }

    return (
      <button
        className="mt-4 btn btn-danger"
        onClick={() => {
          removeUser();
        }}
      >
        Remove
      </button>
    );
  }

  function confirmModal() {
    return (
      <Modal
        isOpen={modalIsOpen}
        ariaHideApp={false}
        onRequestClose={() => {
          setModalIsOpen(false);
        }}
        className="modal"
      >
        <div className="row">
          <div
            className="card text-center"
            style={{ width: "350px", height: "180px" }}
          >
            <div className="card-body align-items-center d-flex justify-content-center">
              You haven't set a photo!
            </div>
            <div className="card-footer text-muted p-4">
              <input
                className="btn btn-secondary btn-pcid-cancel"
                type="button"
                value="Continue"
                onClick={() => {
                  registerUser(false);
                }}
              />
              <input
                className="btn btn-success btn-pcid ml-3"
                type="button"
                value="Go Back"
                onClick={() => {
                  setModalIsOpen(false);
                }}
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  }
};

export default AddUser;
