import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loading from "../../components/Loading";
import ApiService from "../../services/ApiService";

const queryString = require("query-string");

export default function (props: any) {
    let { result } = useParams();
    let query = queryString.parse(window.location.search);
    const [isLoading, setIsLoading] = useState(false);
    const apiService = ApiService();

    useEffect(() => {
        setIsLoading(true);

        if (result == "c") {
            props.history.push("/payment");
            return;
        }

        let data = {
            PaypalSubscription: query.subscription_id,
        };
        apiService.sendPayPalStoreUpdateResult(data).then((data: any) => {
            data = data.data;
            if (!data.isValid) {
                toast.error(
                    "Paypal validation Error! If you made a payment please contact us"
                );
            }
            props.history.push("/stores");
        });
    }, []);

    return (
        <>
            <Loading isLoading={isLoading} />
            <div
                className={
                    "mt-3 text-center badge badge-info p-3 m-3 rounded d-flex"
                }
            >
                Paypal result processing...
            </div>
        </>
    );
}
