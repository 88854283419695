import React from "react";
import ReactTooltip from "react-tooltip";

interface AppToolTipProps {
    tip: string;
    className?: string;
}

const AppToolTip = (props: AppToolTipProps) => {
    return (
        <div className="tooltip-wrap">
            <ReactTooltip />
            <p data-tip={props.tip} className={props.className}>
                <i className="fas fa-question-circle"></i>
            </p>
        </div>
    );
};

export default AppToolTip;
