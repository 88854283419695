/* eslint-disable */

import React, { useState, useContext, useEffect } from "react";
import Loading from "../../components/Loading";
import DashboardLayout from "../../layouts/DashboardLayout";
import ApiService from "../../services/ApiService";
import {
    getUser,
    setUser,
    setLocalStorageFromAuth,
    navigateTo,
} from "../../services/HelperMethod";
import { UserCompany } from "../../store/StoreProvider";
import { toast } from "react-toastify";

const ImpersonateUsers = (props: any) => {
    const [isLoading, setIsLoading] = useState(false);
    const [companies, setCompanies] = useState([] as any);
    const [_, setCompanyName] = useContext(UserCompany);

    const apiService = ApiService();

    useEffect(() => {
        setIsLoading(true);
        apiService
            .switchUsersList()
            .then((data) => {
                data = data.data;
                setCompanies(data);
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
                toast.error("Error! try again");
            });
    }, []);

    function login(userId) {
        setIsLoading(true);

        apiService
            .changeLogin({ userId })
            .then((data: any) => {
                data = data.data;
                setIsLoading(false);
                
                setLocalStorageFromAuth(data, setCompanyName);
                navigateTo(props.history, data.navigateTo);
            })
            .catch((err) => {
                setIsLoading(false);
                toast.error("Error! try again");
            });
    }

    return (
        <DashboardLayout title="PCID Customer Service">
            <Loading isLoading={isLoading} />

            <div className="company-wrap impersonate-user mt-5">
                {companies.map((company: any) => {
                    return (
                        <div
                            key={company.company.id}
                            className="company-switch-row row m-0 align-items-center mt-3"
                        >
                            <div className="col company-name">
                                {company?.company?.name}
                            </div>
                            <div className="col-12">
                                {company?.users?.map((user) => {
                                    {
                                        return (
                                            <div
                                                key={user.id}
                                                className="row align-items-center login-as-row"
                                            >
                                                <div className="col">
                                                    {user.firstName}{" "}
                                                    {user.lastName}{" "}
                                                    {user.userName}
                                                </div>
                                                <div className="col-auto">
                                                    <div className="col-auto">
                                                        <button
                                                            className="btn btn-sm btn-pcid"
                                                            onClick={() => {
                                                                login(user.id);
                                                            }}
                                                        >
                                                            Login
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    }
                                })}
                            </div>
                        </div>
                    );
                })}
            </div>
        </DashboardLayout>
    );
};

export default ImpersonateUsers;
