/* eslint-disable */

import React, {
    useState,
    useContext,
    createRef,
    IframeHTMLAttributes,
} from "react";
import { useHistory } from "react-router-dom";
import ApiService from "../../services/ApiService";
import Loading from "../../components/Loading";
import { toast } from "react-toastify";

import {
    setLocalStorageFromAuth,
    navigateTo,
    parseQueryString,
} from "../../services/HelperMethod";
import { UserCompany } from "../../store/StoreProvider";
import { AxiosResponse } from "axios";

const SetupPayments = (props: any) => {
    const [isLoading, setIsLoading] = useState(false);
    const [paymentToken, setPaymentToken] = useState("");
    const [iframeDisplay, setIframeDisplay] = useState("none");
    const [_, setCompanyNameStore] = useContext(UserCompany);

    function getStepsUI() {
        return LastStep();
    }

    const apiService = ApiService();

    function payFromCard() {
        setIsLoading(true);
        let data = { SourceURL: window.location.href };
        apiService
            .getPaymentToken(data)
            .then((data: any) => {
                setIsLoading(false);
                data = data.data;
                setPaymentToken(data.token);
                setIframeDisplay("flex");
                //load iframe
                (window as any).AuthorizeNetIFrame = {};
                (window as any).AuthorizeNetIFrame.onReceiveCommunication = function (
                    querystr
                ) {
                    const params = parseQueryString(querystr);
                    switch (params["action"]) {
                        case "successfulSave":
                            break;
                        case "cancel":
                            closePayment();
                            break;
                        case "resizeWindow":
                            setIsLoading(false);
                            break;
                        case "transactResponse":
                            let transactionData = JSON.parse(params.response);
                            apiService
                                .validatePayment({
                                    transactionId: transactionData.transId,
                                })
                                .then((data: any) => {
                                    data = data.data;

                                    if (data.errorType) {
                                        toast.error(data.errorExplanation);
                                        closePayment();
                                        return;
                                    }

                                    setLocalStorageFromAuth(
                                        data,
                                        setCompanyNameStore
                                    );
                                    setIsLoading(false);
                                    navigateTo(props.history, data.navigateTo);
                                })
                                .catch((err) => {
                                    setIsLoading(false);
                                    setIframeDisplay("none");
                                    toast.error("Failed try again");
                                });
                            break;
                    }
                };

                const submitForm = document.getElementById(
                    "submit_payment"
                ) as HTMLFormElement;
                submitForm?.submit();
            })
            .catch(() => {
                toast.error("Error loading payment");
            });
    }

    function payFromPaypal() {
        setIsLoading(true);
        let data = { SourceURL: window.location.href };
        apiService
            .getPaypalURL(data)
            .then((data: any) => {
                data = data.data;
                setIsLoading(false);
                
                window.open(data.url, "_self");
            })
            .catch((err) => {
                setIsLoading(false);
            });
    }

    function closePayment() {
        setIframeDisplay("none");
    }

    function LastStep() {
        return (
            <div className="login-wrapper-gray">
                <div className="row center-screen justify-content-center">
                    <div className="col-md-6 col-lg-5">
                        <h2 className="text-center mb-3 topic-text">
                            Sign Up{" "}
                            <span className="topic-small-text">2 of 2</span>{" "}
                        </h2>
                        <div className="card">
                            <div className="card-header">
                                <h5>Billing Information</h5>
                            </div>

                            <div className="card-body">
                                <div className="row">
                                    <div className="col-auto">
                                        <button
                                            className="btn payment-btn btn-secondary credit-card"
                                            onClick={payFromCard}
                                        >
                                            <i className="fab fa-cc-visa" />{" "}
                                            Credit Card
                                        </button>
                                    </div>
                                    {/* <div className="col-auto">
                                        <button
                                            className="btn payment-btn btn-secondary paypal"
                                            onClick={payFromPaypal}
                                        >
                                            <i className="fab fa-cc-paypal" />{" "}
                                            PayPal
                                        </button>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <button
                            className="btn text-secondary mt-3"
                            onClick={() => {
                                props.history.push("/");
                            }}
                        >
                            <i className="fas fa-angle-left" /> Back
                        </button>
                    </div>
                </div>

                <form
                    id="submit_payment"
                    action="https://test.authorize.net/payment/payment"
                    method="post"
                    target="add_payment"
                >
                    <input type="hidden" name="token" value={paymentToken} />
                </form>

                <div
                    className="payment-iframe-wrap"
                    style={{ display: `${iframeDisplay}` }}
                >
                    <div className="content-area">
                        <button
                            onClick={() => {
                                closePayment();
                            }}
                            className="btn btn-iframe-close"
                        >
                            <i className="fas fa-times" />
                        </button>
                        <iframe
                            id="add_payment"
                            name="add_payment"
                            className="embed-responsive-item panel"
                            frameBorder={0}
                        />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div>
            <Loading isLoading={isLoading} />
            {getStepsUI()}
        </div>
    );
};

export default SetupPayments;
