/* eslint-disable */

import React, { useState, useContext, useEffect, Fragment } from "react";
import Loading from "../../components/Loading";
import DashboardLayout from "../../layouts/DashboardLayout";
import ApiService from "../../services/ApiService";
import {
  getUser,
  getUserJWT,
  setUser,
  setUserJWT,
} from "../../services/HelperMethod";
import { UserCompany } from "../../store/StoreProvider";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

const SwitchCompany = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [companies, setCompanies] = useState([] as any);
  const [company, setCompany] = useContext(UserCompany);
  const [isAddNewCompany, setIsAddNewCompany] = useState(false);

  const apiService = ApiService();
  useEffect(() => {
    setIsLoading(true);
    apiService
      .getAllCompanies()
      .then((data) => {
        data = data.data;
        setCompanies(data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }, []);

  function switchCompany(companyId) {
    setIsLoading(true);
    apiService
      .switchCompany(companyId)
      .then((data: any) => {
        data = data.data;
        if (data) {
          var user = getUser();
          if (user) {
            user.company = data;
            setUser(user);
            setCompany(user.company?.name);
          }
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }

  function handleAddNewCompany() {
    localStorage.setItem("tmp_jwt", getUserJWT()!);
    localStorage.setItem("tmp_user", JSON.stringify(getUser()!));

    setIsAddNewCompany(true);
    window.open("/#/signup", "_blank");
  }

  function AddNewCompanyRefreshUI() {
    if (!isAddNewCompany) {
      return <Fragment />;
    }
    return (
      <div className="mt-3 current-company-alert">
        Please click{" "}
        <button
          className="btn btn-secondary"
          onClick={() => {
            let tmpJWT = localStorage.getItem("tmp_jwt");
            let tmpUser = localStorage.getItem("tmp_user");

            setUserJWT(tmpJWT);
            setUser(JSON.parse(tmpUser!));

            localStorage.removeItem("tmp_user");
            localStorage.removeItem("tmp_jwt");

            location.reload();
          }}
        >
          this button
        </button>
        once you complete new company process to stay sign in with your current
        account
      </div>
    );
  }

  return (
    <DashboardLayout title="Change Company">
      <Loading isLoading={isLoading} />

      <button onClick={handleAddNewCompany} className="btn btn-pcid">
        Add new
      </button>

      <AddNewCompanyRefreshUI />

      <div className="company-wrap mt-5">
        {companies.map((company: any) => {
          return (
            <div
              key={company.id}
              className="company-switch-row row m-0 align-items-center mt-3"
            >
              <div className="col">{company.name}</div>
              <div className="col-auto">
                <button
                  className="btn btn-pcid"
                  onClick={() => {
                    switchCompany(company.id);
                  }}
                >
                  Switch
                </button>
              </div>
            </div>
          );
        })}
      </div>
    </DashboardLayout>
  );
};

export default SwitchCompany;
