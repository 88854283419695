/* eslint-disable */

import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import DashboardLayout from "../../layouts/DashboardLayout";
import TextBox from "../../components/TextBox";
import Loading from "../../components/Loading";
import AppCropper from "../../components/AddCropper";
import FileButton from "../../components/FileButton";
import ApiService from "../../services/ApiService";
import { checkClaim, parseQueryString } from "../../services/HelperMethod";
import ModalDelete from "react-modal";

export default function (props: any) {
    const [imageURL, setImageURL] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [PhoneNumber, setPhoneNumber] = useState("");
    const [Email, setEmail] = useState("");
    const [Address, setAddress] = useState("");
    const [FirstName, setFirstName] = useState("");
    const [LastName, setLastName] = useState("");
    const [UserEmail, setUserEmail] = useState("");
    const [UserPhone, setUserPhone] = useState("");
    const [card, setCard] = useState("");

    const [City, setCity] = useState("");
    const [State, setState] = useState("");
    const [Zip, setZip] = useState("");

    const [companyNameError, setCompanyNameError] = useState("");
    const [PhoneNumberError, setPhoneNumberError] = useState("");
    const [EmailError, setEmailError] = useState("");
    const [AddressError, setAddressError] = useState("");
    const [FirstNameError, setFirstNameError] = useState("");
    const [LastNameError, setLastNameError] = useState("");
    const [UserEmailError, setUserEmailError] = useState("");
    const [UserPhoneError, setUserPhoneError] = useState("");

    const [CityError, setCityError] = useState("");
    const [StateError, setStateError] = useState("");
    const [ZipError, setZipError] = useState("");

    const [isLoading, setIsLoading] = useState(false);

    const [paymentToken, setPaymentToken] = useState("");
    const [iframeDisplay, setIframeDisplay] = useState("none");

    const [modalDeleteIsOpen, setModalDeleteIsOpen] = useState(false);
    const [companyId, setCompanyId] = useState();

    const apiService = ApiService();
    useEffect(() => {
        setIsLoading(true);

        getInfo();
    }, []);

    function getInfo() {
        apiService.getCompanyInfo().then((data: any) => {
            data = data.data;

            setIsLoading(false);

            setCompanyId(data.id);
            setCompanyName(data.name);
            setPhoneNumber(data.phone);
            setEmail(data.email);
            setAddress(data.address);
            setFirstName(data.contactFirstName);
            setLastName(data.contactLastName);
            setUserEmail(data.userEmail);
            setUserPhone(data.userPhone);
            setCard(data.card);

            setCity(data.city);
            setState(data.state);
            setZip(data.zip)

            setIsLoading(false);
            setIframeDisplay("none");
        });
    }

    function updateDetails() {
        setIsLoading(true);

        let data = {
            name: companyName,
            phone: PhoneNumber,
            email: Email,
            address: Address,
            contactFirstName: FirstName,
            contactLastName: LastName,
            userEmail: UserEmail,
            userPhone: UserPhone,

            city: City,
            state: State,
            zip: Zip
        };

        apiService
            .updateCompanyInfo(data)
            .then((data) => {
                setIsLoading(false);
            })
            .catch((err: any) => {
                setIsLoading(false);
                handleErrors(err?.response?.data?.errors);
            });
    }

    function handleErrors(err) {
        if (err) {
            setCompanyNameError(err.Name);
            setPhoneNumberError(err.UserPhone);
            setEmailError(err.Email);
            setAddressError(err.Address);
            setFirstNameError(err.ContactFirstName);
            setLastNameError(err.ContactLastName);
            setUserEmailError(err.UserEmail);
            setUserPhoneError(err.UserPhone);
            setCityError(err.City);
            setStateError(err.State);
            setZipError(err.Zip);
            toast.error("Please fill all the required fields");
        } else {
            toast.error("Error! try again");
        }
    }

    function payFromCard() {
        setIsLoading(true);
        var data = { SourceURL: window.location.href };
        apiService
            .getPaymentToken(data)
            .then((data: any) => {
                setIsLoading(false);
                data = data.data;
                setPaymentToken(data.token);
                setIframeDisplay("flex");
                //load iframe
                (window as any).AuthorizeNetIFrame = {};
                (window as any).AuthorizeNetIFrame.onReceiveCommunication = function (
                    querystr
                ) {
                    var params = parseQueryString(querystr);
                    switch (params["action"]) {
                        case "successfulSave":
                            break;
                        case "cancel":
                            setIframeDisplay("none");
                            break;
                        case "resizeWindow":
                            setIsLoading(false);
                            break;
                        case "transactResponse":
                            let transactionData = JSON.parse(params.response);
                            apiService
                                .updatePayment({
                                    transactionId: transactionData.transId,
                                })
                                .then((data: any) => {
                                    data = data.data;
                                    if (data.errorType) {
                                        toast.error(data.errorExplanation);
                                    }

                                    getInfo();
                                });
                            break;
                    }
                };

                var submitForm = document.getElementById(
                    "submit_payment"
                ) as HTMLFormElement;
                submitForm?.submit();
            })
            .catch(() => {
                toast.error("Error loading payment");
            });
    }

    return (
        <DashboardLayout title="Company">
            {confirmDelete()}
            <Loading isLoading={isLoading} />
            <div className="card">
                {/* <div className="card-header">
          <div className="pro-pic">
            <FileButton
              image={imageURL}
              onChange={(data) => {
                setImageURL(data);
              }}
            />
          </div>
        </div> */}

                <div className="row m-0">
                    <div className="col-sm-12 col-md-12">
                        <p className="pl-3 mt-4 general-info">
                            Company Information{" "}
                        </p>
                    </div>
                    <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                            <label>Business Name *</label>
                            <TextBox
                                type="text"
                                onChange={(val) => {
                                    setCompanyName(val);
                                }}
                                className="form-control sign-in-inputs"
                                error={companyNameError}
                                value={companyName}
                            />
                        </div>
                        <div className="form-group mt-1">
                            <label className="ml-3" htmlFor="business-email">
                                Email *
                            </label>
                            <TextBox
                                type="text"
                                onChange={(val) => {
                                    setEmail(val);
                                }}
                                className="form-control sign-in-inputs"
                                error={EmailError}
                                value={Email}
                            />
                        </div>
                        <div className="form-group mt-1">
                            <label className="ml-3" htmlFor="business-city">
                                City *
                            </label>
                            <TextBox
                                type="text"
                                onChange={(val) => {
                                    setCity(val);
                                }}
                                className="form-control sign-in-inputs"
                                error={CityError}
                                value={City}
                            />
                        </div>
                        <div className="form-group mt-1">
                            <label className="ml-3" htmlFor="business-zip">
                                Zip Code *
                            </label>
                            <TextBox
                                type="text"
                                onChange={(val) => {
                                    setZip(val);
                                }}
                                className="form-control sign-in-inputs"
                                error={ZipError}
                                value={Zip}
                            />
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-6">
                        <div className="form-group mt-1">
                            <label className="ml-3" htmlFor="business-phone">
                                Phone *
                            </label>
                            <TextBox
                                type="text"
                                mask="999-999-9999"
                                onChange={(val) => {
                                    setPhoneNumber(val);
                                }}
                                className="form-control sign-in-inputs"
                                error={PhoneNumberError}
                                value={PhoneNumber}
                            />
                        </div>
                        <div className="form-group mt-1">
                            <label className="ml-3" htmlFor="business-address">
                                Address *
                            </label>
                            <TextBox
                                type="text"
                                onChange={(val) => {
                                    setAddress(val);
                                }}
                                className="form-control sign-in-inputs"
                                error={AddressError}
                                value={Address}
                            />
                        </div>
                        <div className="form-group mt-1">
                            <label className="ml-3" htmlFor="business-state">
                                State *
                            </label>
                            <TextBox
                                type="text"
                                onChange={(val) => {
                                    setState(val);
                                }}
                                className="form-control sign-in-inputs"
                                error={StateError}
                                value={State}
                            />
                        </div>
                    </div>
                </div>

                <div className="row m-0">
                    <div className="col-sm-12 col-md-12">
                        <p className="pl-3 mt-4 general-info">
                            Point of Contact Info{" "}
                        </p>
                    </div>
                    <div className="col-sm-12 col-md-6">
                        <div className="form-group mt-1">
                            <label className="ml-3" htmlFor="poc-fname">
                                First Name *
                            </label>
                            <TextBox
                                type="text"
                                onChange={(val) => {
                                    setFirstName(val);
                                }}
                                className="form-control sign-in-inputs"
                                error={FirstNameError}
                                value={FirstName}
                            />
                        </div>

                        <div className="form-group mt-1">
                            <label className="ml-3" htmlFor="poc-email">
                                Email *
                            </label>

                            <TextBox
                                type="text"
                                onChange={(val) => {
                                    setUserEmail(val);
                                }}
                                className="form-control sign-in-inputs"
                                error={UserEmailError}
                                value={UserEmail}
                            />
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                        <div className="form-group mt-1">
                            <label className="ml-3" htmlFor="poc-lname">
                                Last Name *
                            </label>
                            <TextBox
                                type="text"
                                onChange={(val) => {
                                    setLastName(val);
                                }}
                                className="form-control sign-in-inputs"
                                error={LastNameError}
                                value={LastName}
                            />
                        </div>
                        <div className="form-group mt-1">
                            <label className="ml-3" htmlFor="poc-phone">
                                Phone *
                            </label>
                            <TextBox
                                mask="999-999-9999"
                                type="text"
                                onChange={(val) => {
                                    setUserPhone(val);
                                }}
                                className="form-control sign-in-inputs"
                                error={UserPhoneError}
                                value={UserPhone}
                            />
                        </div>
                    </div>
                </div>

                <div className="row justify-content-end mb-2 mt-4 mr-2">
                    <div className="col-auto ">
                        {checkClaim("admin") && companyId
                            ? (<button className="btn btn-secondary btn-block btn-pcid-cancel ml-3"
                                onClick={() => { setModalDeleteIsOpen(true) }}>Delete Company
                            </button>)
                            : (<div></div>)
                        }
                    </div>
                    <div className="col-auto ">
                        <button
                            className="btn btn-success btn-pcid ml-3"
                            onClick={updateDetails}
                        >
                            Update
                        </button>
                    </div>
                </div>
            </div>
            <div className="card mt-4">
                <div className="card-header">Payment information</div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-8">
                            <label>Card Number</label>
                            <div>
                                <span
                                    style={{
                                        fontSize: 16,
                                        fontWeight: "bold",
                                        marginRight: 10,
                                    }}
                                >
                                    {card}
                                </span>
                                <button
                                    className="btn btn-pcid"
                                    onClick={payFromCard}
                                >
                                    Update
                                </button>
                            </div>
                        </div>
                        <div className="col-md-4"></div>
                    </div>
                </div>
            </div>

            <form
                // ref={submitForm}
                id="submit_payment"
                action="https://test.authorize.net/payment/payment"
                method="post"
                target="add_payment"
            >
                <input type="hidden" name="token" value={paymentToken} />
            </form>

            <div
                className="payment-iframe-wrap"
                style={{ display: `${iframeDisplay}` }}
            >
                <div className="content-area">
                    <button
                        onClick={() => {
                            setIframeDisplay("none");
                        }}
                        className="btn btn-iframe-close"
                    >
                        <i className="fas fa-times"></i>
                    </button>
                    <iframe
                        id="add_payment"
                        name="add_payment"
                        className="embed-responsive-item panel"
                        frameBorder={0}
                    ></iframe>
                </div>
            </div>
        </DashboardLayout>
    );

    function deleteCompany(deleteCompany = false) {
        if (companyId && deleteCompany) {

            setIsLoading(true);

            var data = {
                companyId: companyId
            }

            apiService.deleteCompany(data).then((data: any) => {
                data = data.data;

                setIsLoading(false);

                props.history.push("/login/?clear=true");
            }).catch((error) => {
                alert("Unable to delete selected.")
                setIsLoading(false);
            });
        } else {
            alert("Unable to delete selected.")
            setIsLoading(false);
        }
    }

    function confirmDelete() {
        return (
            <ModalDelete isOpen={modalDeleteIsOpen} ariaHideApp={false}
                onRequestClose={() => { setModalDeleteIsOpen(false); }} className="modal">
                <div className="row">
                    <div className="card text-center" style={{ width: "350px", height: "180px" }}>
                        <div className="card-body align-items-center d-flex justify-content-center">
                            Are you sure you want to delete this?
                        </div>
                        <div className="card-footer text-muted p-4">
                            <input className="btn btn-secondary btn-pcid-cancel" type="button"
                                value="Continue" onClick={() => { deleteCompany(true); }}
                            />
                            <input className="btn btn-success btn-pcid ml-3" type="button"
                                value="Go Back"
                                onClick={() => { setModalDeleteIsOpen(false); }}
                            />
                        </div>
                    </div>
                </div>
            </ModalDelete>
        );
    }
}
