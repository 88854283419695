import React from "react";
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"
interface mapProps {
    loadingElement: any;
    googleMapURL: any;
    setLatLong: any;
    markers: any;
}

function setSelectedLatAndLong(lat, long, setFunction) {
    return setFunction(lat, long)
}

const MyMapComponent: any = withScriptjs(
    withGoogleMap((props: mapProps) => (
        <GoogleMap defaultZoom={4} defaultCenter={{ lat: 37.09024, lng: -95.712891 }}
            onClick={(e) => { setSelectedLatAndLong(e.latLng.lat(), e.latLng.lng(), props.setLatLong) }}>
            <Marker position={{ lat: parseFloat(props.markers[0].lat), lng: parseFloat(props.markers[0].lng) }} />
        </GoogleMap>
    ))
);

export default MyMapComponent;