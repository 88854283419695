import React, { useState, useEffect, useRef, useContext } from "react";
import ApiService from "../../services/ApiService";
import parse from "html-react-parser";

import { useParams } from "react-router-dom";

function ProcessorForm() {
  const { guid } = useParams() as any;
  const [checked, setChecked] = useState([]);

  const [companyName, setCompanyName] = useState("");
  const [stores, setStores] = useState([] as any[]);
  const [stringStores, setStringStores] = useState("");
  const [stringLanes, setStringLanes] = useState("");
  const [report, setReport] = useState([]);
  const { reportID, setReportID } = useParams() as any;
  const date = new Date();

  const apiService = ApiService();
  useEffect(() => {
    getInfo();
    getReport();
  }, []);

  function getInfo() {
    apiService.getCompanyByGuid(guid).then((data: any) => {
      data = data.data;
      console.log(data);
      setCompanyName(data.name);
      setStringStores("2");
      setStringLanes("3");
    });

    apiService.getReportByGuid(guid).then((data: any) => {
      data = data.data;
      setReportID(data);
    });
  }

  function getReport() {
    apiService.getComplianceQuestions().then((data: any) => {
      data = data.data;
      var report = [];

      for (var i = 0; i < data.length; i++) {
        report[i] = "" + data[i].specId;
        report[i] = report[i] + data[i].question;
        report[i] = report[i] + data[i].expectation;
      }

      setReport(report);
    });

    apiService.getComplianceAnswers(reportID).then((data: any) => {
      data = data.data;
      var answers = [];
      for (var i = 0; i < data.length; i++) {
        answers[i] = "" + data[i].answer;
      }

      setChecked(answers);
    });
  }

  return (
    <div className="report">
      <img
        src="./assets/images/pcid_logo.png"
        alt="pcid"
        width="200"
        id="logo"
      />
      <button className="requestDetails"> Print as PDF </button>

      <div className="Report-Information">
        <h3>{companyName}</h3>
        <h4>PCI Compliance Report</h4>
        <p>
          {" "}
          <b>Evidence: </b> Media and login data for every
        </p>
      </div>

      <div className="cards">
        <div className="infocard">
          <img src="./assets/images/store-icon.svg" alt="pcid" />
          <div className="infotext">
            <h3>{stringStores}</h3>
            <p>Locations</p>
          </div>
        </div>
        <div className="infocard">
          <img src="./assets/images/Menu-Lanes-Active.png" alt="pcid" />
          <div className="infotext">
            <h3>{stringLanes}</h3>
            <p># of lanes</p>
          </div>
        </div>
        <div className="infocard">
          <img src="./assets/images/shield-icon.svg" alt="pcid" />
          <div className="infotext">
            <h3>0 Day</h3>
            <p>Verification Frequency</p>
          </div>
        </div>
      </div>

      <form
        id="ComplianceCard"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <fieldset disabled>
          <div id="ComplianceColumn">
            <h5 className="gridColumn">Section</h5>
            <h5 className="gridColumn">Question</h5>
            <h5 className="gridColumn">Expected Testing</h5>
            <h5 className="gridColumn">Response</h5>

            {report.map((item) => [
              parse("" + item),
              <div className="radio">
                <input
                  type="radio"
                  value="1"
                  name={report.indexOf(item).toString()}
                  checked={checked[report.indexOf(item)] === "1"}
                />{" "}
                <label>Yes</label>
                <input
                  type="radio"
                  value="2"
                  name={report.indexOf(item).toString()}
                  checked={checked[report.indexOf(item)] === "2"}
                />{" "}
                <label>Yes w/ CCW</label>
                <input
                  type="radio"
                  value="3"
                  name={report.indexOf(item).toString()}
                  checked={checked[report.indexOf(item)] === "3"}
                />{" "}
                <label>No</label>
                <input
                  type="radio"
                  value="4"
                  name={report.indexOf(item).toString()}
                  checked={checked[report.indexOf(item)] === "4"}
                />{" "}
                <label>N/A</label>
                <input
                  type="radio"
                  value="5"
                  name={report.indexOf(item).toString()}
                  checked={checked[report.indexOf(item)] === "5"}
                />{" "}
                <label>Not Tested</label>
              </div>,
            ])}
          </div>
        </fieldset>
      </form>
    </div>
  );
}

export default ProcessorForm;
