import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import AppModal from "../../components/AppModal";
import ApiService from "../../services/ApiService";
import Steps from "../../components/Steps";
import Loading from "../../components/Loading";

function SetupEquipment() {
    const history = useHistory();

    const [modalIsOpen, setModalIsOpen] = useState(false);

    const [storeName, setStoreName] = useState("");
    const [laneNames, setLanesName] = useState([]);
    const [equipmentPerLane, setEquipmentPerLane] = useState(null as any);

    const [store, setStore] = useState([]);
    const [currentStoreLaneData, setCurrentStoreLaneData] = useState(null as any);

    const [isLoading, setIsLoading] = useState(false);

    const apiService = ApiService();

    useEffect(() => {
        setIsLoading(true);

        apiService.getStores().then((data: any) => {
            data = data.data;

            setStore(data);
            setIsLoading(false);
        });
    }, [])

    useEffect(() => {
        function getLaneNames() {
            if (store.length != 0) {

                const storeLength = store.length;

                const lastElement = store[storeLength - 1];

                const lanesData = lastElement.lanes
                const storeName = lastElement.name

                if (lanesData != null) {
                    var laneNamesArr = [];

                    lanesData.map((element) => {
                        return laneNamesArr.push(element.name);
                    });

                    setLanesName(laneNamesArr);
                    setStoreName(storeName);
                    setCurrentStoreLaneData(lanesData);
                }
            }
        }

        getLaneNames();
    }, [store]);

    useEffect(() => {
        function setEquipmentArray() {
            var tempArr = [];

            laneNames.forEach((element) => {
                tempArr.push(0);
            });

            setEquipmentPerLane(tempArr);
        }

        setEquipmentArray();
    }, [laneNames]);

    function getEquipmentValue(equipmentIndex, isAddition) {
        if (isAddition) {
            const array = equipmentPerLane.map((i, index) => {
                if (index == equipmentIndex) {
                    return i + 1;
                } else {
                    return i;
                }
            });

            setEquipmentPerLane(array);
        } else {
            const array = equipmentPerLane.map((i, index) => {
                if (index == equipmentIndex) {
                    return i - 1;
                } else {
                    return i;
                }
            });

            setEquipmentPerLane(array);
        }
    }

    function continueTo() {
        history.push("/done");
    }

    function saveEquipment() {
        const equipmentArr = equipmentPerLane;
        const laneNameArr = laneNames;

        var shopObj = [];

        const isBelowThreshold = (currentValue) => currentValue === 0;

        var isEquipmentZero = equipmentArr.every(isBelowThreshold);

        if (isEquipmentZero) {
            return setModalIsOpen(true);
        }

        laneNameArr.forEach((element, index) => {

            const foundLaneObj = currentStoreLaneData.find(findElement => findElement.name == element);

            var tempObj = {
                laneNumber: foundLaneObj.number,
                laneName: element,
                equipmentPerLane: equipmentArr[index]
            };

            shopObj.push(tempObj);
        });

        var shopObjToString = JSON.stringify(shopObj);
        localStorage.setItem("shopData", shopObjToString)

        history.push({ pathname: "/setup-shop" });
    }

    function redirectToDashboard() {
        history.push("/dashboard")
    }

    return (
        <React.Fragment>
            <Loading isLoading={isLoading} />
            <AppModal message={"Are you sure you want to continue with 0 equipment?"} onClick={() => { continueTo(); }} onClose={() => { setModalIsOpen(false); }} isOpen={modalIsOpen} />

            <div className="full-layout">
                <div style={{ marginBottom: "3rem" }}>
                    <div>
                        <button className="btn-redirect" onClick={(e) => { e.preventDefault(); redirectToDashboard(); }}>Skip</button>
                    </div>
                </div>
                <div className="container">
                    <div style={{ width: "100%" }} className="white-box">
                        <div>
                            <div style={{ marginBottom: "0.5rem" }} className="row">
                                <div className="col-sm-12 col-md-5">
                                    <h2 style={{ marginTop: "0.7rem" }} className="mb-3 topic-text ht" id={"equipment-profile"}>'{storeName}' Equipment</h2>
                                </div>
                                <div className="col-sm-12 col-md-7">
                                    <Steps iteration={4} activeIndex={2} />
                                </div>
                            </div>
                            <div>
                                <div>
                                    <p className="pl-3 general-info">Which lanes would you like to add PCI equipment to?</p>
                                    <form>
                                        <div className="row">
                                            {equipmentPerLane != null ? laneNames.map((i, index) => {
                                                return (
                                                    <div key={i} className="col-sm-12 col-md-3">
                                                        <div className="form-group mt-1">
                                                            <label style={{ display: "flex", justifyContent: "center" }}>
                                                                Lane - {i}
                                                            </label>
                                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                {equipmentPerLane[index] != 0 ?
                                                                    (<i style={{ cursor: "pointer" }} onClick={() => { getEquipmentValue(index, false); }} className="fas fa-caret-left fa-2x"></i>)
                                                                    : (<i className="fas fa-caret-left fa-2x"></i>)}
                                                                <div style={{ fontWeight: "bold", fontSize: "3.5rem", margin: "0 1rem 0 1rem" }}>
                                                                    {equipmentPerLane[index]}
                                                                </div>
                                                                <i style={{ cursor: "pointer" }} onClick={() => { getEquipmentValue(index, true); }} className="fas fa-caret-right fa-2x"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            }) : null}
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "flex-end", }}>
                                            <div style={{ width: 220 }}>
                                                <button className="btn" onClick={(e) => { e.preventDefault(); saveEquipment(); }}>
                                                    Next
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default SetupEquipment;