/* eslint-disable */

import React, { useEffect, useState } from "react";
import ApiService from "../../services/ApiService";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import { useParams } from "react-router-dom";
import DashboardLayout from "../../layouts/DashboardLayout";
import Loading from "../../components/Loading";
import { Link } from "react-router-dom";
import moment from "moment";
import BestCalendar from "../../components/BestCalendar";
import AppTooltip from "../../components/AppTooltip";
import Switch from '@material-ui/core/Switch';

const AddMaintenance = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [managers, setManagers] = useState([] as any[]);
    const apiService = ApiService();
    const [title, setTitle] = useState("Add Maintenance");

    const [verificationDueDate, setVerificationDueDate] = useState(new Date());
    // const [verificationEndDate, setVerificationEndDate] = useState(new Date());

    const [receiveNotifications, setReceiveNotifications] = useState(true);

    const [frequency, setFrequency] = useState("2");
    const [managerId, setManagerId] = useState(0 as any);

    const [nextMaintenanceDate, setNextMaintenanceDate] = useState(null as any);

    const { assetId } = useParams();

    const [wasPreviousMaintenanceAutoCompleted, setWasPreviousMaintenanceAutoCompleted] = useState();
    const [previousMaintenanceDate, setPreviousMaintenanceDate] = useState(null as any);
    const [wasPreviousMaintenanceSuccessful, setWasPreviousMaintenanceSuccessful] = useState();
    const [lastMaintenanceLogId, setLastMaintenanceLogId] = useState(0);
    const [previousMaintenanceDoneBy, setPreviousMaintenanceDoneBy] = useState(null as any);

    const handleChange = () => {
        setReceiveNotifications(!receiveNotifications);
    };

    useEffect(() => {
        setNextMaintenanceDate(getNextDueDate(frequency))
    }, [verificationDueDate, frequency]);

    function getNextDueDate(dueDateValue) {
        var getNextDueDate;

        if (dueDateValue == 1) {
            getNextDueDate = moment(verificationDueDate).add(1, 'weeks').local().format("ll");
        } else if (dueDateValue == 2) {
            getNextDueDate = moment(verificationDueDate).add(2, 'weeks').local().format("ll");
        } else if (dueDateValue == 3) {
            getNextDueDate = moment(verificationDueDate).add(1, 'M').local().format("ll");
        } else if (dueDateValue == 4) {
            getNextDueDate = moment(verificationDueDate).add(3, 'M').local().format("ll");
        } else if (dueDateValue == 5) {
            getNextDueDate = moment(verificationDueDate).add(12, 'M').local().format("ll");
        }

        return getNextDueDate;
    }

    function createMaintenance() {
        setIsLoading(true);
        let data = {
            frequency,
            AssignedToId: managerId,
            assetId: assetId,
            dueDate: verificationDueDate,
            receiveNotifications: receiveNotifications
            // endDate: verificationEndDate,
        };
        apiService
            .createMaintenance(data)
            .then((data: any) => {

                setIsLoading(false);
                props.history.push("/maintenance");
            })
            .catch((err) => {
                setIsLoading(false);

                toast.error("Please fill all required fields");
            });
    }

    useEffect(() => {
        apiService.getManagers().then((data: any) => {
            data = data.data;
            setManagers(data);
        });

        editMaintenance();
    }, []);

    async function editMaintenance() {
        if (assetId) {
            setTitle("Edit Maintenance");

            let previousMaintenance = await apiService.wasPreviousMaintenanceCompleted(assetId);
            let wasPreviousMaintenanceSuccessful = await apiService.wasPreviousMaintenanceSuccessful(assetId);

            setWasPreviousMaintenanceSuccessful(wasPreviousMaintenanceSuccessful?.data?.result)
            setLastMaintenanceLogId(wasPreviousMaintenanceSuccessful?.data?.maintenanceLogId);
            setPreviousMaintenanceDoneBy(previousMaintenance?.data?.assignTo?.firstName + " " + previousMaintenance?.data?.assignTo?.lastName + " - " + previousMaintenance?.data?.assignTo?.username);
            setWasPreviousMaintenanceAutoCompleted(previousMaintenance?.data?.autoCreated);
            setPreviousMaintenanceDate(previousMaintenance?.data?.dueDate);

            apiService.getDepartmentMaintenance(assetId).then((data: any) => {
                data = data.data;
                if (data) {
                    setManagerId(data.assignToId);
                    setFrequency(data.frequency);
                    setVerificationDueDate(moment(data.dueDate).local().toDate());
                    // setVerificationEndDate(moment.utc(data.endDate).local().toDate());
                    setReceiveNotifications(data.receiveNotifications);
                }
            });
        }
    }

    function getLastMaintenanceInfo(isLastMaintenaceAutoCompleted, isLastMaintenanceSuccessful) {
        if (isLastMaintenaceAutoCompleted != undefined && isLastMaintenanceSuccessful != undefined) {
            if (isLastMaintenaceAutoCompleted) {
                return (<div>Last maintenance have not been started or completed</div>)
            }

            if (isLastMaintenanceSuccessful) {
                return (
                    <div>Completed successfully - Last Maintenance: Completed successfully on
                        <span>{moment(previousMaintenanceDate).local().format("ll")}
                        </span> by <span>{previousMaintenanceDoneBy}</span>.
                        Click <Link style={{ color: "blue", textDecoration: "underline" }} to={"/maintenance/log/details/" + lastMaintenanceLogId}>here</Link>
                    </div>
                )
            }

            if (!isLastMaintenanceSuccessful) {
                return (
                    <div>Completed with Failure - Last Maintenance: Completed but Failed
                        on <span>{moment(previousMaintenanceDate).local().format("ll")}
                        </span> by <span>{previousMaintenanceDoneBy}</span>.
                        Click <Link style={{ color: "blue", textDecoration: "underline" }} to={"/maintenance/log/details/" + lastMaintenanceLogId}>here</Link>
                    </div>
                )
            }
        }
    }

    function showAlert() {
        alert("No logs found.")
    }

    return (
        <DashboardLayout title={title}>
            <Loading isLoading={isLoading} />
            <React.Fragment>
                <div className="card">
                    <div className="card-header">
                        <p className="mb-5"></p>
                    </div>
                    <div className="card-body mb-5 mt-3">
                        <form>
                            <div className="row">
                                <div className="col-sm-12 col-md-6">
                                    <div className="form-group mt-1">
                                        <label
                                            className="ml-3"
                                            htmlFor="assign-to"
                                        >
                                            Assign To *
                                        </label>
                                        <select
                                            value={managerId}
                                            onChange={(e) => {
                                                setManagerId(e.target.value);
                                            }}
                                            className="form-control sign-in-inputs"
                                        >
                                            <option value="0">
                                                Select User's Security
                                            </option>
                                            {managers.map((manager: any) => {
                                                return (
                                                    <option
                                                        key={manager.id}
                                                        value={manager.id}
                                                    >
                                                        {manager.firstName}{" "}
                                                        {manager.lastName} - {manager.username}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6">
                                    <div className="form-group mt-1">
                                        <label
                                            className="ml-3"
                                            htmlFor="frequency"
                                        >
                                            Frequency *
                                        </label>
                                        <select
                                            value={frequency}
                                            onChange={(e) => {
                                                setFrequency(e.target.value);
                                            }}
                                            className="form-control sign-in-inputs"
                                            id="frequency"
                                            name="frequency"
                                        >
                                            <option value="2">
                                                Every two weeks
                                            </option>
                                            <option value="1">Weekly</option>
                                            <option value="3">Monthly</option>
                                            <option value="4">Quarterly</option>
                                            <option value="5">Yearly</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6">
                                    <div className="form-group mt-1">
                                        <AppTooltip
                                            className="ml-1"
                                            tip="The date set here will represent the date in which the maintenance should be performed"
                                        />
                                        <label
                                            className="ml-2 mt-1"
                                            htmlFor="due-date"
                                        >
                                            Current Maintenance Due Date *
                                        </label>
                                        <BestCalendar
                                            minDate={Date.now()}
                                            className="form-control sign-in-inputs w-100"
                                            selected={verificationDueDate}
                                            onChange={(e) => {
                                                setVerificationDueDate(e);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6">
                                    <div className="form-group mt-1">
                                        {/* <AppTooltip tip="" /> */}
                                        <label className="ml-2 mt-1" htmlFor="end-date">
                                            Receive Notifications{" "}
                                        </label>
                                        <Switch
                                            checked={receiveNotifications}
                                            onChange={handleChange}
                                            color="primary"
                                            name="checkedB"
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6">
                                    <div style={{ display: "flex", flexDirection: "row" }} className="form-group mt-1">
                                        <label style={{ width: "50rem" }} className="ml-2 mt-1" htmlFor="end-date">
                                            {getLastMaintenanceInfo(wasPreviousMaintenanceAutoCompleted, wasPreviousMaintenanceSuccessful)}
                                        </label>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-12">
                                    <div style={{ display: "flex", flexDirection: "row" }} className="form-group mt-1">
                                        <label style={{ width: "20rem" }} className="ml-2 mt-1" htmlFor="end-date">
                                            Next Maintenance Due Date{" "}
                                        </label>
                                        <label style={{ width: "20rem" }} className="ml-2 mt-1" htmlFor="end-date">
                                            {moment(nextMaintenanceDate).local().format("ll")}
                                        </label>
                                    </div>
                                </div>
                                {/* <div className="col-sm-12 col-md-6">
                                    <div className="form-group mt-1">
                                        <AppTooltip tip="End Date will be last date in which a assigned maintenance should be performed, if it’s not met it will be automatically assigned to the next scheduled slot based on the interval assigned" />

                                        <label
                                            className="ml-2 mt-1"
                                            htmlFor="end-date"
                                        >
                                            End Date{" "}
                                        </label>
                                        <BestCalendar
                                            minDate={Date.now()}
                                            className="form-control sign-in-inputs w-100"
                                            selected={verificationEndDate}
                                            onChange={(e) => {
                                                setVerificationEndDate(e);
                                            }}
                                        />
                                    </div>
                                </div> */}
                            </div>
                            <div className="row mt-4 justify-content-end">
                                <div className="col-md-3">
                                    <input
                                        className="btn btn-secondary btn-block btn-pcid-cancel mt-3"
                                        type="button"
                                        value="Back"
                                        onClick={() => {
                                            props.history.push("/maintenance");
                                        }}
                                    />
                                </div>
                                <div className="col-md-3">
                                    <input
                                        className="btn btn-success btn-block btn-pcid mt-3"
                                        type="button"
                                        value="Save"
                                        onClick={() => {
                                            createMaintenance();
                                        }}
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </React.Fragment>
        </DashboardLayout>
    );
};

export default AddMaintenance;
