/* eslint-disable */
import React, { useState } from "react";
import { toBase64 } from "../services/HelperMethod";
import AppCropper from "./AddCropper";

interface FileButtonProps {
  onChange: CallableFunction;
  getLatLong?: CallableFunction;
  image: string;
  accept?: string;
  isHideCropper?: boolean;
  styles?: any;
  cropperStyles?: any;
}

const FileButton = (props: FileButtonProps) => {
  const [isCropperShow, setCropperShow] = useState(false);
  const [image, setImage] = useState("");
  const exifr = (window as any).exifr;
  const [lat, setLat] = useState("");
  const [long, setLong] = useState("");

  async function process(file) {
    if (file) {
      if (props.getLatLong) {
        try {
          let exifData = await exifr.parse(file);
          setLat(exifData?.latitude);
          setLong(exifData?.longitude);
          if (!exifData?.latitude) {
            alert(
              "No Image Location Data Found, Please fill latitude/longitude manually"
            );
          }
        } catch (error) {
          console.error(error);
          alert(
            "No Image Location Data Found, Please fill latitude/longitude manually"
          );
        }
      }

      toBase64(file).then((data: any) => {
        if (props.isHideCropper) {
          props.onChange(data);
        } else {
          setImage(data);
        }
      });
    } else {
      props.onChange(null);
    }
  }

  let accept = props.accept;
  if (!accept) {
    accept = "image/*";
  }

  let img = <i className="fas fa-camera" />;
  if (props.image) {
    img = (
      <img className="upload-image" src={props.image} alt={"upload image"} />
    );
  }

  let additionalStyles;
  if (props.styles) {
    additionalStyles = props.styles
  }

  return (
    <>
      <AppCropper
        isShow={isCropperShow}
        image={image}
        styles={props.cropperStyles}
        onComplete={(e) => {
          if (e) {
            props.onChange(e);
            if (props.getLatLong) {
              props.getLatLong(lat, long);
            }
          }
          setCropperShow(false);
        }}
      />
      <label className="filebutton" style={additionalStyles}>
        {img}
        <span>
          <input
            onChange={(e: any) => {
              setCropperShow(true);
              process(e.target.files?.[0]);
            }}
            type="file"
            accept={accept}
          />
        </span>
        {/*<button className="btn btn-danger btn-link">Remove</button>*/}
      </label>
    </>
  );
};
export default FileButton;
