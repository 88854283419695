/* eslint-disable */

import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import ApiService from "../../services/ApiService";
import DashboardLayout from "../../layouts/DashboardLayout";
import {
    getImage,
    OptionsDropDown,
    tableColumns,
    getSearch,
} from "../../services/HelperMethod";
import { Dropdown } from "react-bootstrap";
import moment from "moment";

const VerificationDevices = (props) => {
    const { verificationId } = useParams();
    const [devices, setDevices] = useState([] as any[]);
    const [originalDevices, setOriginalDevices] = useState([] as any[]);

    const apiService = ApiService();

    useEffect(() => {
        apiService.getVerificationDevices(verificationId).then((data: any) => {
            data = data.data;
            setDevices(data);
            setOriginalDevices(data);
        });
    }, []);

    return (
        <DashboardLayout title={"Verifications: Terminals"}>
            <div>
                {getSearch(
                    devices,
                    setDevices,
                    [
                        "username",
                        "firstName",
                        "lastName",
                        "phone",
                        "email",
                        "selectedRole",
                    ],
                    originalDevices
                )}
                <table className="table table-borderless table-v-center">
                    <thead>
                        <tr className="colored-row">
                            <th scope="col"></th>
                            <th scope="col"></th>
                            {tableColumns(
                                "Pin Pad",
                                "pinPad",
                                devices,
                                setDevices
                            )}
                            {tableColumns(
                                "Serial",
                                "serial",
                                devices,
                                setDevices
                            )}
                            {tableColumns(
                                "Asset tag",
                                "assetTag",
                                devices,
                                setDevices
                            )}
                            {tableColumns(
                                "Lane",
                                "lane.name",
                                devices,
                                setDevices
                            )}
                            {tableColumns(
                                "Store",
                                "lane.store.name",
                                devices,
                                setDevices
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {devices.map((device) => {
                            return (
                                <tr
                                    className="cursor-pointer"
                                    key={device.id}
                                    onClick={() => {}}
                                >
                                    <td scope="col">
                                        <OptionsDropDown>
                                            <Dropdown.Item
                                                onClick={() => {
                                                    props.history.push(
                                                        "/verification/deviceLog/" +
                                                            device.id
                                                    );
                                                }}
                                            >
                                                View Verification Logs
                                            </Dropdown.Item>
                                        </OptionsDropDown>
                                    </td>
                                    <td scope="col">
                                        <Link
                                            to={
                                                "/verification/deviceLog/" +
                                                device.id
                                            }
                                        >
                                            <div
                                                className="profile-image"
                                                style={{
                                                    backgroundImage: `url(${getImage(
                                                        device.frontImageURL
                                                    )})`,
                                                }}
                                            />
                                        </Link>
                                    </td>
                                    <td scope="row">{device.pinPad}</td>
                                    <td>{device.serial}</td>
                                    <td>{device.assetTag}</td>
                                    <td>{device.lane?.name}</td>
                                    <td>{device.lane?.store.name}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </DashboardLayout>
    );
};

export default VerificationDevices;
