import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  Fragment,
} from "react";
import { NavLink, Link } from "react-router-dom";
import ApiService from "../services/ApiService";
import {
  getImage,
  getUser,
  checkClaim,
  getVersionNumber,
} from "../services/HelperMethod";
import moment from "moment";
import { UserCompany } from "../store/StoreProvider";
import Loading from "../components/Loading";
import { toast } from "react-toastify";
import { Button, Dropdown } from "react-bootstrap";
import { useLocation } from "react-router-dom";

interface DashboardLayoutProps {
  title: any;
  isSubTitle?: boolean;
  children: React.ReactNode;
  onHelpClick?: CallableFunction;
  helpId?: string;
}

const DashboardLayout = (props: DashboardLayoutProps) => {
  const [isSideNavActive, setIsSideNavActive] = useState(false);

  // messages
  const [showMessageUsers, setShowMessageUsers] = useState(false);
  const [msgUsers, setMsgUsers] = useState([]);
  const [selectedMessageUser, setSelectedMessageUser] = useState(null as any);
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState(null as any);
  const [messageCount, setMessageCount] = useState(0);
  const chatMessageEndRef = useRef(null as any);
  const [companyName, setCompanyName] = useContext(UserCompany);
  const [isMessageLoading, setIsMessageLoading] = useState(false);

  const [toggleProfile, setToggleProfile] = useState(false);

  const [loginUser, setLoginUser] = useState(null as any);

  const apiService = ApiService();
  const inspectionHashes = ["#/maintenance", "#/verification"];
  const reportHashes = [
    "#/Reports",
    "#/VerificationReport",
    "#/MaintenanceReport",
    "#/TerminalsReport",
  ];

  let interval: any = null;
  let checkUserMessageInternal: any = null;

  useEffect(() => {
    getUsersList();
    startCheckMessage();

    if (inspectionHashes.includes(window.location.hash)) {
      showInspectionsDropdown();
    }

    if (reportHashes.includes(window.location.hash)) {
      showReportsDropdown();
    }

    var user = getUser();
    setLoginUser(user);
    setCompanyName(user?.company?.name);
    return () => {
      if (interval) {
        clearInterval(interval);
      }
      if (checkUserMessageInternal) {
        clearInterval(checkUserMessageInternal);
      }
    };
  }, []);

  useEffect(() => {
    if (messageCount > 0) {
      if (selectedMessageUser) {
        getMessages(selectedMessageUser);
      }
    }
  }, [selectedMessageUser, messageCount]);

  const [showReports, setShowReports] = React.useState(false);
  const showReportsDropdown = () => setShowReports(!showReports);

  const [showInspections, setShowInspections] = React.useState(false);
  const showInspectionsDropdown = () => setShowInspections(!showInspections);

  function getMessageCountUI() {
    if (messageCount == 0) {
      return <></>;
    }
    return (
      <div className="message-count animate__animated animate__headShake">
        {messageCount}
      </div>
    );
  }

  function scrollToBottom() {
    // chatMessageEndRef.current.scrollIntoView({ behavior: "smooth" });
    chatMessageEndRef?.current?.scrollIntoView(false);
  }

  function getUsersList() {
    apiService
      .getChatUserList()
      .then((data: any) => {
        data = data.data;
        setMsgUsers(data);
      })
      .catch(() => {});
  }

  function startCheckMessage() {
    apiService.checkNewMessages().then((data: any) => {
      data = data.data;
      setMessageCount(data.length);

      interval = setInterval(() => {
        apiService.checkNewMessages().then((data: any) => {
          data = data.data;
          setMessageCount(data.length);
        });
        // if(selectedMessageUser){
        //   getMessages(selectedMessageUser);
        // }
      }, 3000);
    });
  }

  function getMessages(messageUser) {
    setShowMessageUsers(false);
    setIsMessageLoading(true);
    apiService
      .getMessages(messageUser?.user?.id)
      .then((data: any) => {
        data = data.data;
        setMessages(data);
        scrollToBottom();
        setIsMessageLoading(false);
      })
      .catch((err) => {
        toast.error("Error message retrieve!");
        setIsMessageLoading(false);
      });
  }

  // function startGetUserMessages() {
  //   checkUserMessageInternal = setInterval(() => {
  //     if (selectedMessageUser) {
  //       getMessages(selectedMessageUser);
  //     }
  //   }, 1000);
  // }

  function messageFullUI() {
    if (selectedMessageUser) {
      return (
        <div className="message-full">
          <Loading isLoading={isMessageLoading} />
          <div className="header">
            <div className="row align-items-center">
              <div className="col">
                <div className="name">
                  {selectedMessageUser?.user?.firstName}{" "}
                  {selectedMessageUser?.user?.lastName}
                </div>
              </div>
              <div className="col-auto">
                <div className="lane-info">
                  {getAssignedLanesUI(selectedMessageUser)}
                </div>
              </div>
              <div className="col-auto">
                <button
                  className="close-message"
                  onClick={() => {
                    setSelectedMessageUser(null);
                  }}
                >
                  <i className="fas fa-times"></i>
                </button>
              </div>
            </div>
          </div>

          <div className="content-wrap">
            <div className="content">
              {messages?.messages.map((msg: any) => {
                let classNames = "msg";
                if (msg.senderId == messages.uid) {
                  classNames += " me";
                }
                return (
                  <div key={msg.id} className={classNames}>
                    <div className="sender badge">{msg.sender.firstName}</div>
                    <div className="msg-body">{msg.message}</div>
                    <div className="time badge">
                      {moment(msg.sendDate).local().format("MMM Do, YY h:mm a")}
                    </div>
                  </div>
                );
              })}
              <div ref={chatMessageEndRef}></div>
            </div>
          </div>
        </div>
      );
    }

    return <></>;
  }

  function sendMessage() {
    if (isMessageLoading) {
      return;
    }

    let data = {
      message,
      receiverId: selectedMessageUser?.user?.id,
    };
    setIsMessageLoading(true);

    apiService
      .sendMessage(data)
      .then((data: any) => {
        setMessage("");
        getMessages(selectedMessageUser);
      })
      .catch((err) => {
        setIsMessageLoading(false);
        toast.error("Error sending message");
      });
  }

  function getSideNameClass() {
    if (isSideNavActive) {
      return "active sidenav mt-5";
    }

    return "sidenav mt-5";
  }

  function getToggleIcon() {
    if (isSideNavActive) {
      return <i className="fas fa-times"></i>;
    }
    return <i className="fas fa-bars" />;
  }

  function getAssignedLanesUI(messageUser) {
    if (messageUser.assignedLanes?.length <= 0) {
      return <></>;
    }

    let firstLane = messageUser.assignedLanes[0];
    return firstLane?.lane?.name;
  }

  function getUserLastMessageTimeUI(messageUser) {
    if (!messageUser.message) {
      return <></>;
    }
    return (
      <span>
        {moment(messageUser.message?.sendDate)
          .local()
          .format("MMM Do, YY h:mm a")}
      </span>
    );
  }

  function superAdminNavUI() {
    let isSuperAdmin = checkClaim("PCIDAdmin");
    let isCustomerService = checkClaim("PCIDCustomerService");

    let admin = <></>;
    let customerService = <></>;
    if (isSuperAdmin) {
      admin = (
        <>
          <NavLink to="/changeCompany">Company</NavLink>
        </>
      );
    }

    if (isCustomerService) {
      customerService = (
        <>
          <NavLink to="/impersonateUser">Impersonate</NavLink>
        </>
      );
    } else {
      return <></>;
    }

    return (
      <div>
        <div className="mt-5">
          <div className="badge badge-info ml-1">PCID - Admin</div>
          {admin}
          {customerService}
        </div>
      </div>
    );
  }

  function getNavUI() {
    var canAddStore = checkClaim("Admin");
    let dashboardNav = <></>;
    let companyNav = <></>;
    if (true) {
      dashboardNav = (
        <NavLink to="/dashboard">
          <img
            className="mr-2"
            src="./assets/icons/home.svg"
            alt="home"
            height="20"
            width="20"
          />
          Dashboard
        </NavLink>
      );

      companyNav = (
        <NavLink to="/company">
          <i className="far fa-building sidebar-icon"></i>
          Company
        </NavLink>
      );
    }
    return (
      <>
        {dashboardNav}
        <div className={showInspections ? "toggledNav" : ""}>
          <div className="sidenavToggle" onClick={showInspectionsDropdown}>
            <img
              className="mr-2"
              src="./assets/icons/verification.svg"
              alt="home"
              height="20"
              width="20"
            />
            <div>Inspections</div>
          </div>

          {showInspections ? (
            <div className="sidenavMenu">
              <NavLink to="/verification">
                <img
                  className="mr-2"
                  src="./assets/icons/verification.svg"
                  alt="home"
                  height="18"
                  width="18"
                />
                Verification
              </NavLink>

              <NavLink to="/maintenance">
                <img
                  className="mr-2"
                  src="./assets/icons/maintenance.svg"
                  alt="home"
                  height="18"
                  width="18"
                />
                Maintenance
              </NavLink>
            </div>
          ) : null}
        </div>

        {canAddStore ? (
          <NavLink to="/users">
            <img
              className="mr-2"
              src="./assets/icons/users.svg"
              alt="home"
              height="20"
              width="20"
            />
            Users
          </NavLink>
        ) : (
          <Fragment />
        )}
        <NavLink to="/stores">
          <img
            className="mr-2"
            src="./assets/icons/stores.svg"
            alt="home"
            height="20"
            width="20"
          />{" "}
          Stores
        </NavLink>

        <NavLink id="nav-lanes" to="/lanes">
          <img
            className="mr-2"
            src="./assets/icons/lanes.svg"
            alt="home"
            height="20"
            width="20"
          />
          Lanes
        </NavLink>
        <NavLink to="/pciequipments">
          <img
            className="mr-2"
            src="./assets/icons/pci.svg"
            alt="home"
            height="25"
            width="20"
          />
          Terminals
        </NavLink>
        <NavLink to="/assets">
          <img
            className="mr-2"
            src="./assets/icons/assets.svg"
            alt="home"
            height="25"
            width="20"
          />
          Assets
        </NavLink>

        <div className={showReports ? "toggledNav" : ""}>
          <div className="sidenavToggle" onClick={showReportsDropdown}>
            <img
              className="mr-2"
              src="./assets/icons/verification.svg"
              alt="home"
              height="20"
              width="20"
            />
            <div>Reports</div>
          </div>

          {showReports ? (
            <div className="sidenavMenu">
              <NavLink to="/Reports">
                <img
                  className="mr-2"
                  src="./assets/icons/users.svg"
                  alt="home"
                  height="18"
                  width="18"
                />
                Compliance
              </NavLink>

              <NavLink to="/VerificationReport">
                <img
                  className="mr-2"
                  src="./assets/icons/verification.svg"
                  alt="home"
                  height="18"
                  width="18"
                />
                Verification
              </NavLink>

              <NavLink to="/MaintenanceReport">
                <img
                  className="mr-2"
                  src="./assets/icons/maintenance.svg"
                  alt="home"
                  height="18"
                  width="18"
                />
                Maintenance
              </NavLink>

              <NavLink to="/TerminalsReport">
                <img
                  className="mr-2"
                  src="./assets/icons/pci.svg"
                  alt="home"
                  height="18"
                  width="18"
                />
                Terminal
              </NavLink>
            </div>
          ) : null}
        </div>

        {companyNav}
      </>
    );
  }

  function getMsgUI() {
    if (!showMessageUsers) {
      return <></>;
    }

    return (
      <div className="message-user-list">
        <div className="header">Messages</div>
        <div className="msg-profile">
          {msgUsers.map((messageUser: any) => {
            return (
              <div key={messageUser.user.id}>
                <div
                  className="row msg-row justify-content-center align-items-center"
                  onClick={() => {
                    setSelectedMessageUser(messageUser);
                    getMessages(messageUser);
                  }}
                >
                  <div className="col-2">
                    <div
                      className="profile-image"
                      style={{
                        backgroundImage: `url(${getImage(
                          messageUser.user.imageURL
                        )})`,
                      }}
                    />
                  </div>
                  <div className="col-10">
                    <div className="username-wrap">
                      <div className="row no-gutters align-items-center">
                        <div className="col username">
                          {messageUser.user.firstName}{" "}
                          {messageUser?.user?.lastName}
                        </div>
                        <div className="col-auto user-lane">
                          {getAssignedLanesUI(messageUser)}
                        </div>
                        <div className="col-auto last-msg-time">
                          {getUserLastMessageTimeUI(messageUser)}
                        </div>
                      </div>
                    </div>
                    <div className="last-msg">
                      {messageUser.message?.message}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="footer"></div>
      </div>
    );
  }

  function getProfileUI() {
    return (
      <div className="menu-profile-wrap">
        <div className="top">
          <div
            className="profile-image"
            onClick={() => {
              setToggleProfile(!toggleProfile);
            }}
            style={{
              backgroundImage: `url(${getImage(loginUser?.user?.imageURL)})`,
            }}
          />
        </div>

        {toggleProfile ? (
          <div
            className="bottom"
            onMouseLeave={() => {
              setToggleProfile(false);
            }}
          >
            <div className="row p-2 m-0 align-items-center">
              <div className="col-auto">
                <Link to={"/editUser/" + loginUser?.user?.id}>
                  <div
                    className="profile-image"
                    style={{
                      backgroundImage: `url(${getImage(
                        loginUser?.user?.imageURL
                      )})`,
                    }}
                  />
                </Link>
              </div>
              <div className="col">
                <div className="info name">
                  {loginUser?.user?.firstName} {loginUser?.user?.lastName}
                </div>
                <div className="info">{loginUser?.user?.email} </div>
              </div>
            </div>
            <Link to="/login/?clear=true" className="log-out">
              <div className="info">
                <div>
                  <div className="avatar">Sign out</div>
                </div>
              </div>
            </Link>
          </div>
        ) : (
          <></>
        )}
        <div className="top-version-number">{getVersionNumber()}</div>
      </div>
    );
  }

  return (
    <React.Fragment>
      <nav className="navbar navbar-expand-md navbar-light bg-light fixed-top">
        <a className="navbar-brand" href="#/dashboard">
          <img
            className="pcid-logo"
            src="./assets/images/pcid_logo.png"
            alt="pcid"
            width="120"
          />
        </a>

        <div className="company-name d-flex align-items-center">
          <span className="badge">Company :</span>
          <span>{companyName}</span>
        </div>

        <div className="collapse navbar-collapse" id="navbarText">
          <ul className="navbar-nav mr-auto"></ul>
        </div>

        <button
          onClick={() => {
            setIsSideNavActive(!isSideNavActive);
          }}
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarText"
          aria-controls="navbarText"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          {getToggleIcon()}
        </button>

        <div className="navbar-profile-chat-wrap">
          <div className="col-auto">
            <div className="msg-wrap">
              {getMessageCountUI()}
              <button
                className="btn-messages"
                onClick={() => {
                  var state = showMessageUsers;
                  setShowMessageUsers(!state);
                  getUsersList();
                }}
              >
                <i className="fas fa-comment-dots"></i>
              </button>
              {getMsgUI()}
            </div>
          </div>
          <div className="col-auto">
            <div className="profile">{getProfileUI()}</div>
          </div>
        </div>
      </nav>

      <br />

      <div className="container mt-1" id="left-pane">
        <div className="row">
          <div className="col-md-12 mt-5">
            <div className="col-md-3">
              <div className={getSideNameClass()}>
                {getNavUI()}

                {superAdminNavUI()}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid layout-wrap">
        <div className="row center-screen">
          <div className="safe-area">
            <h2
              className={
                props.isSubTitle == true
                  ? "mb-3 topic-text"
                  : "mb-3 topic-text text-center"
              }
            >
              {props.title}
              {props.onHelpClick ? (
                <span
                  style={{
                    fontSize: 13,
                    backgroundColor: "#d6d6d6",
                    borderRadius: "50%",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "inline-flex",
                    width: 30,
                    height: 30,
                    marginLeft: 10,
                    marginBottom: 10,
                    cursor: "pointer",
                  }}
                  id={props.helpId}
                  onClick={() => {
                    props.onHelpClick();
                  }}
                >
                  <i className="fas fa-question"></i>
                </span>
              ) : (
                <Fragment />
              )}
            </h2>
            {props.children}
          </div>
        </div>
      </div>
      <div className="footer">{messageFullUI()}</div>
    </React.Fragment>
  );
};

export default DashboardLayout;
